import React from "react";
import { connect } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import {
   AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell} from 'recharts';
   import parseISO from 'date-fns/parseISO'
   import getMonth from 'date-fns/getMonth'
   import getYear from 'date-fns/getYear'
   import isThisYear from "date-fns/isThisYear";
import { format, subMonths } from "date-fns";

/////////////////////////////////
///Yearly statistics- ORERS
/////////////////////////////////

const useStyles = makeStyles((theme) => ({
   graph__container: {
      paddingLeft: 50,
      marginRight: 10,
      marginBottom:20,
   },
   graph__row1: {
      display: "flex",
      flexDirection: "row",
      alignItems:"center",
      flexWrap: "wrap",
      justifyContent:"flex-start"
   }
}));

const OrderValuesOverview = (props) => {
   const classes = useStyles();
   const {
      ordersArray,
   } = props;
  
   //shows today's month as last column minus 12 month in reverse
   const dataSum = [
      { name: format( subMonths(new Date(),11) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) ===getMonth(subMonths(new Date(),11)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),11)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),10) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) ===getMonth(subMonths(new Date(),10)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),10)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),9) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),9)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),9)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),8) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),8)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),8)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),7) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),7)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),7)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),6) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),6)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),6)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),5) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),5)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),5)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),4) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),4)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),4)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),3) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),3)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),3)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),2) , "MMM"),  orders:  [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),2)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),2)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),1) , "MMM"),  orders: [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) ===  getMonth(subMonths(new Date(),1)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),1)) && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: format( subMonths(new Date(),0) , "MMM"),  orders: [...ordersArray].filter(order => getMonth(parseISO(order.orderDate)) === getMonth(subMonths(new Date(),0)) && getYear(parseISO(order.orderDate)) === getYear(subMonths(new Date(),0))  && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
   ];

   /////calculating domestic vs foreign orders percentages
   //total sum of all orders (foreign + domestic), PDV excluded
   const totalValueSum = ordersArray.filter(order => order.orderNumber.length > 0).map(item => item.totalValue).reduce((a, b) => a + b, 0);
   //total value foreign orders (PDV excluded)
   const foreignOrdersTOtalVlaueNoVat = ordersArray.filter(order => order.isForeignCurrency && order.orderNumber.length > 0).map(item => item.totalValue).reduce((a, b) => a + b, 0)
   const domesticOrdersTotalValueNoVat = totalValueSum - foreignOrdersTOtalVlaueNoVat;
   const foreignOrdersPercentage = (foreignOrdersTOtalVlaueNoVat/totalValueSum)*100;
   const domesticOrdersPercentage = (domesticOrdersTotalValueNoVat/totalValueSum)*100;
   
   const dataPie = [
      { name: `Domestic orders`, value: +domesticOrdersPercentage.toFixed(2)},
      { name: `Foreign orders`, value: +foreignOrdersPercentage.toFixed(2)},
   ]
   const COLORS = ['#FFBB28', '#FF8042'];




   return (
      <div className={classes.graph__container}>

         <div>
            <ResponsiveContainer width="90%" height={250}>
               <AreaChart data={dataSum}
                  margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={{ fontSize: '14px', width: '50px', wordWrap: 'break-word', fill: '#9e9e9e' }} dataKey="name" />
                  <YAxis tick={{ fill: '#9e9e9e' }} 
                  />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Area name="Orders Value €" type='monotone' dataKey='orders' stackId="1" stroke='#82ca9d' fill='#82ca9d' />
               </AreaChart>
            </ResponsiveContainer>
         </div>

         {/* PieChartPie */}
         <div style={{ margin: 10, width: 230 }}>
               <PieChart width={240} height={225}>
                  <Pie data={dataPie} dataKey="value" cx={100} cy={80} innerRadius={40} outerRadius={80} fill="#82ca9d">
                     {
                        dataPie.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                     }
                  </Pie>
                  <Tooltip formatter={(value) => `${value} %`} />
                  <Legend verticalAlign="top" align="left" height={56} wrapperStyle={{paddingLeft: "40px"}} />
               </PieChart>
            </div>

      </div>
   );
};
const mapStateToProps = (state) => ({
   ordersArray:state.salesorders.orders,
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderValuesOverview);

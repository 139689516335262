import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import CachedIcon from "@material-ui/icons/Cached";

const SalesQuotesDropdown = (props) => {
	const { onOpenNewQuoteForm, onGetQuotes } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
				Menu
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem
					onClick={() => {
						onOpenNewQuoteForm();
						handleClose();
					}}>
					<AddBoxIcon style={{ marginRight: 5 }} color="primary" fontSize="small" />
					New Quote
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onGetQuotes();
					}}>
					<CachedIcon style={{ marginRight: 5 }} color="primary" fontSize="small" />
					Reload
				</MenuItem>
				<MenuItem onClick={handleClose}>Statistics</MenuItem>
			</Menu>
		</div>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	// onOpenTaskForm: () => dispatch(actionCreators.setNewCustomerFormVisible())
	onOpenNewQuoteForm: () => dispatch(actionCreators.openNewQuoteDialog()),
	onGetQuotes: () => dispatch(actionCreators.getQuotes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesQuotesDropdown);

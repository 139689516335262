import axios from "axios";
import * as actionCreators from "./index";
import { saveAs } from "file-saver";

export const setIsLoadingQuotesScreen = (isLoading) => {
	return {
		type: "[SALES_QUOTES] IS_LOADING",
		isLoading,
	};
};
export const setQuotes = (quotes) => {
	return {
		type: "[SALES_QUOTES] SET_QUOTES",
		quotes,
	};
};
export const setNewQuoteFormVisible = (isVisible) => {
	return {
		type: "[SALES_QUOTES] SET_NEW_QUOTE_FORM_IS_VISIBLE",
		isVisible,
	};
};
export const setQuoteCardVisible = (quoteCardIsVisible) => {
	return {
		type: "[SALES_QUOTES] SET_QUOTE_CARD_IS_VISIBLE",
		quoteCardIsVisible,
	};
};
export const setQuoteCardInfo = (quoteCardInfo) => {
	return {
		type: "[SALES_QUOTES] SET_QUOTE_CARD_INFO",
		quoteCardInfo,
	};
};
export const resetSalesQuotesInitialState = () => {
	return {
		type: "[SALES_QUOTES] RESET_STATE",
	};
};
export const setQuoteCardBarCode = (barCode) => {
	return {
		type: "[SALES_QUOTES] SET_QUOTE_CARD_BARCODE",
		barCode,
	};
};

//Opens new quote dialog (editing false) and pre-sets following
export const openNewQuoteDialog = () => {
	return async (dispatch, getState) => {
		try {
			await dispatch(actionCreators.setQuoteisEditing(false));
			await dispatch(actionCreators.resetNewQuoteInitialState());
			//set current time/date for quote on "new quote" action
			await dispatch(actionCreators.setQuoteDate(new Date()));
			await dispatch(setNewQuoteFormVisible(true));
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
		}
	};
};

//Opens new quote dialog in EDIT mode
export const openEditQuoteDialog = () => {
	return async (dispatch, getState) => {
		try {
			const customer = getState().customers.customers.find(
				(cust) => cust._id === getState().salesquotes.quoteCardInfo.customerId,
			);

			await dispatch(actionCreators.setQuoteisEditing(true));
			await dispatch(
				actionCreators.setEditingStateValues(
					getState().salesquotes.quoteCardInfo.quoteInputValues,
					getState().salesquotes.quoteCardInfo.noteInputValues,
					getState().salesquotes.quoteCardInfo.date,
					customer,
					getState().salesquotes.quoteCardInfo.foreignCurrency,
					getState().salesquotes.quoteCardInfo.projectInfo,
				),
			);
			await dispatch(setNewQuoteFormVisible(true));
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
		}
	};
};

//Opens quote card and sets details to rStore from that quote (used in [quote_table.js])
export const openQuoteCard = (quoteInfo) => {
	return async (dispatch, getState) => {
		try {
			await dispatch(setQuoteCardInfo(quoteInfo));
			await dispatch(setQuoteCardVisible(true));
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
		}
	};
};

//New quote values on change are connected to store
// so we can pass data from there directyl (no need to pass values)
export const saveNewQuote = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			// quote data - defined separately for foreign currency
			//total quote value
			let totalValue = 0;
			for (const key in getState().newquoteform.quoteInputValues) {
				totalValue += +getState().newquoteform.quoteInputValues[key].total;
			}
			let data = {
				customerName: getState().newquoteform.quoteCustomer.name,
				customerId: getState().newquoteform.quoteCustomer._id,
				date: getState().newquoteform.quoteDate,
				foreignCurrency: getState().newquoteform.isForeignCurrency,
				quoteInputValues: getState().newquoteform.quoteInputValues,
				noteInputValues: getState().newquoteform.noteInputValues,
				totalValue: totalValue,
				projectInfo:getState().newquoteform.projectInfo,
			};
			//If foreignCurrency add correct exchange rate
			//after EUR 2023 all eur exchangre rate = 1
			// if (getState().newquoteform.isForeignCurrency) {
			// 	const response = await axios.get(
			// 		process.env.REACT_APP_NODE_URL + "/api/quotes/getEuroExchangeRate",
			// 		{ headers: { Authorization: "Bearer " + getState().auth.token } },
			// 	);
			// 	//response.data comes as "7,5432" string
			// 	const exchangeRate = +response.data.replace(",", ".");
			// 	data["exchangeRate"] = exchangeRate;
			// } else {
				data["exchangeRate"] = 1;
			// }
			
			// console.log("data save new quote action-->", data)

			const result = await axios.post(
				process.env.REACT_APP_NODE_URL + "/api/quotes/createQuote",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			//close new quote
			dispatch(setNewQuoteFormVisible(false));
			//clear form input and note fields
			dispatch(actionCreators.resetNewQuoteInitialState());
			//show snackbar message
			dispatch(
				actionCreators.showSnackbar(`Quote ${result.data.quoteNumber} created successfully`),
			);
			//reload sales_quotes
			dispatch(getQuotesWithLimit());
		} catch (error) {
			// console.log("salesQuote actions [saveNewQuote ]: ", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
};

//Updates quote with data from the store directly
//(quote inputs are directyl connected to store - [newquoteform])
export const updateQuote = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			// quote data - defined separately for foreign currency
			//total quote value
			let totalValue = 0;
			for (const key in getState().newquoteform.quoteInputValues) {
				totalValue += +getState().newquoteform.quoteInputValues[key].total;
			}
			let data = {
				quoteId: getState().salesquotes.quoteCardInfo._id,
				customerName: getState().newquoteform.quoteCustomer.name,
				customerId: getState().newquoteform.quoteCustomer._id,
				date: getState().newquoteform.quoteDate,
				foreignCurrency: getState().newquoteform.isForeignCurrency,
				quoteInputValues: getState().newquoteform.quoteInputValues,
				noteInputValues: getState().newquoteform.noteInputValues,
				totalValue: totalValue,
				projectInfo:getState().newquoteform.projectInfo,
			};
			//If foreignCurrency add correct exchange rate
			// if (getState().newquoteform.isForeignCurrency) {
			// 	const response = await axios.get(
			// 		process.env.REACT_APP_NODE_URL + "/api/quotes/getEuroExchangeRate",
			// 		{ headers: { Authorization: "Bearer " + getState().auth.token } },
			// 	);
			// 	//response.data comes as "7,5432" string
			// 	const exchangeRate = +response.data.replace(",", ".");
			 	data["exchangeRate"] = 1;
			// } else {
			// 	data["exchangeRate"] = 1;
			// }

			const result = await axios.patch(
				process.env.REACT_APP_NODE_URL + "/api/quotes/updateQuote",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);

			//close new quote & quote details
			dispatch(setNewQuoteFormVisible(false));
			dispatch(setQuoteCardVisible(false));
			//clear form input and note fields
			dispatch(actionCreators.resetNewQuoteInitialState());
			//show snackbar message
			dispatch(
				actionCreators.showSnackbar(`Quote ${result.data.quoteNumber} updated successfully`),
			);
			//reload sales_quotes
			dispatch(getQuotesWithLimit());
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
};

export const deleteQuote = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			const data = {
				quoteId: getState().salesquotes.quoteCardInfo._id,
			};
			const result = await axios.put(
				process.env.REACT_APP_NODE_URL + "/api/quotes/deleteQuote",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			// console.log(result);
			dispatch(setQuoteCardVisible(false));
			//clear form input and note fields
			dispatch(resetSalesQuotesInitialState());
			//show snackbar message
			dispatch(
				actionCreators.showSnackbar(`Quote ${result.data.quote.quoteNumber} deleted successfully`),
			);
			//reload sales_quotes
			dispatch(getQuotesWithLimit());
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
};

//Get all quotes from db and populate quotes in [salesQuotes r]
export const getQuotes = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			const result = await axios.get(process.env.REACT_APP_NODE_URL + "/api/quotes", {
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			// console.log("salesQuotes [getQuotes]: ",result.data)
			//set quoes in rStore
			dispatch(setQuotes(result.data));
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
};
//////////////////////
//Get limited number of quotes from db and populate quotes in [salesQuotes r]
export const getQuotesWithLimit = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			const data = {
				numberOfRecords: 300
			};
			const result = await axios.post(process.env.REACT_APP_NODE_URL + "/api/quotes/getQuotesWithLimit", 
			data,
			{
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			// console.log("salesQuotes [getQuotesWithLimit]: ",result.data)
			//set quoes in rStore
			dispatch(setQuotes(result.data));
		} catch (error) {
			// console.log("salesQuotes actions [getQuotesWithLimit] :", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
};

//Search for quotes based on the name param
export const searchQuote = (searchString) => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			const data = {
				searchString: searchString
			};
			const result = await axios.post(process.env.REACT_APP_NODE_URL + "/api/quotes/searchQuotes", 
			data,
			{
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			console.log("salesQuotes [searchQuote]: ",result.data)
			//set quoes in rStore

			//!dispatch(setQuotes(result.data));
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
}


export const copyQuote = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			const data = {
				quoteId: getState().salesquotes.quoteCardInfo._id,
			};
			const result = await axios.post(
				process.env.REACT_APP_NODE_URL + "/api/quotes/copyQuote",
				data,
				{
					headers: { Authorization: "Bearer " + getState().auth.token },
				},
			);
			// console.log(result)
			dispatch(setQuoteCardVisible(false));
			//clear form input and note fields
			dispatch(resetSalesQuotesInitialState());
			//show snackbar message
			dispatch(
				actionCreators.showSnackbar(
					`Quote ${result.data.createdQuote.quoteNumber} created successfully`,
				),
			);
			//reload sales_quotes
			dispatch(getQuotesWithLimit());
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
};



export const generatePdf = (withBarcode) => {
	//withBarcode is bool - sends weather to print barcode
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingQuotesScreen(true));
			const selectedRow = getState().salesquotes.quoteCardInfo;
			const data = {
				quoteNumber: selectedRow.quoteNumber,
				date: selectedRow.date,
				createdDate: selectedRow.createdDate,
				customerId: selectedRow.customerId,
				foreignCurrency: selectedRow.foreignCurrency,
				quoteInputValues: selectedRow.quoteInputValues,
				noteInputValues: selectedRow.noteInputValues,
				totalValue: selectedRow.totalValue,
				exchangeRate: selectedRow.exchangeRate,
				orderNumber: selectedRow.orderNumber,
				isOrderCreated: selectedRow.isOrderCreated,
				withBarcode,
				isOrder: false,
				projectInfo:selectedRow.projectInfo
			};
			// console.log("[generatePdf] data -->", data)
			const result = await axios.post(
				process.env.REACT_APP_NODE_URL + "/api/pdf/createPdf",
				data,
				{ responseType: "blob", headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			const pdfBlob = new Blob([result.data], { type: "application/pdf" });
			saveAs(pdfBlob, data.quoteNumber);
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingQuotesScreen(false));
		}
	};
};

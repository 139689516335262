import React, { useEffect } from "react";
import PageTitle from "../../shared/components/UI/PageTitle";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import CustomersOptionsDropdown from "../components/CustomersOptionsDropdown";
import NewCustomerForm from "../components/NewCustomerForm";
import CustomerDetailCard from "../components/CustomerCard";
import {
	CircularProgress,
	Container,
	Grid,
	makeStyles,
} from "@material-ui/core";
import CustomersTable from "../components/CustomersTable";
import SearchBar from "../../shared/components/UI/SearchBar";

const useStyles = makeStyles((theme) => ({
	header: {},
}));

const Customers = (props) => {
	const classes = useStyles();

	const {
		onGetCustomers,
		isLoading,
		customersArray,
		customerCardVisible,
		customerFormIsVisible,
		onGetAllQuotes
	} = props;

	useEffect(() => {
		onGetCustomers()
		onGetAllQuotes()
	}, [onGetCustomers, onGetAllQuotes]);

	return (
		<Container maxWidth="xl">
			<Grid container justify="space-between" alignItems="center">
				<Grid container alignItems="center" spacing={3} style={{ width: 250 }}>
					{/* Page title */}
					<Grid item>
						<PageTitle tooltip="Click to refresh" onClick={onGetCustomers} title="Customers" />
					</Grid>
					<Grid item>
						{/* Spinner */}
						{isLoading && <CircularProgress color="secondary" />}
					</Grid>
				</Grid>
				<Grid item>
					{/* options menu */}
					<CustomersOptionsDropdown />
				</Grid>
			</Grid>
			{/* search bar */}
			<SearchBar
				filters={{ name: "name", oib: "OIB",email:"e-mail", address: "address" }}
				searchableArray={customersArray}
				showDropdown
			/>
			{/* customers table */}
			<CustomersTable />
			

			{/* HIDDEN */}
			{/* new customer form modal */}
			{customerFormIsVisible && <NewCustomerForm />}
			{/* customer detail card */}
			{customerCardVisible && <CustomerDetailCard />}
		</Container>
	);
};

const mapStateToProps = (state) => ({
	customersArray: state.customers.customers, //sarchagbe array
	isLoading: state.customers.isLoading,
	customerCardVisible: state.customers.customerInfoVisible,
	customerFormIsVisible: state.newcustomerform.isVisible, //new form UEFF?
});

const mapDispatchToProps = (dispatch) => ({
	// onSetIsLoading: (isLoading) =>
	// 	dispatch(actionCreators.setIsLoadingCustomersScreen(isLoading)),
	onGetCustomers: () => {
		dispatch(actionCreators.getCustomers());
	},
	onGetAllQuotes: () => dispatch(actionCreators.getQuotes()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);

import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
	AppBar,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	Menu,
	MenuItem,
	Slide,
	Toolbar,
} from "@material-ui/core";
import * as actionCreators from "../../../store/actions/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import UndoIcon from "@material-ui/icons/Undo";
import OrderCardDetails from "./OrderCard-details";

const useStyles = makeStyles((theme) => ({
	root: {},
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	customerData__container: {
		paddingTop: 10,
		paddingLeft: 50,
	},
	orderMenu: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		marginLeft: 10,
		paddingLeft: 5,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const OrderCard = (props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const {
		isLoading,
		orderCardIsVisible,
		orderCardInfo,
		onCloseOrderCard,
		onShowAlertModal,
		onOpenOrderEditDialog,
		onGenerateOrderPdf,
	} = props;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const closeDialogHandler = () => {
		onCloseOrderCard();
	};

	return (
		<div className={classes.root}>
			<Dialog
				fullScreen
				open={orderCardIsVisible}
				onClose={closeDialogHandler}
				aria-labelledby="form-dialog-title"
				maxWidth="md"
				TransitionComponent={Transition}>
				{/* APPBAR */}
				<AppBar className={classes.appBar} color="secondary" style={{}}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={closeDialogHandler}
							aria-label="close">
							<ArrowBackIcon fontSize="large" />
						</IconButton>
						{/* QUOTE TITLE/NAME */}
						<Typography variant="h6" className={classes.title}>
							Order for {orderCardInfo.quoteNumber}
						</Typography>
						{isLoading && <CircularProgress style={{ marginRight: 30 }} color="primary" />}
						{/* ACTION BUTTONS */}
						{/* DROPDOWN MENU */}
						<div className={classes.orderMenu}>
							<Button
								size="small"
								variant="outlined"
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleClick}>
								Order Menu
							</Button>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
								onClick={handleMenuClose}>
								{/* //dropdown items here..... */}
								{/* edit */}
								<MenuItem dense onClick={onOpenOrderEditDialog}>
									<EditIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />
									<Typography>Edit</Typography>
								</MenuItem>
								<MenuItem
									dense
									onClick={() => {
										onShowAlertModal(
											"Delete",
											`Are you sure you want to remove order for ${orderCardInfo.quoteNumber}?`,
											{ method: "deleteOrder" },
										);
									}}>
									<DeleteIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />
									<Typography>Delete</Typography>
								</MenuItem>
								{/* storno */}
								<MenuItem
									dense
									onClick={() => {
										onShowAlertModal(
											"Credit note",
											`Are you sure you want to issue a credit note/storno for invoice ${orderCardInfo.orderNumber} (${orderCardInfo.customerName})?`,
											{ method: "stornoOrder" },
										);
									}}>
									<UndoIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />
									<Typography>Storno</Typography>
								</MenuItem>
								{/* GENERATE PDF*/}
								<MenuItem onClick={() => onGenerateOrderPdf(true)}>
									<PictureAsPdfIcon color="primary" fontSize="small" style={{ marginRight: 5 }} />
									<Typography color="primary">Save PDF</Typography>
								</MenuItem>
								{/* GENERATE PDF - no barcode*/}
								<MenuItem onClick={() => onGenerateOrderPdf(false)}>
									<PictureAsPdfIcon color="primary" fontSize="small" style={{ marginRight: 5 }} />
									<Typography color="primary">PDF no barcode</Typography>
								</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
				{/* ORDER DETAILS VIEW */}
				<OrderCardDetails />
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	salesOrdersState: state.salesorders,
	isLoading: state.salesorders.isLoading,
	orderCardIsVisible: state.salesorders.orderCardIsVisible,
	orderCardInfo: state.salesorders.orderCardInfo,
});

const mapDispatchToProps = (dispatch) => ({
	onCloseOrderCard: () => dispatch(actionCreators.setOrderCardIsVisible(false)),
	onShowAlertModal: (title, message, modalProps) =>
		dispatch(actionCreators.showAlertModal(title, message, modalProps)),
	onOpenOrderEditDialog: () => dispatch(actionCreators.openEditOrderDialog()),
	onGenerateOrderPdf: (withBarcode) => dispatch(actionCreators.generateOrderPdf(withBarcode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);

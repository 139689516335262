import React, {useRef} from "react";
import { connect } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import * as actionCreators from "../store/actions/index";


import "./Styles.css";
import axios from "axios";


const useStyles = makeStyles((theme) => ({
	header: {},
}));

const Test = (props) => {
	const classes = useStyles();

	const currFilter = "projectInfo"
	const value = "";

	const test = () => {
		console.log("ordersArray-->",props.quoteArray);

		props.quoteArray.forEach(item => 
			console.log(item.quoteInputValues[Object.keys(item.quoteInputValues)[0]].pdv)
			)
	}

	
	const  deleteEntries = async () => {
		props.onDeleteEntries();
		
	}
	const  recordCount = async () => {
		props.onRecordCount();
		
	}
	


	

	return (
		<div>
			<Button onClick={test}>testing1</Button>
			<Button onClick={deleteEntries}>testing deletion</Button>
			<div>
			<Button onClick={recordCount}>Record count</Button>
			</div>
			
		</div>
		
	);
};

const mapStateToProps = (state) => ({
	quoteArray: state.salesquotes.quotes,
	ordersArray: state.salesorders.orders,
	token: state.auth.token,
	isLoading:state.customers.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
	//add action and call it with props.action name
	onDeleteEntries: () => dispatch(actionCreators.deleteDbEntriesTest111()),
	onRecordCount: () => dispatch(actionCreators.recordCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Test);

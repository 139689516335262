import axios from "axios";
import * as actionCreators from "./index";
import { saveAs } from "file-saver";

export const setIsLoadingOrdersScreen = (isLoading) => {
	return {
		type: "[SALES_ORDERS] IS_LOADING",
		isLoading,
	};
};
export const setNewOrderFormVisible = (isVisible) => {
	return {
		type: "[SALES_ORDERS] SET_NEW_ORDER_FORM_IS_VISIBLE",
		isVisible,
	};
};
export const setOrderCardIsVisible = (orderCardIsVisible) => {
	return {
		type: "[SALES_ORDERS] SET_ORDER_CARD_IS_VISIBLE",
		orderCardIsVisible,
	};
};
export const setOrders = (orders) => {
	return {
		type: "[SALES_ORDERS] SET_ORDERS",
		orders,
	};
};
export const setOrderCardInfo = (orderCardInfo) => {
	return {
		type: "[SALES_ORDERS] SET_ORDER_CARD_INFO",
		orderCardInfo,
	};
};
export const setOrderCardBarCode = (barCode) => {
	return {
		type: "[SALES_ORDERS] SET_ORDER_CARD_BARCODE",
		barCode,
	};
};
///work
export const setPdfItemsToPrint = (ordersTableRow) => {
	return {
		type: "[SALES_ORDERS] SET_PDF_ITEMS_TO_PRINT",
		ordersTableRow,
	};
};
export const resetSalesOrdersInitialState = () => {
	return {
		type: "[SALES_ORDERS] RESET_STATE",
	};
};
export const setSalesOrderField = (field, value) => {
	return {
		type: "[SALES_ORDERS] SET_STATE_FIELD",
		field,
		value
	};
};

export const completeOrder = (orderId, isCompleted) => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingOrdersScreen(true));
			//send to db
			const data = {
				orderId,
				isCompleted,
			};
			const result = await axios.patch(
				process.env.REACT_APP_NODE_URL + "/api/orders/completeOrder",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			// console.log(result);
			//update orders
			dispatch(actionCreators.getOrdersWithLimit());
			//show shanckbar -  (only in case when we check finished)
			if (result.data.order.orderNumber.length > 0) {
				dispatch(actionCreators.showSnackbar(`Order ${result.data.order.orderNumber} completed`));
			}
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

export const openOrderCard = (orderInfo) => {
	return async (dispatch, getState) => {
		try {
			await dispatch(setOrderCardInfo(orderInfo));
			await dispatch(setOrderCardIsVisible(true));
		} catch (error) {}
	};
};

export const getOrders = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingOrdersScreen(true));
			const result = await axios.get(process.env.REACT_APP_NODE_URL + "/api/orders", {
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			// console.log(result)
			dispatch(setOrders(result.data.orders));
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

export const getOrdersWithLimit = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingOrdersScreen(true));
			const data = {
				numberOfRecords: 200
			};
			const result = await axios.post(process.env.REACT_APP_NODE_URL + "/api/orders/getOrdersWithLimit", 
			data,
			{
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			// console.log("salesOrders [getOrdersWithLimit]: ",result.data)
			dispatch(setOrders(result.data.orders));
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

export const saveNewOrder = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingOrdersScreen(true));
			const data = {
				orderDate: getState().neworderform.orderDate,
				customerId: getState().neworderform.orderCustomerId,
				customerName: getState().neworderform.orderCustomerName,
				isForeignCurrency: getState().neworderform.isForeignCurrency,
				quoteId: getState().neworderform.quoteId,
				quoteNumber: getState().neworderform.quoteNumber,
				orderInputValues: getState().neworderform.orderInputValues,
				orderNoteInputValues: getState().neworderform.noteInputValues,
				isCompleted: false,
				totalValue: getState().neworderform.totalValue,
				exchangeRate: getState().neworderform.exchangeRate,
				projectInfo: getState().neworderform.projectInfo,
				paymentDueDays: getState().neworderform.paymentDueDays ,

			};
			const result = await axios.post(
				process.env.REACT_APP_NODE_URL + "/api/orders/createOrder",
				data,
				{
					headers: { Authorization: "Bearer " + getState().auth.token },
				},
			);

			//close new quote form
			dispatch(setNewOrderFormVisible(false));
			//reset new order state
			dispatch(actionCreators.resetNewOrderState());
			//update quotes
			dispatch(actionCreators.getQuotes());
			//show shanckbar
			dispatch(actionCreators.showSnackbar(`Order created successfully`));
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

export const updateOrder = () => {
	return async (dispatch, getState) => {
		dispatch(setIsLoadingOrdersScreen(true));
		try {
			//total quote value
			let totalValue = 0;
			for (const key in getState().neworderform.orderInputValues) {
				totalValue += +getState().neworderform.orderInputValues[key].total;
			}
			const data = {
				orderId: getState().salesorders.orderCardInfo._id,
				orderDate: getState().neworderform.orderDate,
				orderInputValues: getState().neworderform.orderInputValues,
				noteInputValues: getState().neworderform.noteInputValues,
				totalValue,
				projectInfo: getState().neworderform.projectInfo,
				paymentDueDays: getState().neworderform.paymentDueDays ,
			};
			const response = await axios.patch(
				process.env.REACT_APP_NODE_URL + "/api/orders/updateOrder",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			// console.log(response);
			//close newOrderForm & order details
			dispatch(setNewOrderFormVisible(false));
			dispatch(setOrderCardIsVisible(false));
			//reset newOrderForm
			dispatch(actionCreators.resetNewOrderState());
			//refresh orders
			dispatch(getOrdersWithLimit());
			//show snack
			dispatch(actionCreators.showSnackbar(`Order updated successfully`));
		} catch (error) {
			actionCreators.showErrorModal(error.response.data.message || "Something went wrong");
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

export const deleteOrder = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingOrdersScreen(true));
			//completed orders can't be deleted
			if (getState().salesorders.orderCardInfo.orderNumber.length > 0) {
				dispatch(
					actionCreators.showErrorModal(
						"Completed orders cannot be deleted, please issue new credit note (storno).",
					),
				);
				return;
			}
			const data = {
				orderId: getState().salesorders.orderCardInfo._id,
				quoteId: getState().salesorders.orderCardInfo.quoteId,
			};

			const result = await axios.put(
				process.env.REACT_APP_NODE_URL + "/api/orders/deleteOrder",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			dispatch(setOrderCardIsVisible(false));
			//clear form input and note fields
			dispatch(resetSalesOrdersInitialState());
			//show snackbar message
			dispatch(actionCreators.showSnackbar(`Order deleted successfully`));
			//reload sales_orders
			dispatch(getOrdersWithLimit());
		} catch (error) {
			// console.log("salesQuotes actions [getQuotes] :", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

export const stornoOrder = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingOrdersScreen(true));
			//Only completed orders can be storno'd
			if (!getState().salesorders.orderCardInfo.orderNumber.length > 0) {
				dispatch(
					actionCreators.showErrorModal(
						"Credit note (storno) can only be issued for completed/invoiced orders.\nUnclompleted orders can be deleted.",
					),
				);
				return;
			}
			const data = {
				orderId: getState().salesorders.orderCardInfo._id,
			};
			const result = await axios.put(
				process.env.REACT_APP_NODE_URL + "/api/orders/stornoOrder",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			dispatch(setOrderCardIsVisible(false));
			//clear form input and note fields
			dispatch(resetSalesOrdersInitialState());
			//show snackbar message
			dispatch(
				actionCreators.showSnackbar(
					`New order ${result.data.createdOrder.orderNumber} created successfully`,
				),
			);
			//reload sales_orders
			dispatch(getOrdersWithLimit());
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

export const changeOrderDate = (values) => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingOrdersScreen(true));
			const data = {
				orderId: values.orderId,
				newDate: values.newDate,
			};
			const result = await axios.patch(
				process.env.REACT_APP_NODE_URL + "/api/orders/changeOrderDate",
				data,
				{ headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			//reload sales_orders
			dispatch(getOrdersWithLimit());
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingOrdersScreen(false));
		}
	};
};

//using same code from generatePDF(quote) just passing parameters from order
//no separate route/endpoint will be needed
//server will generate pdf same as for quote
export const generateOrderPdf = (withBarcode) => {
	//withBarcode is bool - sends weather to print barcode
	return async (dispatch, getState) => {
		try {
			dispatch(actionCreators.setIsLoadingOrdersScreen(true));
			const selectedRow = getState().salesorders.orderCardInfo;
			const data = {
				quoteNumber: selectedRow.orderNumber,
				date: selectedRow.orderDate,
				createdDate: selectedRow.createdDate,
				customerId: selectedRow.customerId,
				foreignCurrency: selectedRow.isForeignCurrency,
				quoteInputValues: selectedRow.orderInputValues,
				noteInputValues: selectedRow.orderNoteInputValues,
				totalValue: selectedRow.totalValue,
				exchangeRate: selectedRow.exchangeRate,
				orderNumber: selectedRow.orderNumber,
				isOrderCreated: true,
				withBarcode,
				isOrder: true,
				projectInfo:selectedRow.projectInfo,
				paymentDueDays:selectedRow.paymentDueDays,
			};
			const result = await axios.post(process.env.REACT_APP_NODE_URL + "/api/pdf/createPdf", data, {
				responseType: "blob",
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			const pdfBlob = new Blob([result.data], { type: "application/pdf" });
			saveAs(pdfBlob, data.quoteNumber || "Invoce-not-closed");
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(actionCreators.setIsLoadingOrdersScreen(false));
		}
	};
};

export const generateMultiplePdf = (withBarcode) => {
	//table rows from r
	return async (dispatch, getState) => {
		try {
			dispatch(actionCreators.setIsLoadingOrdersScreen(true));
			if (getState().salesorders.pdfItemsArray.length === 0) {
				dispatch(
					actionCreators.showErrorModal("Plese select files you would like to save as PDFs."),
				);
				return;
			}
			const data = {
				files: getState().salesorders.pdfItemsArray,
				withBarcode,
			};
			const result = await axios.post(
				process.env.REACT_APP_NODE_URL + "/api/pdf/createMultiplePdf",
				data,
				{ responseType: "blob", headers: { Authorization: "Bearer " + getState().auth.token } },
			);
			const pdfBlob = new Blob([result.data], { type: "application/zip" });
			saveAs(pdfBlob, `DownloadedPDFs-${(new Date()).toLocaleDateString('en-US')}`);
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(actionCreators.setIsLoadingOrdersScreen(false));
		}
	};
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as actionCreators from "../../../store/actions/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EventIcon from "@material-ui/icons/Event";
import DynamicQuoteLine from "./DynammicQuoteLine";
import DynamicNoteLine from "./DynammicNoteLine";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	AppBar,
	Button,
	Card,
	CircularProgress,
	Container,
	Dialog,
	Divider,
	FormControlLabel,
	IconButton,
	InputAdornment,
	MenuItem,
	Slide,
	Switch,
	TextField,
	Toolbar,
	Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveIcon from "@material-ui/icons/Save";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	root: {},
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	card: {
		padding: "5px 10px",
		backgroundColor: "#f5f5f5",
		marginTop: 15,
	},
	card_input: {
		margin: "10px 0px",
	},
	card__customerAndSwitchContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
	},
	card__title: {
		fontSize: 16,
		textAlign: "center",
		color: "#bdbdbd",
		marginBottom: 5,
	},
	divider: {
		marginTop: 25,
		marginBottom: 25,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const NewQuoteForm = (props) => {
	const {
		onShowErrorModal,
		newQuoteFormIsVisible,
		onCloseNewQuoteForm,
		customersArray,
		isLoading,
		onAddNewNoteLine,
		noteInputsObj,
		quotesInputsObj,
		onAddNewQuoteLine,
		onSaveNewQuote,
		onUpdateQuote,
		quoteDate,
		quoteCustomer,
		isForeignCurrency,
		onSetDate,
		onSetCustomer,
		onSetIsForeignCurrency,
		isEditing,
		quoteCardInfo,
		onGetStandardNotes,
		standardNotesArray,
		onApplySelectedNote,
		onSetQuoteProjectInfo,
		quoteProjectInfo,
		onResetNewQuteFormState
	} = props;
	const classes = useStyles();

	useEffect(() => {
		onGetStandardNotes();
	}, [onGetStandardNotes]);

	const [note, setNote] = useState("");

	const handleClose = () => {
		onCloseNewQuoteForm();
		//reset new quote state
		onResetNewQuteFormState();
	};

	const saveFormHandler = () => {
		//Validation
		if (!quoteDate || !quoteCustomer) {
			onShowErrorModal("Date and customer fields cannot be empty.");
			return;
		}
		for (const key in quotesInputsObj) {
			if (quotesInputsObj[key].price === 0 || !quotesInputsObj[key].price) {
				onShowErrorModal("Price field cannot be null or empty.");
				return;
			}
		}
		//<--
		//data for saving and updateing quote is pulled in action directly from reducer
		//if we are editing use update quote action
		if (isEditing) {
			onUpdateQuote();
		} else {
			onSaveNewQuote();
		}
	};

	return (
		<div className={classes.root}>
			<Dialog
				fullScreen
				open={newQuoteFormIsVisible}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				maxWidth="md"
				TransitionComponent={Transition}>
				{/* APPBAR */}
				<AppBar className={classes.appBar} color="secondary" style={{}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<ArrowBackIcon fontSize="large" />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{isEditing ? quoteCardInfo && "Editing " + quoteCardInfo.quoteNumber : "New Quote"}
						</Typography>
						{isLoading && <CircularProgress style={{ marginRight: 30 }} color="primary" />}
						{/* ACTION BUTTONS */}
						<div>
							<Button
								onClick={saveFormHandler}
								size="small"
								variant="contained"
								color="primary"
								startIcon={<SaveIcon />}>
								Save
							</Button>
						</div>
					</Toolbar>
				</AppBar>
				<Container maxWidth="xl">
					{/* CUSTOMER CUSTOMER CUSTOMER CUSTOMER CUSTOMER CUSTOMER CUSTOMER CUSTOMER */}
					<Card className={classes.card}>
						<Typography className={classes.card__title}>Customer</Typography>
						{/* DATE */}
						<div>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									autoOk
									size="small"
									value={quoteDate}
									onChange={(date) => {
										onSetDate(date);
									}}
									format="d/MM/yyyy"
									inputVariant="outlined"
									style={{ width: 200 }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<EventIcon color="primary" />
											</InputAdornment>
										),
									}}
								/>
							</MuiPickersUtilsProvider>
						</div>
						{/* CUSTOMER + SWITCH container (for horizonatal spacing) */}
						<div className={classes.card__customerAndSwitchContainer}>
							{/* CUTOMER */}
							<div className={classes.card_input}>
								<Autocomplete
									onChange={(event, newValue) => {
										onSetCustomer(newValue);
									}}
									value={quoteCustomer}
									options={customersArray}
									getOptionLabel={(option) => option.name}
									renderInput={(params) => {
										return (
											<TextField
												value={quoteCustomer}
												size="small"
												margin="dense"
												{...params}
												style={{ width: 300 }}
												variant="outlined"
												label="Customer"
												InputProps={{
													...params.InputProps,
													startAdornment: (
														<InputAdornment position="start">
															<HomeWorkIcon color="primary" />
														</InputAdornment>
													),
												}}
											/>
										);
									}}
								/>
							</div>
							{/* SWITCH - disabled in editing mode */}
							<div style={{ float: "right" }}>
								<FormControlLabel
									control={
										<Switch
											checked={isForeignCurrency}
											onChange={() => {
												if (isForeignCurrency === false) {
													onSetIsForeignCurrency(true);
												} else {
													onSetIsForeignCurrency(false);
												}
											}}
											name="switchState"
											label="Legal Entity"
										/>
									}
									label="English language"
									// label={switchState ? "Legal Entity" : "Person"}
								/>
							</div>
						</div>
					</Card>
					{/* PROJECT INFO */}
					<Accordion
						style={{ padding: "5px 10px", marginTop: 2, backgroundColor: "#fafafa" }}
						defaultExpanded={quoteProjectInfo ? quoteProjectInfo.length > 0 : false}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header">
							<Typography style={{ color: "#bdbdbd" }}>Project Details (optional)</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{/* rendered INPUTS */}
							<TextField
								defaultValue={quoteProjectInfo}
								multiline
								rowsMax={4}
								style={{ width: "100%" }}
								size="small"
								type="text"
								label="Project description"
								helperText="e.g. EU project 8852: final review for project P5543-J."
								variant="outlined"
								onChange={(e) => onSetQuoteProjectInfo(e.target.value)}
								inputProps={{
									style: {
										fontSize: 14,
										marginBottom: "0px",
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 14,
									},
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Divider className={classes.divider} />
					{/* QUOTE QUOTE QUOTE QUOTE QUOTE QUOTE QUOTE QUOTE QUOTE QUOTE QUOTE QUOTE  */}
					<Card className={classes.card}>
						<Typography className={classes.card__title}>Quote items</Typography>
						{/* rendered INPUTS */}
						{Object.keys(quotesInputsObj).map((key, index) => (
							<DynamicQuoteLine key={key} lineId={key} />
						))}
						{/* ADD quote line -BUTTON */}
						<Tooltip title="Add new item">
							<Button
								onClick={onAddNewQuoteLine}
								size="small"
								startIcon={<AddCircleIcon color="secondary" style={{ fontSize: 30 }} />}>
								New item
							</Button>
						</Tooltip>
					</Card>
					<Divider className={classes.divider} />
					{/* NOTES NOTES NOTES NOTES NOTES NOTES NOTES NOTES NOTES NOTES NOTES NOTES NOTES */}
					<Card className={classes.card} style={{ marginBottom: 20 }}>
						<Typography className={classes.card__title}>Notes</Typography>
						{/* rendered NOTES */}
						{noteInputsObj &&
							Object.keys(noteInputsObj).map((key, index) => (
								<DynamicNoteLine key={key} lineId={key} />
							))}
						{/* ADD note line -BUTTON */}
						<Tooltip title="Add new note">
							<Button
								onClick={() => onAddNewNoteLine("", "")}
								size="small"
								startIcon={<AddCircleIcon color="secondary" style={{ fontSize: 30 }} />}>
								New note
							</Button>
						</Tooltip>
						{/* adding standard notes from db menu list */}
						<div style={{ float: "right", paddingBottom: 12 }}>
							<TextField
								style={{ minWidth: 250 }}
								inputProps={{
									style: { fontSize: 10 },
								}}
								variant="outlined"
								size="small"
								select
								label="Select note"
								value={note}
								onChange={(event) => setNote(event.target.value)}>
								{standardNotesArray.map((option) => (
									<MenuItem key={option._id} value={option}>
										{option.name}
									</MenuItem>
								))}
							</TextField>
							<IconButton
								style={{ marginLeft: 5 }}
								size="small"
								onClick={() => {
									onApplySelectedNote(note);
								}}>
								{/* check if we have set note - if yes display tooltip with text */}
								{note.description ? (
									<Tooltip title={note.description}>
										<AddBoxIcon color="secondary" style={{ fontSize: 30 }} />
									</Tooltip>
								) : (
									<PlaylistAddIcon color="secondary" style={{ fontSize: 30 }} />
								)}
							</IconButton>
						</div>
					</Card>
				</Container>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	newQuoteFormIsVisible: state.salesquotes.newQuoteFormIsVisible,
	isLoading: state.salesquotes.isLoading,
	customersArray: state.customers.customers,
	quotesInputsObj: state.newquoteform.quoteInputValues,
	noteInputsObj: state.newquoteform.noteInputValues,
	quoteCardInfo: state.salesquotes.quoteCardInfo,
	standardNotesArray: state.newquoteform.standardNotes,

	quoteDate: state.newquoteform.quoteDate,
	quoteCustomer: state.newquoteform.quoteCustomer,
	isForeignCurrency: state.newquoteform.isForeignCurrency,
	isEditing: state.newquoteform.isEditing,

	quoteProjectInfo: state.newquoteform.projectInfo,
});

const mapDispatchToProps = (dispatch) => ({
	onShowErrorModal: (message) => dispatch(actionCreators.showErrorModal(message)),
	onCloseNewQuoteForm: () => dispatch(actionCreators.setNewQuoteFormVisible(false)),
	onGetStandardNotes: () => dispatch(actionCreators.getStandardQuoteNotes()),

	onAddNewNoteLine: () => dispatch(actionCreators.addNewNoteLine()),
	onAddNewQuoteLine: () => dispatch(actionCreators.addNewQuoteLine()),
	onSaveNewQuote: (values) => dispatch(actionCreators.saveNewQuote(values)),
	onUpdateQuote: () => dispatch(actionCreators.updateQuote()),

	onSetDate: (payload) => dispatch(actionCreators.setQuoteDate(payload)),
	onSetCustomer: (payload) => dispatch(actionCreators.setQuoteCustomer(payload)),
	onSetIsForeignCurrency: (payload) => dispatch(actionCreators.setQuoteisForeignCurrency(payload)),
	onApplySelectedNote: (noteId) => dispatch(actionCreators.applyQuoteNote(noteId)),

	onSetQuoteProjectInfo: (value) => dispatch(actionCreators.setQuoteProjectInfo(value)),
	onResetNewQuteFormState:()=>dispatch(actionCreators.resetNewQuoteInitialState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewQuoteForm);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem, TableHead, Tooltip, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { QuoteTablePaginationActions } from "./QuoteTablePaginationActions";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import QuickQuotePreview from "./QuoteQuickPreview";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";

const useStyles2 = makeStyles({
	root: {
		padding: 20,
		marginTop: 20,
	},
	table: {
		minWidth: 500,
	},
	actions__container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	actions__pdfLink: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
	},
	elipsisContainer: {
		display: "block",
		width: 200,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
});

const QuotesTable = (props) => {
	const classes = useStyles2();
	const {
		quoteArray,
		searchQuery,
		filterBy,
		onOpenQuoteCard,
		onSetQuoteCardInfo,
		onOpenNewOrderForm,
		onCopyQuote,
		onOpenEditQuoteDialog,
		onGeneratePdf,
	} = props;
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);

	const filteredQuotesArray = quoteArray.filter((item) => {
		//since filterBy is set globally we have to check if our array has this prop
		//instead of manually setting name we can pass filters array and select 1st value
		if (typeof item[filterBy] === "undefined" || !filterBy) {
			return item["quoteNumber"].toLowerCase().includes(searchQuery.toLowerCase());
		} else {
			return item[!filterBy ? "quoteNumber" : filterBy]
				.toLowerCase()
				.includes(searchQuery.toLowerCase());
		}
	});

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, filteredQuotesArray.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const viewQuoteDetailsHandler = async (data) => {
		onOpenQuoteCard(data);
	};

	const [hoverDetailIsVisible, setHoverDetailIsVisible] = useState(false);
	const [rowDetails, setRowDetails] = useState();

	//dotted row dropdown
	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuClickHandler = (event, row) => {
		setAnchorEl(event.currentTarget);
		setQuoteDetailsOnMenuClick(row);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	//ON dotted menu click in row -> creates barcode & sets clicked row details in quoteDetailCard
	const setQuoteDetailsOnMenuClick = (data) => {
		onSetQuoteCardInfo(data);
	};

	//set page to 0 (first page) after search query is selected/applied
	useEffect(() => {
		if (page > 0) {
			setPage(0);
		}
	}, [searchQuery]);

	//calculates and returns total value with pdv for each item(row) in table
	const calculateWithPdvHelper = (row) => {
		const pdv = row.quoteInputValues[Object.keys(row.quoteInputValues)[0]].pdv;
		const pdvResult = "1." + pdv.toString();
		const totalWithPdv = ( (row.totalValue * +pdvResult)).toFixed(2)
		return totalWithPdv;
	}

	return (
		<div className={classes.root}>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="custom pagination table" size="small">
					<TableHead>
						<TableRow>
							<TableCell align="left">Date</TableCell>
							<TableCell>SQ number</TableCell>
							<TableCell>Customer</TableCell>
							<TableCell>Project Info</TableCell>
							<TableCell>Value</TableCell>
							<TableCell>Invoice #</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredQuotesArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: filteredQuotesArray
						).map((row) => (
							<TableRow key={row._id}>
								<TableCell width={135} component="th" scope="row">
									{format(parseISO(row.date), "dd/MM/yy")}
								</TableCell>
								<TableCell width={120} align="left">
									{row.quoteNumber}
								</TableCell>
								{/* customer name */}
								<TableCell width={250} align="left">
									{row.customerName}
								</TableCell>
								{/* project info */}
								<TableCell width={200} align="left">
									<Tooltip title={row.projectInfo}>
										<div className={classes.elipsisContainer}>{row.projectInfo}</div>
									</Tooltip>
								</TableCell>
								{/* total value */}
								<TableCell width={100} align="left">
									{/* return total value with included pdv*/}
									{calculateWithPdvHelper(row)}
									<span style={{ color: "silver", fontSize: 12, marginLeft: 3 }}>
										{row.foreignCurrency ? "€ EN" : "€"}
									</span>
								</TableCell>
								{/* invoice number */}
								<TableCell width={100} align="left">{row.orderNumber}</TableCell>
								{/* ACTIONS ACTIONS ACTIONS ACTIONS */}
								<TableCell align="left" width={150}>
									<div className={classes.actions__container}>
										{/* hover details */}
										<div>
											<IconButton
												onMouseEnter={() => {
													setHoverDetailIsVisible(true);
													setRowDetails(row);
												}}
												onMouseLeave={() => {
													setHoverDetailIsVisible(false);
												}}
												style={{ padding: 0, margin: 0 }}>
												<VisibilityIcon fontSize="small" color={"primary"} />
											</IconButton>
											{/* open details */}
											<Tooltip title="View details">
												<IconButton
													onClick={() => viewQuoteDetailsHandler(row)}
													style={{ padding: 0, marginLeft: 4 }}>
													<OpenInNewIcon fontSize="small" color={"primary"} />
												</IconButton>
											</Tooltip>
											{/* //  */}
											{row.isOrderCreated && row.orderNumber.length === 0 && (
												<Tooltip title="Order is created">
													<IconButton style={{ padding: 0, marginLeft: 4 }}>
														<CheckCircleOutlineIcon fontSize="small" style={{ fill: "#ffb74d" }} />
													</IconButton>
												</Tooltip>
											)}
											{row.isOrderCreated && row.orderNumber.length > 1 && (
												<Tooltip title="Order is created and invoiced">
													<IconButton style={{ padding: 0, marginLeft: 4 }}>
														<CheckCircleOutlineIcon fontSize="small" color="secondary" />
													</IconButton>
												</Tooltip>
											)}
										</div>
										{/* dots row menu */}
										<div>
											<IconButton
												aria-controls="simple-menu"
												aria-haspopup="true"
												onClick={(event) => menuClickHandler(event, row)}
												style={{ padding: 0, marginLeft: 5 }}>
												<MoreVertIcon fontSize="small" color="primary" />
											</IconButton>
											<Menu
												elevation={2}
												id="simple-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleClose}>
												<div onClick={handleClose}>
													{/* create order */}
													<MenuItem onClick={onOpenNewOrderForm}>
														<LocalAtmIcon
															color="primary"
															fontSize="small"
															style={{ marginRight: 5 }}
														/>
														<Typography color="primary">Create order</Typography>
													</MenuItem>
													{/* save copy*/}
													<MenuItem onClick={onCopyQuote}>
														<FileCopyIcon
															color="primary"
															fontSize="small"
															style={{ marginRight: 5 }}
														/>
														<Typography color="primary">Save copy</Typography>
													</MenuItem>
													{/* edit */}
													<MenuItem onClick={onOpenEditQuoteDialog}>
														<EditIcon color="primary" fontSize="small" style={{ marginRight: 5 }} />
														<Typography color="primary">Edit</Typography>
													</MenuItem>
													{/* GENERATE PDF*/}
													<MenuItem onClick={() => onGeneratePdf(true)}>
														<PictureAsPdfIcon
															color="primary"
															fontSize="small"
															style={{ marginRight: 5 }}
														/>
														<Typography color="primary">Save PDF</Typography>
													</MenuItem>
													{/* GENERATE PDF - no barcode*/}
													<MenuItem onClick={() => onGeneratePdf(false)}>
														<PictureAsPdfIcon
															color="primary"
															fontSize="small"
															style={{ marginRight: 5 }}
														/>
														<Typography color="primary">PDF no barcode</Typography>
													</MenuItem>
												</div>
											</Menu>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[15, 20, 30]} // , { label: 'All', value: -1 }
								colSpan={3}
								count={filteredQuotesArray.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={QuoteTablePaginationActions}
								style={{ overflow: "visible" }}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			{/* Quck details preview */}
			{hoverDetailIsVisible && (
				<QuickQuotePreview rowDetails={rowDetails} fadeIn={hoverDetailIsVisible} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	quoteArray: state.salesquotes.quotes,
	searchQuery: state.searchbar.searchQuery,
	filterBy: state.searchbar.filterBy,
	quoteCardVisible: state.salesquotes.quoteCardVisible,
});

const mapDispatchToProps = (dispatch) => ({
	onOpenQuoteCard: (quoteInfo) => dispatch(actionCreators.openQuoteCard(quoteInfo)),
	onSetQuoteCardInfo: (quoteInfo) => dispatch(actionCreators.setQuoteCardInfo(quoteInfo)),
	onOpenNewOrderForm: () => dispatch(actionCreators.openNewOrderForm()),
	onCopyQuote: () => dispatch(actionCreators.copyQuote()),
	onOpenEditQuoteDialog: () => dispatch(actionCreators.openEditQuoteDialog()),
	onGeneratePdf: (withBarcode) => dispatch(actionCreators.generatePdf(withBarcode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotesTable);

import React, { useState } from 'react'
import { Grid, makeStyles, TextField, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { connect } from "react-redux";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import "./DynamicOrderLine.css"
import * as actionCreators from "../../../store/actions/index"

const useStyles = makeStyles((theme) => ({
   root: {
      width: "100%",
      marginBottom: 20
   },
   grid__main: {
   },
   inputField: {
   },
}));


const DynammicQuoteLine = (props) => {
   const classes = useStyles();
   const {
      lineId,
      onOrderValuesChange,
      storeValues,
      onRemoveOrderLine,
   } = props;
   const valuta = "€"

   const [inputValues, setInputValues] = useState({
      id: lineId,
      itemName: storeValues[lineId].itemName,
      description: storeValues[lineId].description,
      quantity: storeValues[lineId].quantity,
      unit: storeValues[lineId].unit,
      price: storeValues[lineId].price,
      discountPercentage: storeValues[lineId].discountPercentage,
      discountValue: storeValues[lineId].discountValue,
      pdv: storeValues[lineId].pdv,
      total: storeValues[lineId].total
   });

   const onInputChange = (fieldName, value) => {
       //reset discountPercentage and discountValue fields to 0 if those fields are empty/null/NaN
      if (fieldName==="discountPercentage" && isNaN(value)) {
         value = 0
      }
      if (fieldName==="discountValue" && isNaN(value)) {
         value = 0
      }
      let newInputValues = { ...inputValues };
      newInputValues[fieldName] = value
      //test
      if (isNaN(newInputValues["discountPercentage"])) {
         newInputValues["discountPercentage"] = 0
      }
      if (isNaN(newInputValues["price"])) {
         newInputValues["price"] = 0
      }
      if (isNaN(newInputValues["discountValue"])) {
         newInputValues["discountValue"] = 0
      }

      if (fieldName === "price") {
         newInputValues["discountPercentage"] = parseFloat((newInputValues.discountValue / newInputValues.price) * 100).toFixed(2);
      }
      //ability when discountValue is changed that it influences discountPercantage and vice versa
      if (fieldName === "discountPercentage") {
         newInputValues["discountPercentage"] = value;
         newInputValues["discountValue"] = parseFloat(newInputValues.price - (newInputValues.price * ((100 - newInputValues.discountPercentage) / 100))).toFixed(2)
         // discountPerctangeRef.current.value=value;
         // console.log("mnew discountPercentage value -->", value)
      }
      if(fieldName === "discountValue"){
         newInputValues["discountValue"] = value;
         newInputValues["discountPercentage"] = parseFloat((newInputValues.discountValue / newInputValues.price) * 100).toFixed(2);
         // discountValueRef.current.value=value;
         // console.log("mnew discountValue value -->", value)
      }

      newInputValues["total"] = parseFloat(newInputValues.quantity * newInputValues.price * ((100 - newInputValues.discountPercentage) / 100)).toFixed(2)
      //
      setInputValues(newInputValues)
      onOrderValuesChange(lineId, newInputValues)
   }

   


   return (
      <div className={classes.root}>
         <Grid className={classes.grid__main} container direction="row" alignItems="center" spacing={1}>
            
            {/* ITEM NAME */}
            <Grid item className="textField__multiline">
               <TextField
                  autoFocus
                  multiline
                  rowsMax={4}
                  defaultValue={storeValues[lineId].itemName}
                  size="small"
                  type="text"
                  label="Item"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     }
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("itemName", e.target.value)
                  }
               />
            </Grid>
            {/* DESCRIPTION */}
            <Grid item className="textField__multiline">
               <TextField
                  defaultValue={storeValues[lineId].description}
                  multiline
                  rowsMax={4}
                  rows={2}
                  style={{ width: 500 }}
                  size="small"
                  type="text"
                  label="Description"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("description", e.target.value)
                  }
               />
            </Grid>
            {/* QUANTITY */}
            <Grid item className="textField__oneLiner">
               <TextField
                  defaultValue={storeValues[lineId].quantity}
                  style={{ width: 50 }}
                  size="small"
                  type="number"
                  label="Qty."
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("quantity", +e.target.value)
                  }
               />
            </Grid>
            {/* UNIT */}
            <Grid item className="textField__oneLiner">
               <TextField
                  defaultValue={storeValues[lineId].unit}
                  style={{ width: 80 }}
                  size="small"
                  type="text"
                  label="Unit"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("unit", e.target.value)
                  }
               />
            </Grid>
            {/* PRICE */}
            <Grid item className="textField__oneLiner">
               <TextField
                  defaultValue={storeValues[lineId].price}
                  style={{ width: 100 }}
                  size="small"
                  type="number"
                  label="Price/pc"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("price", +e.target.value)
                  }
                  InputProps={{
                     startAdornment: <InputAdornment position="start"><p style={{ fontSize: 10 }}>{valuta}</p></InputAdornment>,
                  }}
               />
            </Grid>
            {/* % DISCOUNT */}
            <Grid item className="textField__oneLiner">
               <TextField
                  value={storeValues[lineId].discountPercentage}
                  style={{ width: 80 }}
                  size="small"
                  type="number"
                  label="Disc."
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("discountPercentage", parseFloat(e.target.value))
                  }
                  InputProps={{
                     startAdornment: <InputAdornment position="start"><p style={{ fontSize: 10 }}>%</p></InputAdornment>,
                  }}
               />
            </Grid>
            {/*  DISCOUNT */}
            <Grid item className="textField__oneLiner">
               <TextField
                  value={storeValues[lineId].discountValue}
                  style={{ width: 100 }}
                  size="small"
                  type="number"
                  label="Discount"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("discountValue", parseFloat(e.target.value))
                  }
                  InputProps={{
                     startAdornment: <InputAdornment position="start"><p style={{ fontSize: 10 }}>{valuta}</p></InputAdornment>,
                  }}
               />
            </Grid>
            {/* PDV */}
            <Grid item className="textField__oneLiner">
               <TextField
                  defaultValue={storeValues[lineId].pdv}
                  style={{ width: 60 }}
                  size="small"
                  type="number"
                  label="% PDV"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("pdv", +e.target.value)
                  }
               />
            </Grid>
            {/* TOTAL */}
            <Grid item className="textField__oneLiner">
               <TextField
                  disabled
                  value={storeValues[lineId].total}
                  style={{ width: 120 }}
                  size="small"
                  type="number"
                  label="total"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("total", parseFloat(e.target.value).toFixed(2))
                  }
                  InputProps={{
                     startAdornment: <InputAdornment position="start"><p style={{ fontSize: 10 }}>{valuta}</p></InputAdornment>,
                  }}
               />
            </Grid>
            {/* remove row button */}
            <Grid item style={{margin:0, padding:0}}>
               <Tooltip title="Remove item">
                  <IconButton size="small" onClick={() => onRemoveOrderLine(lineId)} >
                     <HighlightOffIcon style={{fill:"#e57373", fontSize: 22}}/>
                  </IconButton>
               </Tooltip>
            </Grid>
         </Grid>
      </div>
   )
}

const mapStateToProps = (state) => ({
   storeValues: state.neworderform.orderInputValues
});

const mapDispatchToProps = (dispatch) => ({
   onOrderValuesChange: (lineId, newValue) => dispatch(actionCreators.setNewOrderValue(lineId, newValue)),
   onRemoveOrderLine: (lineId) => dispatch(actionCreators.removeOrderLine(lineId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DynammicQuoteLine);
const uniqid = require("uniqid");

const initialState = {
	orderInputValues: {},
	noteInputValues: {},
	orderDate: new Date(),
	orderCustomerId: null,
	orderCustomerName: null,
	isForeignCurrency: false,
	isEditing: false,
	quoteId: "",
	quoteNumber: "",
	standardNotes: [],
	totalValue: null,
	exchangeRate: 1,
	projectInfo:"",
	paymentDueDays:null //due date in days
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "[NEW_ORDER_FORM] SET_STATE_FIELD":
			const newState = { ...state };
			newState[action.field] = action.value;
			return newState;

		case "[NEW_ORDER_FORM] SET_IS_EDITING":
			return {
				...state,
				isEditing: action.isEditing,
			};

		//ORDER ITEMS
		case "[NEW_ORDER_FORM] SET_ITEM_LINE_VALUE":
			const newOrderValues = { ...state.orderInputValues };
			newOrderValues[action.lineId] = action.newValue;
			return {
				...state,
				orderInputValues: newOrderValues,
			};
		//
		case "[NEW_ORDER_FORM] ADD_ORDER_LINE":
			const orderId = `DynamicOrderLine-${uniqid()}`;
			const newOrderLines = { ...state.orderInputValues };
			newOrderLines[orderId] = {
				id: orderId,
				itemName: "Usluga pisanog prijevoda teksta",
				description: "",
				quantity: 1,
				unit: "Kartica",
				price: 0,
				discountPercentage: 0,
				discountValue: 0,
				pdv: 25,
				total: 0,
			};
			return {
				...state,
				orderInputValues: newOrderLines,
			};
		case "[NEW_ORDER_FORM] REMOVE_ITEM_LINE":
			const withoutDeletedLine = { ...state.orderInputValues };
			delete withoutDeletedLine[action.lineId];
			return {
				...state,
				orderInputValues: withoutDeletedLine,
			};

		//NOTES
		case "[NEW_ORDER_FORM] SET_NOTE_LINE_VALUE":
         const newNoteValues = { ...state.noteInputValues };
			// const newNoteValues = cloneDeep(state.noteInputValues);
			newNoteValues[action.lineId] = action.newValue;
			return {
				...state,
				noteInputValues: newNoteValues,
			};
		case "[NEW_ORDER_FORM] ADD_NOTE_LINE":
			const noteId = `DynamicNoteLine-${uniqid()}`;
			const newNoteLines = { ...state.noteInputValues };
			newNoteLines[noteId] = {
				id: noteId,
				noteTitle: action.noteTitle,
				noteText: action.noteText,
			};
			return {
				...state,
				noteInputValues: newNoteLines,
			};
		case "[NEW_ORDER_FORM] REMOVE_NOTE_LINE":
			const withoutDeletedQuote = { ...state.noteInputValues };
			delete withoutDeletedQuote[action.lineId];
			return {
				...state,
				noteInputValues: withoutDeletedQuote,
			};
		case "[NEW_ORDER_FORM] LOAD_STANDARD_NOTES":
			return {
				...state,
				standardNotes: action.notesArray.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
			};

		//RESET
		case "[NEW_ORDER_FORM] RESET_STATE":
			return initialState;
		case "[NEW_ORDER_FORM] SET_VALUES_BEFORE_EDIT":
			return {
				...state,
				orderInputValues: action.orderInputValues,
				noteInputValues: action.noteInputValues,
				orderDate: action.orderDate,
				orderCustomerId: action.orderCustomerId,
				isForeignCurrency: action.isForeignCurrency,
				projectInfo: action.projectInfo,
				paymentDueDays: action.paymentDueDays,
			};

		default:
			return state;
	}
};

import React from "react";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	pageTitle__CKP: {
		color: "#334752",
		fontFamily: `'Pacifico', cursive`,
		marginTop: 10,
		marginBottom: 20,
		fontSize: 25,
	},
}));

const PageTitle = (props) => {
	const classes = useStyles();

	return (
		<div onClick={() => props.onClick()} style={{ cursor: "pointer" }}>
			<Tooltip title={props.tooltip}>
				<Typography className={classes.pageTitle__CKP}>{props.title}</Typography>
			</Tooltip>
		</div>
	);
};

export default PageTitle;

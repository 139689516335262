import React, { useState, useEffect } from "react";
// import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormInputNatural from "./FormInputsNatural";
import FormInputLegal from "./FormInputsLegal";
import {
	FormControlLabel,
	FormGroup,
	makeStyles,
	Switch,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	dialog__container: {
		width: "50vw",
		maxWidth: 700,
	},
	switch: {
		width: 160,
	},
	header__image: {
		height: 100,
		width: "100%",
		objectFit: "cover",
	},
}));

const NewCustomerForm = (props) => {
	const classes = useStyles();
	const {
		newCustomerFormVisible,
		onCloseCustomerForm,
		isEditing,
		selectedCustomerInfo,
	} = props;

	const [switchState, setSwitchState] = useState(
		selectedCustomerInfo.legalEntity
	);

	const handleSwitchChange = () => {
		setSwitchState((prev) => !prev);
	};

	const handleClose = () => {
		onCloseCustomerForm();
	};

	useEffect(() => {
	}, [switchState]);

	return (
		<div>
			<Dialog
				open={newCustomerFormVisible}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				maxWidth="md"
			>
				<div className={classes.dialog__container}>
					<DialogTitle id="form-dialog-title">
						{isEditing ? "Edit customer" : "Create New Customer"}
					</DialogTitle>
					{/* HEADER IMAGE */}
					<div>
						{switchState ? (
							<img
								className={classes.header__image}
								src="https://miro.medium.com/max/3840/1*5g_9kcXVF05A2P6Y3AHLnw.jpeg"
								alt=""
							/>
						) : (
							<img
								className={classes.header__image}
								src="https://www.ciphr.com/wp-content/uploads/2019/08/Asian-man-and-woman-interviewing-a-candidate.jpg"
								alt=""
							/>
						)}
					</div>
					<DialogContent>
						{/* SWITCH - only visible on customer creation*/}
						{!isEditing && (
							<FormGroup className={classes.switch}>
								<FormControlLabel
									control={
										<Switch
											checked={switchState}
											onChange={handleSwitchChange}
											name="switchState"
											label="Legal Entity"
										/>
									}
									label={switchState ? "Legal Entity" : "Person"}
								/>
							</FormGroup>
						)}

						{/* switcher */}
						{switchState ? <FormInputLegal /> : <FormInputNatural />}
					</DialogContent>
				</div>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	newCustomerFormVisible: state.newcustomerform.isVisible,
	isEditing: state.newcustomerform.isEditing,
	selectedCustomerInfo: state.customers.customerInfo,
});

const mapDispatchToProps = (dispatch) => ({
	onCloseCustomerForm: () =>
		dispatch(actionCreators.setNewCustomerFormHidden()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerForm);

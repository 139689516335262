import React, { useEffect, useState } from "react";
// import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Box, IconButton, InputAdornment, LinearProgress } from "@material-ui/core";
import { Button, makeStyles } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import { cloneDeep } from "lodash";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
const uniqid = require("uniqid");

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
	},
	dialog__container: {},
	textField: {
		width: "100%",
	},
	dialog__variableNotes: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
      alignItems: "flex-start",
      width:"100%"
   },
   actions:{
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      marginTop:20,
   }
}));

const NewCustomerForm = (props) => {
	const classes = useStyles();
	const {
		isEditing,
		onNewNoteFormIsVsible,
		newNoteFormIsVisible,
		noteInputValues,
      onSaveNewNote,
      onUpdateNote,
	} = props;

	const handleClose = () => {
		onNewNoteFormIsVsible(false);
	};

	const [initValues, setInitValues] = useState({
		
	});

	useEffect(() => {
		if (!isEditing) {
			setInitValues({
				name: "",
				description: "",
				data: {},
			});
		} else {
			const newObj = {};
			noteInputValues.data.forEach((item) => {
				const newId = uniqid();
				newObj[newId] = {
					title: item.title,
					text: item.text,
				};
			});
			setInitValues({
				name: noteInputValues.name,
				description: noteInputValues.description,
				data: newObj,
         });
		}
	}, []);
	//adds dynamic note line (title, text)
	const addNewLine = () => {
		const newData = cloneDeep(initValues);
		const newId = uniqid();
		newData.data[newId] = {
			title: "",
			text: "",
		};
		setInitValues(newData);
	};

	const removeNoteLine = (id) => {
		const newData = cloneDeep(initValues);
		delete newData.data[id];
		setInitValues(newData);
	};

	const test = () => {
		console.log(initValues);
	};

	return (
		<div className={classes.root}>
			<Dialog
				fullScreen={true}
				open={newNoteFormIsVisible}
				onClose={handleClose}
				aria-labelledby="form-dialog-title">
				<Button onClick={test}>test</Button>
				<div className={classes.dialog__container}>
					<DialogTitle id="form-dialog-title">
						{isEditing ? "Edit Note" : "Create New Note"}
					</DialogTitle>
					<DialogContent>
						{/* formik */}
						<Formik
							//initial values depending if we are in EDIT mode or not
							initialValues={initValues}
							validate={(values) => {
								const errors = {};
								if (!values.name) {
									errors.name = "Note name required";
								}

								if (!values.description) {
									errors.description = "Note description required";
                        }

								return errors;
							}}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(false);
								// alert(JSON.stringify(values, null, 2));
                        
								if (isEditing) {
                           const payload = {
                              id:noteInputValues._id,
                              name:values.name,
                              description:values.description,
                              data:values.data,
                           }
                           onUpdateNote(payload);
								}else {
                           onSaveNewNote(values);
								}
							}}>
							{({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
								<Form>
									{/* NAME */}
									<Box margin={2}>
										<Field
											className={classes.textField}
											component={TextField}
											name="name"
											label="Name:"
											type="text"
											variant="outlined"
											size="small"
											helperText="e.g. Stnd delivery ENGL"
											autoFocus
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<BusinessIcon style={{ fill: "lightgrey" }} />
													</InputAdornment>
												),
											}}
										/>
									</Box>

									{/* DESCRIPTION */}
									<Box margin={2}>
										<Field
											className={classes.textField}
											component={TextField}
											name="description"
											label="Description"
											type="text"
											variant="outlined"
											size="small"
											helperText="e.g. Standard delivery terms in ENGL"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<HomeWorkIcon style={{ fill: "lightgrey" }} />
													</InputAdornment>
												),
											}}
										/>
									</Box>
									{/* add more note lines button */}
									<Box margin={2}>
										<Button
											startIcon={<AddBoxIcon color="secondary" fontSize="small" />}
											onClick={addNewLine}>
											Add note line
										</Button>
									</Box>

									{/* variable notes title - text field */}
									{/* variable notes title - text field */}
									{/* variable notes title - text field */}
									{/* variable notes title - text field */}
									{initValues.data &&
										Object.keys(initValues.data).map((key, index) => (
											//1
											<div key={key} className={classes.dialog__variableNotes}>
												<Box margin={2}>
													<Field
														className={classes.textField}
														component={TextField}
														name={`data.${key}.title`}
														label="Note title"
														type="text"
														variant="outlined"
														size="small"
														helperText="e.g. Delivery "
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<HomeWorkIcon style={{ fill: "lightgrey" }} />
																</InputAdornment>
															),
														}}
													/>
												</Box>

												<Box margin={2}>
													<Field
														// className={classes.textField}
														component={TextField}
														name={`data.${key}.text`}
														label="Note text"
														multiline
														rows={2}
                                          rowsMax={5}
                                          style={{width:"70vw", minWidth:200}}
														type="text"
														variant="outlined"
														size="small"
														helperText="e.g. Document will be delivered within 10 working days."
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<HomeWorkIcon style={{ fill: "lightgrey" }} />
																</InputAdornment>
															),
														}}
													/>
												</Box>
												<Box style={{ marginTop: 23 }}>
													<IconButton size="small" onClick={() => removeNoteLine(key)}>
														<HighlightOffIcon fontSize="small" />
													</IconButton>
												</Box>
											</div>
										))}

									{/* spinner */}
									{isSubmitting && <LinearProgress />}

									{/* SUBMIT */}
									<Box margin={1} className={classes.actions}>
										{/* submit form */}
										<Button
											variant="contained"
											color="secondary"
											disabled={isSubmitting}
											onClick={submitForm}>
											Submit
										</Button>
										{/* close form */}
                              {/* TODO - close form by resetting state */}
										<Button onClick={() => onNewNoteFormIsVsible(false)} color="primary">
											Cancel
										</Button>
									</Box>
								</Form>
							)}
						</Formik>
					</DialogContent>
				</div>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isEditing: state.notes.isEditing,
	newNoteFormIsVisible: state.notes.newNoteFormIsVisible,
	noteInputValues: state.notes.noteInputValues,
});

const mapDispatchToProps = (dispatch) => ({
	onNewNoteFormIsVsible: (isVisible) => dispatch(actionCreators.setNewNoteFormIsVisible(isVisible)),
   onSaveNewNote: (values) => dispatch(actionCreators.saveNewNote(values)),
   onUpdateNote:(payload) => dispatch(actionCreators.updateNote(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerForm);

import React, { useEffect } from "react";
import PageTitle from "../../shared/components/UI/PageTitle";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import QuotesOptionsDropdown from "../../sales/components/quotes/QuotesOptionsDropdown";
import SearchBar from "../../shared/components/UI/SearchBar";
import NewQuoteForm from "../components/quotes/NewQuoteForm.jsx";
import QuoteDetailCard from "../components/quotes/QuoteCard-dialog";
import {
	CircularProgress,
	Container,
	Grid,
	makeStyles,
	IconButton,
	Tooltip
} from "@material-ui/core";
import QuotesTable from "../components/quotes/QuotesTable";
import YearlyOverviewStatistics from "../../sales/components/quotes/statistics/YearlyOverview"
import NewOrderForm from "../components/orders/NewOrderForm";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const useStyles = makeStyles((theme) => ({
	header: {},
}));

const SalesQuotes = (props) => {
	const classes = useStyles();

	const {
		isLoading,
		quoteArray,
		onGetAllQuotes,
		quoteCardVisible,
		onSetQuotesScreenIsLoading,
		onGetCustomers,
		newQuoteFormIsVisible,
		newOrderFormIsVisible,
		onGetAllOptions,
		onGetQuotesWithLimit
	} = props;

	useEffect(() => {
		onGetQuotesWithLimit(); //need for quotes table
		onGetCustomers(); //need for loading pdf (getting cust details from id)
		onGetAllOptions(); //needed for options (newOrder creation due date)

	}, [onGetQuotesWithLimit, onSetQuotesScreenIsLoading, onGetCustomers,onGetAllOptions]);

	return (
		<Container maxWidth="xl">
			<Grid container justify="space-between" alignItems="center">
				<Grid container alignItems="center" spacing={3} style={{ width: 250 }}>
					{/* Page title */}
					<Grid item>
						<PageTitle tooltip="Click to refresh" onClick={onGetAllQuotes} title="Ponude" />
					</Grid>
					<Grid item>
						{/* Spinner */}
						{isLoading && <CircularProgress color="secondary" />}
					</Grid>
				</Grid>
				<Grid item>
					{/* options menu */}
					<QuotesOptionsDropdown />
				</Grid>
			</Grid>

			{/* SEARCH BAR */}
			<Grid container justify="center" alignItems="center">

				<Grid item>
					<SearchBar
					showDropdown
					filters={{ customerName: "customer", quoteNumber: "quote number",  projectInfo: "project info" }}
					searchableArray={quoteArray}
					/>
				</Grid>
				<Grid item>
					<Tooltip title="Fetch all">
						<IconButton color="primary" aria-label="Fetch all quotes" size="medium" style={{marginLeft: 5}} onClick={onGetAllQuotes}>
							<RotateLeftIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				</Grid>

			</Grid>
			
			{/* QUOTES TABLE */}
			<QuotesTable />
			{/* Statistics */}
			<YearlyOverviewStatistics />
			{/* HIDDEN */}
			{newQuoteFormIsVisible && <NewQuoteForm />}
			{quoteCardVisible && <QuoteDetailCard />}
			{/* new order dialog */}
			{newOrderFormIsVisible && <NewOrderForm />}
		</Container>
	);
};

const mapStateToProps = (state) => ({
	isLoading: state.salesquotes.isLoading,
	quoteArray: state.salesquotes.quotes,
	quoteCardVisible: state.salesquotes.quoteCardVisible,
	newQuoteFormIsVisible: state.salesquotes.newQuoteFormIsVisible,
	newOrderFormIsVisible: state.salesorders.newOrderFormIsVisible,

});

const mapDispatchToProps = (dispatch) => ({
	onSetQuotesScreenIsLoading: (isLoading) =>
		dispatch(actionCreators.setIsLoadingQuotesScreen(isLoading)),
	onGetAllQuotes: () => dispatch(actionCreators.getQuotes()),
	onGetCustomers: () => {
		dispatch(actionCreators.getCustomers());
	},
	onGetAllOptions:() => dispatch(actionCreators.getAllOptions()),
	onGetQuotesWithLimit:() => dispatch(actionCreators.getQuotesWithLimit()),


});

export default connect(mapStateToProps, mapDispatchToProps)(SalesQuotes);

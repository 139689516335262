import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
	AppBar,
	Box,
	Button,
	Container,
	Dialog,
	IconButton,
	Slide,
	Toolbar,
	Tooltip,
} from "@material-ui/core";
import * as actionCreators from "../../store/actions/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import CustomerOverviewGraph from "../components/statistics/CustomerOverview";
import { useHistory } from "react-router-dom";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 500,
	},
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	header__image: {
		height: 160,
		width: "100%",
		objectFit: "cover",
	},
	header__actions: {
		display: "flex",
		direction: "row",
	},
	customerData__container: {
		paddingTop: 10,
		paddingLeft: 50,
	},
	customerData__box: {
		marginTop: 10,
	},
	customerData__textValue: {
		fontWeight: 500,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerCard = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const {
		customerDetailsCardVisible,
		onCloseCustomerCard,
		loadedCustomerInfo,
		onOpenEditCustomerDialog,
		onShowAlertModal,
		onCreateQuoteFromCustomerDetails,
	} = props;
	const handleClose = () => {
		onCloseCustomerCard();
	};

	//menu - not used atm
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	//quote
	const createQuote = () => {
		//totate to salesQuotes (newQuoteForm lives there)
		history.push("/salesQuotes");
		//lauch action -> sets customer for newQuoteForm
		onCreateQuoteFromCustomerDetails();
	};

	return (
		<div className={classes.root}>
			<Dialog
				fullScreen
				open={customerDetailsCardVisible}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				maxWidth="md"
				TransitionComponent={Transition}>
				{/* APPBAR */}
				<AppBar className={classes.appBar} color="secondary" style={{}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<ArrowBackIcon fontSize="large" />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							Customer Details
						</Typography>
						{/* ACTION BUTTONS */}
						<div>
							<Tooltip title="Create new quote for customer">
								<Button color="primary" onClick={createQuote} startIcon={<AddBoxIcon />}>
									New Quote
								</Button>
							</Tooltip>
							<Tooltip title="Edit customer">
								<Button color="primary" onClick={onOpenEditCustomerDialog} startIcon={<EditIcon />}>
									Edit
								</Button>
							</Tooltip>
							<Tooltip title="Delete customer">
								<Button
									color="primary"
									onClick={() => {
										onShowAlertModal("Delete", "Are you sure you want to delete the customer?", {
											method: "deleteCustomer",
										});
									}}
									startIcon={<DeleteOutlineIcon />}>
									Delete
								</Button>
							</Tooltip>
						</div>
					</Toolbar>
				</AppBar>
				{/* HEADER IMG */}
				<div>
					{loadedCustomerInfo.legalEntity ? (
						<img
							className={classes.header__image}
							src="https://miro.medium.com/max/3840/1*5g_9kcXVF05A2P6Y3AHLnw.jpeg"
							alt=""
						/>
					) : (
						<img
							className={classes.header__image}
							src="https://www.ciphr.com/wp-content/uploads/2019/08/Asian-man-and-woman-interviewing-a-candidate.jpg"
							alt=""
						/>
					)}
				</div>
				<Container maxWidth="xl" className={classes.customerData__container}>
					{/* name */}
					<Box className={classes.customerData__box}>
						<Typography className={classes.customerData__textValue} variant="h4" component="p">
							{loadedCustomerInfo.name}
						</Typography>
					</Box>
					{/* e-mail */}
					<Box className={classes.customerData__box}>
						<Typography variant="body2" color="textSecondary" component="p">
							E-mail
						</Typography>
						<Typography
							className={classes.customerData__textValue}
							variant="subtitle1"
							component="p">
							{loadedCustomerInfo.email}
						</Typography>
					</Box>
					{/* oib */}
					<Box className={classes.customerData__box}>
						<Typography variant="body2" color="textSecondary" component="p">
							OIB
						</Typography>
						<Typography
							className={classes.customerData__textValue}
							variant="subtitle1"
							component="p">
							{loadedCustomerInfo.oib}
						</Typography>
					</Box>
					{/* address */}
					<Box className={classes.customerData__box}>
						<Typography variant="body2" color="textSecondary" component="p">
							Address
						</Typography>
						<Typography className={classes.customerData__textValue} variant="body1" component="p">
							{loadedCustomerInfo.address}
						</Typography>
					</Box>
					{/* city */}
					<Box className={classes.customerData__box}>
						<Typography variant="body2" color="textSecondary" component="p">
							City
						</Typography>
						<Typography className={classes.customerData__textValue} variant="body1" component="p">
							{loadedCustomerInfo.city}
						</Typography>
					</Box>
					{/* tel */}
					<Box className={classes.customerData__box}>
						<Typography variant="body2" color="textSecondary" component="p">
							Tel
						</Typography>
						<Typography className={classes.customerData__textValue} variant="body1" component="p">
							{loadedCustomerInfo.tel}
						</Typography>
					</Box>
					{/* vat id */}
					{loadedCustomerInfo.legalEntity && (
						<Box className={classes.customerData__box}>
							<Typography variant="body2" color="textSecondary" component="p">
								Vat ID
							</Typography>
							<Typography className={classes.customerData__textValue} variant="body1" component="p">
								{loadedCustomerInfo.vatId}
							</Typography>
						</Box>
					)}

					{/* comments */}
					<Box className={classes.customerData__box}>
						<Typography variant="body2" color="textSecondary" component="p">
							Comments
						</Typography>
						<Typography className={classes.customerData__textValue} variant="body1" component="p">
							{loadedCustomerInfo.comments}
						</Typography>
					</Box>
					{/* DATA */}
					{/* statistics grapg */}
					<div>
						<CustomerOverviewGraph />
					</div>
				</Container>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	customerDetailsCardVisible: state.customers.customerInfoVisible,
	loadedCustomerInfo: state.customers.customerInfo, //currently selected customer
});

const mapDispatchToProps = (dispatch) => ({
	onCloseCustomerCard: () => dispatch(actionCreators.setCustomerCardHidden()),
	onOpenEditCustomerDialog: () => dispatch(actionCreators.openEditCustomerDialog()),
	onDeleteCustomer: () => dispatch(actionCreators.deleteCustomer()),
	onShowAlertModal: (title, message, modalProps) =>
		dispatch(actionCreators.showAlertModal(title, message, modalProps)),
	onCreateQuoteFromCustomerDetails: () => dispatch(actionCreators.createQuoteFromCustomer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCard);

export const terminologija = {
	quote: { en: "Quote", hrv: "Ponuda" },
	invoice: { en: "Invoice", hrv: "Račun" },
	translationAgency: { en: "Translation Agency", hrv: "Prevoditeljska Agencija" },
	item: { en: "Item Description", hrv: "Naziv" },
	// unit:{en:"Unit", hrv:"Mjera"},
	unitPrice: { en: "Unit price", hrv: "J. cijena" },
	quantity: { en: "Quantity", hrv: "Količina" },
	unit: { en: "Unit", hrv: "J. mjera" },
	total: { en: "Total", hrv: "Ukupno" },
	subtotal: { en: "Subtotal", hrv: "Sveukupno bez PDV-a" },
	discount: { en: "Discount", hrv: "Popust" },
	vat: { en: "Vat & Tax", hrv: "PDV" },
	grandTotal: { en: "Grand Total", hrv: "Sveukupno" },
	currency: { en: "€", hrv: "€" },
	notes: { en: "Notes", hrv: "Napomene" },
	payment: { en: "Payment Method", hrv: "Način Plaćanja" },
	paymentDue: { en: "Payment due", hrv: "Dospijeće" },

};
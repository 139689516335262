import React, { useState, useEffect } from "react";
// import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import {
	AppBar,
	Button,
	CircularProgress,
	IconButton,
	makeStyles,
	Slide,
	Toolbar,
	Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NotesTable from "./NotesTable";
import AddBoxIcon from "@material-ui/icons/AddBox";
import NewNoteForm from "./NewNoteForm";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	appBar__title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	dialog__container: {},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const NotesDialog = (props) => {
	const classes = useStyles();
	const {
		dialogIsVisibility,
		isLoading,
		onSetNotesDialogVisibility,
		onGetNotes,
		onSetNewNoteFormVisibility,
		newNoteFormIsVisible,
	} = props;

	const handleClose = () => {
		onSetNotesDialogVisibility(false);
	};

	useEffect(() => {
		onGetNotes();
	}, [onGetNotes]);

	return (
		<div>
			<Dialog
				fullScreen
				TransitionComponent={Transition}
				open={dialogIsVisibility}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				maxWidth="md">
				<AppBar className={classes.appBar} color="secondary" style={{}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<ArrowBackIcon fontSize="large" />
						</IconButton>
						<Typography className={classes.appBar__title} variant="h6">
							Notes
						</Typography>

						{isLoading && <CircularProgress style={{ marginRight: 30 }} color="primary" />}
						<Button
							style={{ float: "right" }}
							onClick={() => {
								onSetNewNoteFormVisibility(true, false);
							}}
							startIcon={<AddBoxIcon color="primary" fontSize="large" />}>
							Add new
						</Button>
					</Toolbar>
				</AppBar>
				<div className={classes.dialog__container}>
					<DialogContent>
						<NotesTable />
					</DialogContent>
					{newNoteFormIsVisible && <NewNoteForm />}
				</div>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoading: state.notes.isLoading,
	dialogIsVisibility: state.notes.noteDialogIsVisible,
	standardNotes: state.neworderform.standardNotes, //neworder and new quote pull from the same API
	newNoteFormIsVisible: state.notes.newNoteFormIsVisible,
});

const mapDispatchToProps = (dispatch) => ({
	onSetNotesDialogVisibility: (isVisible) =>
		dispatch(actionCreators.setNoteDialogIsVisible(isVisible)),
	onGetNotes: () => dispatch(actionCreators.getStandardOrderNotes()),
	onSetNewNoteFormVisibility: (isVisible, isEditing) =>
		dispatch(actionCreators.setNewNoteFormIsVisible(isVisible,  isEditing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesDialog);

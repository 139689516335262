import * as actionCreators from "./index";
import axios from "axios";

export const setNotesIsLoading = (isLoading) => ({
	type: "[NOTES] IS_LOADING",
	isLoading,
});
export const setNotesFormIsEditing = (isEditing) => ({
	type: "[NOTES] IS_EDITING",
	isEditing,
});
export const setNoteDialogIsVisible = (isVisible) => ({
	type: "[NOTES] DIALOG_IS_VISIBLE",
	isVisible,
});

export const setNewNoteFormIsVisible = (isVisible, isEditing) => ({
	type: "[NOTES] NEW_NOTE_FORM_IS_VISIBLE",
	isVisible,
	isEditing,
});
export const resetNotesState = () => ({
	type: "[NOTES] RESET_STATE",
});

//adds new title+description line to new note form
//one note name (available at dropdown) can hold multiple titles+texts at once
export const addNewNoteTitleTextLine = (noteId) => ({
	type: "[NOTES] ADD_NEW_NOTE_LINE",
	noteId,
});
//sets values for note line with this id
export const setValuesNewNoteTitleTextLine = (payload) => ({
	type: "[NOTES] SET_NOTE_LINE_VALUES",
	payload,
});
export const setNoteInputValues = (payload) => ({
	type: "[NOTES] SET_NOTE_INPUT_VALUES",
	payload,
});

export const saveNewNote = (values) => {
	return async (dispatch, getState) => {
		try {
			dispatch(setNotesIsLoading(true));
			const data = values;
			const result = await axios.post(
				process.env.REACT_APP_NODE_URL + "/api/notes/createNote",
				data,
				{
					headers: { Authorization: "Bearer " + getState().auth.token },
				},
			);
			dispatch(setNewNoteFormIsVisible(false));
			dispatch(
				actionCreators.showSnackbar(`Note "${result.data.createdNote.name}"  created successfully`),
			);
			dispatch(actionCreators.getStandardOrderNotes());
		} catch (error) {
		} finally {
			dispatch(setNotesIsLoading(false));
		}
	};
};
//save edited note to db
export const updateNote = (payload) => {
	//payload is combined with some data from formik, some from initState (!stupid solution!)
	return async (dispatch, getState) => {
		try {
			dispatch(setNotesIsLoading(true));
			const data = payload;
			const result = await axios.post(
				process.env.REACT_APP_NODE_URL + "/api/notes/updateNote",
				data,
				{
					headers: { Authorization: "Bearer " + getState().auth.token },
				},
			);
			dispatch(setNewNoteFormIsVisible(false));
			dispatch(actionCreators.showSnackbar(`Note "${result.data.note.name}" updated successfully`));
			dispatch(actionCreators.getStandardOrderNotes());
		} catch (error) {
		} finally {
			dispatch(setNotesIsLoading(false));
		}
	};
};

//delete note in db
export const deleteNote = (id) => {
	return async (dispatch, getState) => {
		try {
			dispatch(setNotesIsLoading(true));
			const data = {
				id,
			};
			const result = await axios.put(
				process.env.REACT_APP_NODE_URL + "/api/notes/deleteNote",
				data,
				{
					headers: { Authorization: "Bearer " + getState().auth.token },
				},
         );
         dispatch(actionCreators.showSnackbar(`Note "${result.data.note.name}" deleted successfully`));
			dispatch(actionCreators.getStandardOrderNotes());
		} catch (error) {
		} finally {
			dispatch(setNotesIsLoading(false));
		}
	};
};

//edit note locally - opens newNoteForm with pre-populated data
export const editNote = (row) => {
	return async (dispatch, getState) => {
		try {
			const selectedNoteData = row;
			dispatch(setNotesIsLoading(true));
			dispatch(setNewNoteFormIsVisible(true));
			dispatch(setNoteInputValues(selectedNoteData));
			setNewNoteFormIsVisible(true, true);
			dispatch(setNotesFormIsEditing(true));
		} catch (error) {
		} finally {
			dispatch(setNotesIsLoading(false));
		}
	};
};

import * as actionCreators from "./index";

export const showAlertModal = (title, message, modalProps) => ({
	type: "[ALERTMODAL] SHOW",
	title,
	message,
	modalProps,
});
export const hideAlertModal = () => ({
	type: "[ALERTMODAL] HIDE",
});
export const setAlertModalIsLoading = (isLoading) => ({
	type: "[ALERTMODAL] IS_LOADING",
	isLoading,
});
export const resetAlertModal = () => ({
	type: "[ALERTMODAL] RESET",
});
export const setActionInMotion = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setAlertModalIsLoading(true));
			if (getState().alertmodal.modalProps.method === "deleteCustomer") {
				await dispatch(actionCreators.deleteCustomer());
				//resets
				dispatch(hideAlertModal());
				dispatch(resetAlertModal());
				return;
			}
			if (getState().alertmodal.modalProps.method === "deleteQuote") {
				await dispatch(actionCreators.deleteQuote());
				//resets
				dispatch(hideAlertModal());
				dispatch(resetAlertModal());
				return;
			}
			if (getState().alertmodal.modalProps.method === "deleteOrder") {
				await dispatch(actionCreators.deleteOrder());
				//resets
				dispatch(hideAlertModal());
				dispatch(resetAlertModal());
				return;
			}
			if (getState().alertmodal.modalProps.method === "stornoOrder") {
				await dispatch(actionCreators.stornoOrder());
				//resets
				dispatch(hideAlertModal());
				dispatch(resetAlertModal());
				return;
			}
			if (getState().alertmodal.modalProps.method === "deleteNote") {
				await dispatch(actionCreators.deleteNote(getState().alertmodal.modalProps.id));
				//resets
				dispatch(hideAlertModal());
				dispatch(resetAlertModal());
				return;
			}
			//dialog to when unchecking cheched/finished order 
			if (getState().alertmodal.modalProps.method === "unfinishOrder") {
				await dispatch(
					actionCreators.completeOrder(
						getState().alertmodal.modalProps.orderId,
						getState().alertmodal.modalProps.isCompleted,
					),
				);
				//resets
				dispatch(hideAlertModal());
				dispatch(resetAlertModal());
				return;
			}
		} catch (error) {
		} finally {
			dispatch(setAlertModalIsLoading(false));
		}
	};
};

import React from "react";
import "./Welcome.css";

const Welcome = () => {
	return (
		<div className="background">
			<div className="title__container">
				<div className="title">INTEGRA</div>
				<div className="subtitle">- TRANSLATION AGENCY -</div>
			</div>
		</div>
	);
};

export default Welcome;

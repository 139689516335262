import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import parseISO from 'date-fns/parseISO'
import addYears from 'date-fns/addYears'
import getYear from 'date-fns/getYear'
import {
   LineChart, Line,
   AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const useStyles = makeStyles((theme) => ({
   graph__container: {
      marginLeft: "5%",
      marginBottom:20
   },
   graph: {
      width: "90%"
   }
}));
const CustomerOverview = (props) => {
   const classes = useStyles();
   const {
      quoteArray,
      selectedCustomer
   } = props;

   const dataCount = [
      { name: getYear(addYears(new Date(), -4)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -4))).length, orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -4)) && quote.orderNumber.length > 0).length },
      { name: getYear(addYears(new Date(), -3)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -3))).length, orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -3)) && quote.orderNumber.length > 0).length },
      { name: getYear(addYears(new Date(), -2)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -2))).length, orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -2)) && quote.orderNumber.length > 0).length },
      { name: getYear(addYears(new Date(), -1)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -1))).length, orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -1)) && quote.orderNumber.length > 0).length },
      { name: getYear(addYears(new Date(), 0)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), 0))).length, orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), 0)) && quote.orderNumber.length > 0).length },
   ]

   const dataValues = [
      { name: getYear(addYears(new Date(), -4)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -4))).map(item => item.totalValue).reduce((a, b) => a + b, 0), orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -4)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a, b) => a + b, 0) },
      { name: getYear(addYears(new Date(), -3)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -3))).map(item => item.totalValue).reduce((a, b) => a + b, 0), orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -3)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a, b) => a + b, 0) },
      { name: getYear(addYears(new Date(), -2)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -2))).map(item => item.totalValue).reduce((a, b) => a + b, 0), orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -2)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a, b) => a + b, 0) },
      { name: getYear(addYears(new Date(), -1)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -1))).map(item => item.totalValue).reduce((a, b) => a + b, 0), orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), -1)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a, b) => a + b, 0) },
      { name: getYear(addYears(new Date(), 0)), quotes: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), 0))).map(item => item.totalValue).reduce((a, b) => a + b, 0), orders: [...quoteArray].filter(quote => quote.customerName === selectedCustomer.name && getYear(parseISO(quote.createdDate)) === getYear(addYears(new Date(), 0)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a, b) => a + b, 0) },
   ]
   return (
      <div className={classes.graph__container}>
         <div className={classes.graph}>
            <ResponsiveContainer width="100%" height={250}>
               <LineChart data={dataCount}
                  margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={{ fill: '#9e9e9e' }} dataKey="name" />
                  <YAxis tick={{ fill: '#9e9e9e' }} />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Line name="Quoted for customer" type='step' dataKey='quotes' stackId="1" stroke='#82ca9d' fill='#82ca9d' strokeWidth={2} strokeDasharray="5 5" />
                  <Line name="Ordered by customer" type='step' dataKey='orders' stackId="1" stroke='#ffc658' fill='#ffc658' strokeWidth={2} />
               </LineChart>
            </ResponsiveContainer>
         </div>
         <div className={classes.graph}>
            <ResponsiveContainer width="100%" height={250}>
               <AreaChart data={dataValues}
                  margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={{ fill: '#9e9e9e' }} dataKey="name" />
                  <YAxis tick={{ fill: '#9e9e9e' }} />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Area name="Quote Value €" type='monotone' dataKey='quotes' stackId="1" stroke='#8884d8' fill='#8884d8' />
                  <Area name="Orders Value €" type='monotone' dataKey='orders' stackId="1" stroke='#ffc658' fill='#ffc658' />
               </AreaChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};
const mapStateToProps = (state) => ({
   quoteArray: state.salesquotes.quotes,
   selectedCustomer: state.customers.customerInfo
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerOverview);

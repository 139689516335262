import React, { useState} from 'react'
import { Grid, makeStyles, TextField, IconButton, Tooltip } from '@material-ui/core';
import { connect } from "react-redux";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as actionCreators from "../../../store/actions/index"
import "./DynammicQuoteLine.css"

const useStyles = makeStyles((theme) => ({
   root: {
      width: "100%",
      marginBottom: 20
   },
   grid__main: {
   },
   inputField: {
   },
}));

const DynammicNoteLine = (props) => {
   const classes = useStyles();
   const {
      lineId,
      onNoteValuesChange,
      storeValues,
      onRemoveNoteLine

   } = props;

   const [inputValues, setInputValues] = useState({
      id: lineId,
      noteTitle: storeValues[lineId].noteTitle,
      noteText: storeValues[lineId].noteText,
   })

   const onInputChange = (fieldName, value) => {
      let newInputValues = { ...inputValues };
      newInputValues[fieldName] = value
      setInputValues(newInputValues)
      onNoteValuesChange(lineId, newInputValues)
   }

   return (
      <div className={classes.root}>
         <Grid className={classes.grid__main} container direction="row" alignItems="flex-start" spacing={1}>
            <Grid item >
               <Tooltip title="Remove note">
                  <IconButton size="small" onClick={() => onRemoveNoteLine(lineId)}>
                     <HighlightOffIcon />
                  </IconButton>
               </Tooltip>
            </Grid>
            {/* ITEM NAME */}
            <Grid item className="textField__multiline">
               <TextField
                  autoFocus
                  multiline
                  defaultValue={storeValues[lineId].noteTitle}
                  size="small"
                  type="text"
                  label="Title"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     }
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("noteTitle", e.target.value)
                  }
               />
            </Grid>
            {/* DESCRIPTION */}
            <Grid item className="textField__multiline" style={{ flex: 1 }}>
               <TextField
                  multiline
                  style={{ width: "100%" }}
                  defaultValue={storeValues[lineId].noteText}
                  size="small"
                  type="text"
                  label="Text"
                  variant="outlined"
                  inputProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  InputLabelProps={{
                     style: {
                        fontSize: 14,
                     },
                  }}
                  onChange={
                     (e) => onInputChange("noteText", e.target.value)
                  }
               />
            </Grid>
         </Grid>
      </div>
   )
}

const mapStateToProps = (state) => ({
   storeValues: state.newquoteform.noteInputValues
});

const mapDispatchToProps = (dispatch) => ({
   onNoteValuesChange: (lineId, newValue) => dispatch(actionCreators.setNewNoteValue(lineId, newValue)),
   onRemoveNoteLine: (lineId) => dispatch(actionCreators.removeNoteLine(lineId))

});

export default connect(mapStateToProps, mapDispatchToProps)(DynammicNoteLine);
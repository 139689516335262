const initialState = {
	isLoading: false,
	newOrderFormIsVisible: false,
	orderCardIsVisible: false,
	orders: [],
	orderCardInfo: {},
	pdfItemsArray: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "[SALES_ORDERS] IS_LOADING":
			return {
				...state,
				isLoading: action.isLoading,
			};
		case "[SALES_ORDERS] SET_NEW_ORDER_FORM_IS_VISIBLE":
			return {
				...state,
				newOrderFormIsVisible: action.isVisible,
			};
		case "[SALES_ORDERS] SET_ORDER_CARD_IS_VISIBLE":
			return {
				...state,
				orderCardIsVisible: action.orderCardIsVisible,
			};
		case "[SALES_ORDERS] SET_ORDERS":
			return {
				...state,
				orders: action.orders,
			};
		case "[SALES_ORDERS] SET_ORDER_CARD_INFO":
			return {
				...state,
				orderCardInfo: action.orderCardInfo,
			};
		case "[SALES_ORDERS] SET_ORDER_CARD_BARCODE":
			const newInfo = { ...state.orderCardInfo };
			newInfo["barcode"] = action.barCode;
			return {
				...state,
				orderCardInfo: newInfo,
			};

		case "[SALES_ORDERS] SET_PDF_ITEMS_TO_PRINT":
			let newPdfArr = [...state.pdfItemsArray];
			if (state.pdfItemsArray.filter((e) => e._id === action.ordersTableRow._id).length > 0) {
				//remove from array if item(row) is already in
				newPdfArr = newPdfArr.filter((e) => e._id !== action.ordersTableRow._id);
			} else {
				//add that item(row)
				newPdfArr.push(action.ordersTableRow);
			}
			return {
				...state,
				pdfItemsArray: newPdfArr,
			};
		case "[SALES_ORDERS] SET_STATE_FIELD":
			const newState = { ...state };
			newState[action.field] = action.value;
			return newState;

		case "[SALES_ORDERS] RESET_STATE":
			return initialState;

		default:
			return state;
	}
};

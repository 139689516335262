import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

const CustomersOptionsDropdown = (props) => {
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <div>
         <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            Menu
      </Button>
         <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
         >
            <MenuItem onClick={() => {
               props.onOpenTaskForm()
               handleClose();

            }
            }>
               <AddBoxIcon style={{ marginRight: 5 }} color="primary" fontSize="small" />
               Add customer</MenuItem>
            <MenuItem onClick={handleClose}>Statistics</MenuItem>
         </Menu>
      </div>
   );
}

const mapStateToProps = (state) => ({

})



const mapDispatchToProps = (dispatch) => ({
   onOpenTaskForm: () => dispatch(actionCreators.openNewCustomerDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomersOptionsDropdown);


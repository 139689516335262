import * as actionCreators from "./index"
import axios from "axios"


export const setNewCustomerFormVisible = () => ({
  type: "[NEW_CUSTOMER_FORM] SET_VISIBLE",
});

export const setNewCustomerFormHidden = () => ({
  type: "[NEW_CUSTOMER_FORM] SET_HIDDEN",
});

export const setNewCustomerFormIsLoading = (isLoading) => ({
  type: "[NEW_CUSTOMER_FORM] IS_LOADING",
  isLoading
});

export const setNewCustomerFormIsEditing = (isEditing) => ({
  type: "[NEW_CUSTOMER_FORM] IS_EDITING",
  isEditing
});

//saves new customer form to db
export const saveNewCustomerForm = (values) => {
  return async (dispatch, getState) => {
    const data = {
      legalEntity: values.legalEntity,
      name: values.name,
      email: values.email,
      oib: values.oib,
      address: values.address,
      city: values.city,
      tel:values.tel,
      comment: values.comment,
      vatId:values.vatId
    };
    try {
      dispatch(actionCreators.setIsLoadingCustomersScreen(true));
      const result = await axios.post(
        process.env.REACT_APP_NODE_URL + "/api/customers/createCustomer",
        data,
        { headers: { Authorization: "Bearer " + getState().auth.token } }
      )
      // console.log("response [saveNewCustomerForm]: ", result);
      dispatch(actionCreators.getCustomers())
      dispatch(actionCreators.showSnackbar(`Customer ${result.data.createdCustomer.name}  created successfully`));
      dispatch(actionCreators.setNewCustomerFormHidden());

    } catch (error) {
      dispatch(actionCreators.showErrorModal(error.response.data.message || error))
    } finally {
      dispatch(actionCreators.setIsLoadingCustomersScreen(false));
    }
  }
}

export const updateCustomerForm = (values) => {
  return async (dispatch, getState) => {
    const data = {
      customerId: getState().customers.customerInfo._id,
      legalEntity: values.legalEntity,
      name: values.name,
      email: values.email,
      oib: values.oib,
      address: values.address,
      city: values.city,
      tel:values.tel,
      comment: values.comment,
      vatId:values.vatId
    };
    try {
      dispatch(actionCreators.setIsLoadingCustomersScreen(true));
      const result = await axios.patch(
        process.env.REACT_APP_NODE_URL + "/api/customers/updateCustomer",
        data,
        { headers: { Authorization: "Bearer " + getState().auth.token } }
      )
      // console.log("response [updateCustomerForm]: ", result);
      dispatch(actionCreators.showSnackbar(`${result.data.customer.name} updated successfully`));
      dispatch(actionCreators.setNewCustomerFormHidden());
      dispatch(actionCreators.setCustomerCardHidden());
      //clear selected customer in store [customers r] -customerInfo
      dispatch(actionCreators.setCustomerInfo({}));
      //updated customer table
      dispatch(actionCreators.getCustomers());
    } catch (error) {
      // console.log(error)
      dispatch(actionCreators.showErrorModal(error.response.data.message))
    } finally {
      dispatch(actionCreators.setIsLoadingCustomersScreen(false));
    }
  }
}

export const deleteCustomer = () => {
  return async (dispatch, getState) => {
    //customer to delete pulled from store - currentyl opened customer
    const data = {
      customerId: getState().customers.customerInfo._id,
    };
    try {
      dispatch(actionCreators.setIsLoadingCustomersScreen(true));
      const result = await axios.put(
        process.env.REACT_APP_NODE_URL + "/api/customers/deleteCustomer",
        data,
        { headers: { Authorization: "Bearer " + getState().auth.token } }
      )
      
      dispatch(actionCreators.showSnackbar(`${result.data.customer.name} deleted successfully`));
      dispatch(actionCreators.setCustomerCardHidden());
      //clear selected customer in store [customers r] -customerInfo
      dispatch(actionCreators.setCustomerInfo({}));
      //updated customer table
      dispatch(actionCreators.getCustomers());
    } catch (error) {
      // console.log(error)
      dispatch(actionCreators.showErrorModal(error.response.data.message))
    } finally {
      dispatch(actionCreators.setIsLoadingCustomersScreen(false));
    }
  }
}

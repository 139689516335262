//gets all options from db. Can be put in sepreate action

import axios from "axios";
import * as actionCreators from "./index";

//sets options data recevied from db collection "options" - can be moved in separate action
export const setOptions = (options) => ({
	type: "[SETTINGS] SET_OPTIONS",
	options,
});

export const getAllOptions = () => {
	return async (dispatch, getState) => {
		try {
			const response = await axios.get(process.env.REACT_APP_NODE_URL + "/api/options/options", {
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			// console.log("auth controller [getAllOptions] response: ", response.data.options[0].data);
			dispatch(await setOptions(response.data.options[0].data));
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		}
	};
};

//change value
export const ChangeOptionValue = (payload) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actionCreators.setNotesIsLoading(true));
			const data = payload;
			const result = await axios.put(
				process.env.REACT_APP_NODE_URL + "/api/options/options",
				data,
				{
					headers: { Authorization: "Bearer " + getState().auth.token },
				},
			);
			//refresh settings (pull new values from BE)
			dispatch(actionCreators.showSnackbar(`Due date time updated successfully.`));
			dispatch(getAllOptions());

		} catch (error) {
		} finally {
			dispatch(actionCreators.setNotesIsLoading(false));
		}
	};
};

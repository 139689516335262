import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import * as actionCreators from "../../store/actions/index";
import { TableHead, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { CustomerTablePaginationActions } from "./customerTablePaginationActions";

const useStyles2 = makeStyles({
	root: {
		padding: 50,
	},
	table: {
		minWidth: 500,
	},
});

const CustomersTable = (props) => {
	const { customersArray, searchQuery, onOpenCard, filterBy } = props;

	const classes = useStyles2();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const filteredCustomersArray = customersArray.filter((item) => {
		//since filterBy is set globally we have to check if our array has this prop
		//instead of manually setting name we can pass filters array and select 1st value
		if (typeof item[filterBy] === "undefined" || !filterBy) {
			return item["name"].toLowerCase().includes(searchQuery.toLowerCase());
		} else {
			return item[!filterBy ? "name" : filterBy].toLowerCase().includes(searchQuery.toLowerCase());
		}
	});

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, filteredCustomersArray.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	//set page to 0 (first page) after search query is selected/applied
	useEffect(() => {
      if (page > 0) {
         setPage(0)
      }
   }, [searchQuery]);
   
	

	return (
		<div className={classes.root}>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="custom pagination table" size="small">
					<TableHead>
						<TableRow>
							<TableCell>name</TableCell>
							<TableCell>OIB</TableCell>
							<TableCell>Address</TableCell>
							<TableCell>e-mail</TableCell>
							<TableCell>Tel</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredCustomersArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: filteredCustomersArray
						).map((row) => (
							<TableRow key={row._id}>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell align="center">{row.oib}</TableCell>
								<TableCell align="left">{row.address}</TableCell>
								<TableCell align="left">{row.email}</TableCell>
								<TableCell align="left">{row.tel}</TableCell>
								<TableCell align="left">
									<Tooltip title="View details">
										<IconButton onClick={() => onOpenCard(row)} style={{ padding: 0, margin: 0 }}>
											<VisibilityIcon fontSize="small" color={"primary"} />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}

						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
                     <TablePagination
								rowsPerPageOptions={[10, 20, 30]} // , { label: 'All', value: -1 }
								colSpan={3}
								count={filteredCustomersArray.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={CustomerTablePaginationActions}
								style={{overflow:"visible"}}
							/>
							
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	);
};

const mapStateToProps = (state) => ({
	customersArray: state.customers.customers,
	searchQuery: state.searchbar.searchQuery,
	filterBy: state.searchbar.filterBy,
});

const mapDispatchToProps = (dispatch) => ({
	onOpenCard: (info) => dispatch(actionCreators.openCustomerCard(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTable);

const initialState = {
   
   options:{}
}

export default (state = initialState, action) => {
   switch (action.type) {
       case "[SETTINGS] SET_OPTIONS":
           return {
               ...state,
               options: action.options
           }
       default:
           return state
   }
}

import React, { useEffect } from "react";
import PageTitle from "../../shared/components/UI/PageTitle";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import OrdersOptionsDropdown from "../../sales/components/orders/OrdersOptionsDropdown";
import SearchBar from "../../shared/components/UI/SearchBar";
import OrderDetalCard from "../components/orders/OrderCard-dialog";
import { CircularProgress, Container, Grid, makeStyles, IconButton, Tooltip } from "@material-ui/core";
import NewOrderForm from "../components/orders/NewOrderForm";
import OrdersTable from "../components/orders/OrdersTable";
import YearlyOverviewStatistics from "../components/orders/statistics/YearlyOverview";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const useStyles = makeStyles((theme) => ({
	header: {},
}));

const SalesOrders = (props) => {
	const classes = useStyles();

	const {
		onGetAllOrdes,
		isLoading,
		ordersArray,
		onGetCustomers,
		orderCardIsVisible,
		newOrderFormIsVisible,
		onGetOrdersWithLimit
	} = props;

	useEffect(() => {
		onGetOrdersWithLimit();
		onGetCustomers();
	}, [onGetOrdersWithLimit, onGetCustomers]);

	return (
		<Container maxWidth="xl">
			<Grid container justify="space-between" alignItems="center">
				<Grid container alignItems="center" spacing={3} style={{ width: 250 }}>
					{/* Page title */}
					<Grid item>
						<PageTitle tooltip="Click to refresh" onClick={onGetAllOrdes} title="Računi" />
					</Grid>
					<Grid item>
						{/* Spinner */}
						{isLoading && <CircularProgress color="secondary" />}
					</Grid>
				</Grid>
				<Grid item>
					{/* options menu */}
					<OrdersOptionsDropdown />
				</Grid>
			</Grid>

			{/* SEARCH BAR */}
			<Grid container justify="center" alignItems="center">

				<Grid item>
					<SearchBar
					showDropdown
					filters={{
						customerName: "customer",
						orderNumber: "invoice number",
						quoteNumber: "quote number",
						projectInfo: "project info"
					}}
					searchableArray={ordersArray}
					/>
				</Grid>
				<Grid item>
					<Tooltip title="Fetch all">
						<IconButton color="primary" aria-label="Fetch all orders" size="medium" style={{marginLeft: 5}} onClick={onGetAllOrdes}>
							<RotateLeftIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>

			{/* ORDERS TABLE */}
			<OrdersTable />
			{/* STATISTICS */}
			<YearlyOverviewStatistics />
			{/* HIDDEN */}
			{orderCardIsVisible && <OrderDetalCard />}
			{/* newOrderCard for edit */}
			{newOrderFormIsVisible && <NewOrderForm />}
		</Container>
	);
};

const mapStateToProps = (state) => ({
	isLoading: state.salesorders.isLoading,
	ordersArray: state.salesorders.orders,
	orderCardIsVisible: state.salesorders.orderCardIsVisible,
	newOrderFormIsVisible: state.salesorders.newOrderFormIsVisible,
});

const mapDispatchToProps = (dispatch) => ({
	onGetAllOrdes: () => dispatch(actionCreators.getOrders()),
	onGetCustomers: () => {
		dispatch(actionCreators.getCustomers());
	},
	onGetAllQuotes: () => dispatch(actionCreators.getQuotes()),
	onGetOrdersWithLimit: () => dispatch(actionCreators.getOrdersWithLimit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrders);

const initialState = {
   isVisible: false,
   isLoading:false,
   isEditing:false
}

const reducer = (state = initialState, action) => {
   switch (action.type) {

       case "[NEW_CUSTOMER_FORM] SET_VISIBLE": 
           return {
               ...state,
               isVisible: true
           }
       case "[NEW_CUSTOMER_FORM] SET_HIDDEN":
           return {
               ...state,
               isVisible: false
           }
        case "[NEW_CUSTOMER_FORM] IS_LOADING":
            return{
                ...state,
                isLoading:action.isLoading
            }
        case "[NEW_CUSTOMER_FORM] IS_EDITING":
            return{
                ...state,
                isEditing:action.isEditing
            }
       
       default:
           return state 
   }
}


export default reducer;

import React from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
	Box,
	Container,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import IntegraLogo from "../../../assets/images/logo_integra.png";
import NikaSignature from "../../../assets/images/nika_signature.png";
import chevron3 from "../../../assets/images/chevron3.jpg";
import barcode from "../../../assets/images/barcode_example.png";
import clsx from "clsx";
import {terminologija} from "../../../utils/terminologija.js"

/////////////////////////////////////////////////
/////////////////////////////////////////////////
////QUOTE CARD - details normal view
////////////////////////////////////////////////
/////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
	},
	container: {
		width: 1024,
	},
	currency__span: {
		color: "lightgray",
		fontSize: 12,
		marginLeft: 3,
	},
	table: {
		minWidth: 650,
	},
	header: {
		display: "flex",
		justifyContent: "space-around",
		alignItems:"center",
		padding: "10px 0px 30px 0px",
		// backgroundColor: "#f5f5f5"
	},
	header__logo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
	},
	header__logoTitle: {
		fontFamily: '"Montserrat", sans-serif',
	},
	header__quote: {
		display: "flex",
		alignItems: "flex-end",
		flexDirection: "column",
	},
	customer__container: {
		backgroundColor: "#f5f5f5",
		paddingTop: 10,
		paddingBottom: 10,
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		flexDirection: "row",
		borderTopLeftRadius:5,
		borderTopRightRadius:5,
	},
	customer__seller: {},
	customer__buyer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
	},
	projectInfo__container: {
		backgroundColor: "#eeeeee",
		padding: "4px 35px",
		borderBottomLeftRadius:5,
		borderBottomRightRadius:5,
	},

	items__container: {
		padding: 10,
	},
	items__total: {
		width: 400,
		display: "flex",
		flexDirection: "column",
		padding: 0,
	},
	itemsTotal__row: {
		padding: "5px 10px",
		display: "flex",
		justifyContent: "space-between",
	},
	itemsTotal__grandValue: {
		backgroundColor: theme.palette.secondary.main,
		padding: 10,
		boxSizing: "border-box",
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
	},
	notes__row: {
		paddingleft: 10,
		display: "flex",
		justifyContent: "flex-start",
		flexDirection: "row",
		marginBottom: 20,
	},
	notes__name: {
		minWidth: 150,
		paddingRight: 10,
		paddingTop: 5,
		borderRight: "2px solid lightgrey",
		display: "flex",
		justifyContent: "flex-end",
	},
	notes__description: {
		padding: 5,
	},

	payment: {
		marginTop: 50,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		padding: 50,
	},
	payment__header: {
		display: "inline-block",
	},
	payment__headerText: {
		borderBottom: "1px solid lightgrey",
		marginBottom: 5,
	},
	payment__signature: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
	},
	payment__barcode: {
		width: 300,
		marginTop: 10,
	},
}));


const QuoteCard = (props) => {
	const classes = useStyles();
	const { quoteCardInfo, customersArray } = props;
	//customer info
	const customerToDisplay = customersArray.find(
		(customer) => customer._id === quoteCardInfo.customerId,
	);
	let vat = 0;
	//Returns discount for overall quote 
	const getDiscount = () => {
		let amount = 0;
		if (quoteCardInfo.quoteInputValues) {
			Object.keys(quoteCardInfo.quoteInputValues).map(
				(key) =>
					(amount +=
						parseFloat(quoteCardInfo.quoteInputValues[key].price ) *
						quoteCardInfo.quoteInputValues[key].quantity),
			);
			return (amount - Math.abs(quoteCardInfo.totalValue)).toFixed(2);
		}
		return amount;
	};
	//PDV (%)
	const getVat = () => {
		vat = 0;
		let counter = 0;
		if (quoteCardInfo.quoteInputValues) {
			Object.keys(quoteCardInfo.quoteInputValues).map((key) => {
				vat += +quoteCardInfo.quoteInputValues[key].pdv;
				return (counter += 1);
			});
			return (vat / counter).toFixed(2);
		}
		return vat;

	};
	//Retrurn grand total including pdv 
	const getGrandTotal = () => {
		//Extract PDV (from 25% to 1.25)
		const res = "1." + getVat().toString();
		const ans = (quoteCardInfo.totalValue ) * parseFloat(res);
		return ans.toFixed(2);
	};
	
	//returns pdv value (how much € is pdv out of total)
	const getVatValue = () => {
		//grand otal (with pdv) - total (without pdv)
		const vatValue = (getGrandTotal() -  (quoteCardInfo.totalValue)).toFixed(2) 
		return vatValue;
	}


	return (
		<Container className={classes.root} maxWidth="lg">
			<div className={classes.container}>
				{/* HEADER */}
				<div className={classes.header}>
					<div className={classes.header__logo}>
						<div>
							<img style={{ width: 150, paddingTop: 9 }} src={IntegraLogo} alt="Integra logo" />
							<Typography
								className={classes.header__logoTitle}
								color="textSecondary"
								variant="subtitle1">
								{quoteCardInfo.foreignCurrency
									? terminologija.translationAgency.en
									: terminologija.translationAgency.hrv}
							</Typography>
						</div>
					</div>
					<div className={classes.header__quote}>
						<Typography color="primary" variant="h6">
							{quoteCardInfo.foreignCurrency ? terminologija.quote.en : terminologija.quote.hrv}{" "}
							{quoteCardInfo.quoteNumber}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							{format(parseISO(quoteCardInfo.date), "d.M.yyyy. kk:mm")}
						</Typography>
					</div>
				</div>
				{/* CUSTOMER  */}
				<div className={classes.customer__container}>
					<div className={classes.customer__seller}>
						<Typography color="primary" variant="h6">
							Integra d.o.o.
						</Typography>
						<Typography color="textSecondary" variant="body2">
							HR41849057634
						</Typography>
						<Typography color="textSecondary" variant="body2">
							Milana Amruša 10, Zagreb
						</Typography>
						<Typography color="textSecondary" variant="body2">
							Integra@integra.hr
						</Typography>
						<Typography color="textSecondary" variant="body2">
							+38513833429
						</Typography>
					</div>
					<div>
						<img
							src={chevron3}
							style={{ width: 55 }}
							alt="arrow"></img>
					</div>
					{customerToDisplay ? 
					<div className={classes.customer__buyer}>
						<Typography color="primary" variant="h6">
							{customerToDisplay.name ? customerToDisplay.name : "Not existing"}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							{customerToDisplay.vatId ? customerToDisplay.vatId : customerToDisplay.oib}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							{customerToDisplay.email}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							{customerToDisplay.address}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							{customerToDisplay.city}
						</Typography>
					</div> : 
					<div>
						<Typography color="primary" variant="h6">
							Customer not found
						</Typography>
					</div>
					}
				</div>
				{/* PROJECT INFO */}
				<div className={classes.projectInfo__container}>
					<Typography color="textSecondary" style={{ whiteSpace: "pre-wrap" }} variant="body2">
						{quoteCardInfo.projectInfo}
					</Typography>
				</div>
				{/* items _ TABLE */}
				<div className={classes.items__container}>
					<Table className={classes.table} aria-label="simple table" size="small">
						<TableHead>
							<TableRow>
								<TableCell style={{ width: 550 }}>
									{quoteCardInfo.foreignCurrency ? terminologija.item.en : terminologija.item.hrv}
								</TableCell>
								<TableCell align="right">
									{quoteCardInfo.foreignCurrency
										? terminologija.quantity.en
										: terminologija.quantity.hrv}
								</TableCell>
								<TableCell align="right">
									{quoteCardInfo.foreignCurrency ? terminologija.unit.en : terminologija.unit.hrv}
								</TableCell>
								<TableCell align="right">
									{quoteCardInfo.foreignCurrency
										? terminologija.unitPrice.en
										: terminologija.unitPrice.hrv}
								</TableCell>
								<TableCell align="right">
									{quoteCardInfo.foreignCurrency ? terminologija.total.en : terminologija.total.hrv}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.keys(quoteCardInfo.quoteInputValues).map((key) => (
								<TableRow key={quoteCardInfo.quoteInputValues[key].id}>
									<TableCell component="th" scope="row">
										<Typography component="div">
											<Box fontWeight="fontWeightMedium">
												{quoteCardInfo.quoteInputValues[key].itemName}
											</Box>
										</Typography>
										<Typography style={{ whiteSpace: "pre-wrap" }} variant="body2">
											{quoteCardInfo.quoteInputValues[key].description}
										</Typography>
									</TableCell>
									<TableCell align="right">
										{quoteCardInfo.quoteInputValues[key].quantity}
									</TableCell>
									<TableCell align="right">{quoteCardInfo.quoteInputValues[key].unit}</TableCell>
									{/* price */}
									<TableCell align="right">
										{quoteCardInfo.foreignCurrency
											? (
												parseFloat(quoteCardInfo.quoteInputValues[key].price)).toFixed(2)
											: parseFloat(quoteCardInfo.quoteInputValues[key].price).toFixed(2)}
										<span className={classes.currency__span}>
											{quoteCardInfo.foreignCurrency
												? terminologija.currency.en
												: terminologija.currency.hrv}
										</span>
									</TableCell>
									{/* total */}
									<TableCell align="right">
										{quoteCardInfo.foreignCurrency
											? (
													parseFloat(quoteCardInfo.quoteInputValues[key].total)).toFixed(2)
											: parseFloat(quoteCardInfo.quoteInputValues[key].total).toFixed(2)}
										<span className={classes.currency__span}>
											{quoteCardInfo.foreignCurrency
												? terminologija.currency.en
												: terminologija.currency.hrv}
										</span>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					{/* items _ SUBTOTAL */}
					<div className={classes.items__total}>
						{/* row 1 */}
						<div className={classes.itemsTotal__row}>
							<Typography>
								{quoteCardInfo.foreignCurrency
									? terminologija.subtotal.en.toUpperCase()
									: terminologija.subtotal.hrv.toUpperCase()}
							</Typography>
							<Typography>
								{quoteCardInfo.foreignCurrency
									? (quoteCardInfo.totalValue).toFixed(2)
									: quoteCardInfo.totalValue.toFixed(2)}
								<span className={classes.currency__span}>
									{quoteCardInfo.foreignCurrency
										? terminologija.currency.en
										: terminologija.currency.hrv}
								</span>
							</Typography>
						</div>
						{/* row 1-1 discount */}
						<div className={classes.itemsTotal__row}>
							<Typography>
								{quoteCardInfo.foreignCurrency
									? terminologija.discount.en.toUpperCase()
									: terminologija.discount.hrv.toUpperCase()}
							</Typography>
							<Typography>
								{getDiscount()}
								<span className={classes.currency__span}>
									{quoteCardInfo.foreignCurrency
										? terminologija.currency.en
										: terminologija.currency.hrv}
								</span>
							</Typography>
						</div>
						{/* row 2 */}
						<div className={classes.itemsTotal__row}>
							<Typography>
								{quoteCardInfo.foreignCurrency
									? terminologija.vat.en.toUpperCase()
									: terminologija.vat.hrv.toUpperCase()} (
									<span style={{  }}>{getVat()}</span>
									<span style={{ color: "lightgray", marginLeft: 3 }}>%</span>)
							</Typography>
							<Typography>
								{getVatValue()}
								<span style={{ color: "lightgray" }} className={classes.currency__span}>
										{quoteCardInfo.foreignCurrency
											? terminologija.currency.en
											: terminologija.currency.hrv}
									</span>
							</Typography>
						</div>
						{/* row 3 */}
						<div className={clsx(classes.itemsTotal__row, classes.itemsTotal__grandValue)}>
							<Typography component="div">
								<Box fontWeight="fontWeightMedium">
									{quoteCardInfo.foreignCurrency
										? terminologija.grandTotal.en.toUpperCase()
										: terminologija.grandTotal.hrv.toUpperCase()}
								</Box>
							</Typography>
							<Typography component="div">
								<Box fontWeight="fontWeightMedium">
									{getGrandTotal()}
									<span style={{ color: "white" }} className={classes.currency__span}>
										{quoteCardInfo.foreignCurrency
											? terminologija.currency.en
											: terminologija.currency.hrv}
									</span>
								</Box>
							</Typography>
						</div>
					</div>
				</div>
				{/* PAYMENT - DIRECTOR SIGNATURE*/}
				<div className={classes.payment}>
					{/* payment */}
					<div className={classes.payment__header}>
						<Typography
							className={classes.payment__headerText}
							variant="body2"
							color="textPrimary"
							component="div">
							<Box fontWeight="fontWeightMedium">
								{quoteCardInfo.foreignCurrency
									? terminologija.payment.en.toUpperCase()
									: terminologija.payment.hrv.toUpperCase()}
							</Box>
						</Typography>
						{/* hrv */}
						{!quoteCardInfo.foreignCurrency && (
							<div>
								<Typography component="div" variant="body2" color="textSecondary">
									Uplate na IBAN HR66 2360000 1101355418
								</Typography>
								<Typography component="div" variant="body2" color="textSecondary">
									Zagrebačka banka, Savska cesta 60, 10000 Zagreb
								</Typography>
								<img
									className={classes.payment__barcode}
									src={barcode}
									alt="barcodeImage"
								/>
							</div>
						)}
						{/* engl */}
						{quoteCardInfo.foreignCurrency && (
							<div>
								<Typography component="div" variant="body2" color="textSecondary">
									Payment to account no. 2400033803-3509800
								</Typography>
								<Typography component="div" variant="body2" color="textSecondary">
									IBAN HR66 2360000 1101355418
								</Typography>
								<Typography component="div" variant="body2" color="textSecondary">
									Zagrebačka banka, Savska cesta 60, 10000 Zagreb
								</Typography>
							</div>
						)}
					</div>
					{/* signature */}
					<div className={classes.payment__signature}>
						<Typography component="div" variant="body1" color="textPrimary">
							<Box fontWeight="fontWeightMedium">Nika Prlić</Box>
						</Typography>
						<Typography component="div" variant="body2" color="textSecondary">
							Direktorica
						</Typography>
						<img style={{ width: 150 }} src={NikaSignature} alt="signature" />
					</div>
				</div>
				{/* NOTES */}
				<div>
					{quoteCardInfo.noteInputValues &&
						Object.keys(quoteCardInfo.noteInputValues).map((key) => (
							<div key={quoteCardInfo.noteInputValues[key].id} className={classes.notes__row}>
								<div className={classes.notes__name}>
									<Typography color="textPrimary" variant="body2">
										{quoteCardInfo.noteInputValues[key].noteTitle}
									</Typography>
								</div>
								<div className={classes.notes__description}>
									<Typography
										style={{ whiteSpace: "pre-wrap" }}
										color="textSecondary"
										variant="caption">
										{quoteCardInfo.noteInputValues[key].noteText}
									</Typography>
								</div>
							</div>
						))}
				</div>
			</div>
		</Container>
	);
};

const mapStateToProps = (state) => ({
	quoteCardInfo: state.salesquotes.quoteCardInfo,
	customersArray: state.customers.customers,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteCard);

import React from 'react'
import { makeStyles, List, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AndroidIcon from '@material-ui/icons/Android';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';



const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
        marginTop:20,
    },
   
    navLink: {
        textDecoration: "none",
        color:theme.palette.grey[900]
    },
    activeLink: {
        fontWeight: "bold",
        color: "grey"
    },

    icon:{
        color:theme.palette.primary.main,
    },

    bottomActions:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width:"100%"
    }
}));

const NavLinks = (props) => {
    const classes = useStyles();
    return (
        <div
            className={classes.list}
            onClick={props.closeDrawerHandler}
            onKeyDown={props.closeDrawerHandler}
        >
            <List>
                {/* sales quotes */}
                <NavLink
                    exact
                    activeClassName={classes.activeLink}
                    className={classes.navLink} to="/salesQuotes">
                    <ListItem button>
                        <ListItemIcon>
                            <AssignmentIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary="Ponude" />
                    </ListItem>
                </NavLink>
                {/* sales orders */}
                <NavLink
                    exact
                    activeClassName={classes.activeLink}
                    className={classes.navLink} to="/salesOrders">
                    <ListItem button>
                        <ListItemIcon>
                            <LocalAtmIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText primary="Računi" />
                    </ListItem>
                </NavLink>
                {/* customers */}
                <NavLink
                    exact
                    activeClassName={classes.activeLink}
                    className={classes.navLink} to="/customers">
                    <ListItem button>
                        <ListItemIcon>
                            <PeopleIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText primary="Customers" />
                    </ListItem>
                </NavLink>
                {/* settings */}
                <NavLink
                    exact
                    activeClassName={classes.activeLink}
                    className={classes.navLink} to="/settings">
                    <ListItem button>
                        <ListItemIcon>
                            <SettingsIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItem>
                </NavLink>
                {/* test */}
                <NavLink
                    exact
                    activeClassName={classes.activeLink}
                    className={classes.navLink} to="/test">
                    <ListItem button>
                        <ListItemIcon>
                            <AndroidIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText primary="test" />
                    </ListItem>
                </NavLink>
                
            </List>
            <div className={classes.bottomActions}>
            <Button  color="secondary">Logout</Button>

            </div>
        </div>
    )
}

export default NavLinks

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as actionCreators from "../../../store/actions/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EventIcon from "@material-ui/icons/Event";
import OrderDynamicNoteLine from "./DynamicNoteLine";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	AppBar,
	Button,
	Card,
	CircularProgress,
	Container,
	Dialog,
	Divider,
	IconButton,
	InputAdornment,
	MenuItem,
	Slide,
	TextField,
	Toolbar,
	Tooltip,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DynamicOrderLine from "./DynamicOrderLine";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { parseISO, addDays, isValid, differenceInCalendarDays, format } from "date-fns";

const useStyles = makeStyles((theme) => ({
	root: {},
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	card: {
		padding: "5px 10px",
		backgroundColor: "#f5f5f5",
		marginTop: 20,
	},
	card_input: {
		margin: "10px 0px",
	},
	card__customerAndSwitchContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
	},
	card__title: {
		fontSize: 16,
		textAlign: "center",
		color: "#bdbdbd",
		marginBottom: 5,
	},
	card__subtitle: {
		fontSize: 16,
		color: "#bdbdbd",
		marginBottom: 5,
	},
	items__subtotal: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		padding: 10,
	},
	items__subtotalRow: {
		width: 250,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
		padding: 5,
	},
	divider: {
		marginTop: 25,
		marginBottom: 25,
	},
	dueDate__container: {
		marginTop: 10,
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-center",
		width: 500,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const NewOrderForm = (props) => {
	const classes = useStyles();
	const {
		isLoading,
		onShowErrorModal,
		newOrderFormIsVisible,
		onCloseNewOrderForm,
		isEditing,
		orderCardInfo,
		customersArray,
		onSetNewOrderField,
		orderInputsObj,
		quotesArray,
		newOrderState,
		salesQuoteState,
		orderNoteInputsObj,
		onAddNewOrderNoteLine,
		onGetStandardNotes,
		standardNotesArray,
		onApplySelectedNote,
		onSaveNewOrder,
		onAddNewOrderLine,
		onUpdateOrder,
		onResetNewOrderFormState,
		orderProjectInfo,
		options,
	} = props;

	useEffect(() => {
		onGetStandardNotes();
	}, [onGetStandardNotes]);

	const [note, setNote] = useState("");

	const noteChangeHandler = (event) => {
		setNote(event.target.value);
	};

	const handleClose = () => {
		onCloseNewOrderForm();
		//reset state
		onResetNewOrderFormState();
	};

	const saveOrderHandler = () => {
		if (isEditing) {
			//Validation
			if (!newOrderState.orderDate) {
				onShowErrorModal("Date field cannot be empty.");
				return;
			}
			for (const key in orderInputsObj) {
				if (orderInputsObj[key].price === 0 || !orderInputsObj[key].price) {
					onShowErrorModal("Price field cannot be null or empty.");
					return;
				}
			}
			onUpdateOrder();
		} else {
			//saving order valiedation not needed as already valiedated values from quote are just copies (no edit in this stage)
			onSaveNewOrder();
		}
	};

	
	//payment due field -  which date to disply (new date or editing value)
	const displayDueDateValueHelper = () => {
		//if editing payment due date will be picked automatically from that order, else due days will be picked from options (newOrderForm action)
		//date check for diff format (new input vs from db)
		if (isValid(parseISO(newOrderState.orderDate))) {
			return addDays(parseISO(newOrderState.orderDate), parseInt(newOrderState.paymentDueDays));
		} else {
			return addDays(newOrderState.orderDate, parseInt(newOrderState.paymentDueDays));
		}
	};

	//payment due field - onChange (dospjeće)
	const onChangePaymentDueHandler = (date) => {
		let validInputDate;
		let validOrderDate;
		//date check for diff format (new input vs from db)
		if (isValid(parseISO(date))) {
			validInputDate = parseISO(date);
		} else {
			validInputDate = date;
		}
		if (isValid(parseISO(newOrderState.orderDate))) {
			validOrderDate = parseISO(newOrderState.orderDate);
		} else {
			validOrderDate = newOrderState.orderDate;
		}
		//difference in days between orderDate and dueDate (calendarDays gives us this value)
		const diffInDays = differenceInCalendarDays(validInputDate, validOrderDate);
		//Set days value in R
		onSetNewOrderField("paymentDueDays", parseInt(diffInDays));
	};

	const customerToDisplay = customersArray.find(
		(customer) => customer._id === newOrderState.orderCustomerId,
	);
	const fromQuote = quotesArray.find((quote) => quote._id === newOrderState.quoteId);

	return (
		<div className={classes.root}>
			<Dialog
				fullScreen
				open={newOrderFormIsVisible}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				maxWidth="md"
				TransitionComponent={Transition}>
				{/* APPBAR */}
				<AppBar className={classes.appBar} color="secondary" style={{}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<ArrowBackIcon fontSize="large" />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{isEditing
								? orderCardInfo && "Editing " + orderCardInfo._id
								: `Create order for ${fromQuote.quoteNumber}`}
						</Typography>
						{isLoading && <CircularProgress style={{ marginRight: 30 }} color="primary" />}
						{/* ACTION BUTTONS */}
						<div>
							<Button
								onClick={saveOrderHandler}
								size="small"
								variant="contained"
								color="primary"
								startIcon={<SaveIcon />}>
								Save
							</Button>
						</div>
					</Toolbar>
				</AppBar>

				<Container maxWidth="xl">
					{/* ORDER CUSTOMER CREATE  -  EDIT ORDER*/}
					<Card className={classes.card}>
						<div style={{ marginLeft: 15 }}>
							<Typography className={classes.card__title}>Customer</Typography>
							<Typography variant="h5" color="primary">
								{customerToDisplay && customerToDisplay.name}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								{customerToDisplay && customerToDisplay.oib}
							</Typography>
						</div>
						{/* ORDER DATE */}
						<div style={{ margin: 10 }}>
							<div>
								<Typography className={classes.card__subtitle}>Order Date</Typography>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DateTimePicker
										ampm={false}
										showTodayButton
										size="small"
										value={newOrderState.orderDate}
										onChange={(date) => {
											onSetNewOrderField("orderDate", date);
										}}
										format="d/MM/yy kk:mm"
										inputVariant="outlined"
										style={{ width: 200 }}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<EventIcon color="primary" />
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>

							{/* NEW DUE DATE ------------DATE PICKER----------------------*/}
							<div>
								<Typography style={{ marginTop: 15 }} className={classes.card__subtitle}>
									Payment Due
								</Typography>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										ampm={false}
										showTodayButton
										size="small"
										value={displayDueDateValueHelper()}
										onChange={(date) => {
											onChangePaymentDueHandler(date);
										}} //change this
										format="d/MM/yy kk:mm"
										inputVariant="outlined"
										style={{ width: 200 }}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<EventIcon color="primary" />
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
					</Card>
					{/* PROJECT INFO -- */}
					<Accordion
						style={{ padding: "5px 10px", marginTop: 2, backgroundColor: "#fafafa" }}
						defaultExpanded={orderProjectInfo ? orderProjectInfo.length > 0 : false}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header">
							<Typography style={{ color: "#bdbdbd" }}>Project Details (optional)</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{/* rendered INPUTS */}
							<TextField
								defaultValue={orderProjectInfo}
								multiline
								rowsMax={4}
								style={{ width: "100%" }}
								size="small"
								type="text"
								label="Project description"
								helperText="e.g. EU project 8852: final review for project P5543-J."
								variant="outlined"
								onChange={(e) => onSetNewOrderField("projectInfo", e.target.value)}
								inputProps={{
									style: {
										fontSize: 14,
										marginBottom: "0px",
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 14,
									},
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Divider className={classes.divider} />

					{/* ORDER ITEMS  ORDER ITEMS  ORDER ITEMS  ORDER ITEMS*/}
					{isEditing ? (
						<Card className={classes.card}>
							<Typography className={classes.card__title}>Quote items</Typography>
							{/* rendered INPUTS */}
							{Object.keys(orderInputsObj).map((key, index) => (
								<DynamicOrderLine key={key} lineId={key} />
							))}
							{/* ADD quote line -BUTTON */}
							<Tooltip title="Add new item">
								<Button
									onClick={onAddNewOrderLine}
									size="small"
									startIcon={<AddCircleIcon color="secondary" style={{ fontSize: 30 }} />}>
									New item
								</Button>
							</Tooltip>
						</Card>
					) : (
						<Card className={classes.card}>
							<Typography className={classes.card__title}>Order items</Typography>
							{/* rendered INPUTS */}
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Item</TableCell>
										<TableCell align="right">Qty.</TableCell>
										<TableCell align="right">Unit</TableCell>
										<TableCell align="right">Price/pc</TableCell>
										<TableCell align="right">Disc.&nbsp;%</TableCell>
										<TableCell align="right">Disc</TableCell>
										<TableCell align="right">PDV</TableCell>
										<TableCell align="right">Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(orderInputsObj).map((key, index) => (
										<TableRow key={key}>
											<TableCell component="th" scope="row">
												<Typography variant="subtitle1">{orderInputsObj[key].itemName}</Typography>
												<Typography variant="body2">{orderInputsObj[key].description}</Typography>
											</TableCell>
											<TableCell align="right">{orderInputsObj[key].quantity}</TableCell>
											<TableCell align="right">{orderInputsObj[key].unit}</TableCell>
											<TableCell align="right">{orderInputsObj[key].price}</TableCell>
											<TableCell align="right">{orderInputsObj[key].discountPercentage}</TableCell>
											<TableCell align="right">{orderInputsObj[key].discountValue}</TableCell>
											<TableCell align="right">{orderInputsObj[key].pdv}</TableCell>
											<TableCell align="right">{orderInputsObj[key].total}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							{/* subtotal */}
							<div className={classes.items__subtotal}>
								<div className={classes.items__subtotalRow}>
									<Typography variant="subtitle2" color="textSecondary">
										SUBTOTAL
									</Typography>
									<div>
										<Typography
											component="span"
											variant="subtitle2"
											color="primary"
											style={{ marginLeft: 6 }}>
											{salesQuoteState.quoteCardInfo.totalValue.toFixed(2)}
										</Typography>
										<Typography
											component="span"
											variant="subtitle2"
											color="textSecondary"
											style={{ marginLeft: 3 }}>
											€
										</Typography>
									</div>
								</div>
							</div>
						</Card>
					)}
					<Divider className={classes.divider} />

					{/* ORDER NOTES  ORDER NOTES  ORDER NOTES  ORDER NOTES */}
					<Card className={classes.card} style={{ marginBottom: 20 }}>
						<Typography className={classes.card__title}>Notes</Typography>
						{/* rendered NOTES */}
						{orderNoteInputsObj &&
							Object.keys(orderNoteInputsObj).map((key, index) => (
								<OrderDynamicNoteLine key={key} lineId={key} />
							))}
						{/* ADD note line -BUTTON */}
						<Tooltip title="Add new note">
							<Button
								onClick={() => onAddNewOrderNoteLine("", "")}
								size="small"
								startIcon={<AddCircleIcon color="secondary" style={{ fontSize: 30 }} />}>
								New note
							</Button>
						</Tooltip>
						{/* adding standard notes from db menu list */}
						<div style={{ float: "right", paddingBottom: 12 }}>
							<TextField
								style={{ minWidth: 200 }}
								variant="outlined"
								size="small"
								select
								label="Select note"
								value={note}
								onChange={noteChangeHandler}>
								{standardNotesArray.map((option) => (
									<MenuItem key={option._id} value={option}>
										{option.name}
									</MenuItem>
								))}
							</TextField>
							<IconButton
								style={{ marginLeft: 5 }}
								size="small"
								onClick={() => {
									onApplySelectedNote(note);
								}}>
								{/* check if we have set note - if yes display tooltip with text */}
								{note.description ? (
									<Tooltip title={note.description}>
										<AddBoxIcon color="secondary" style={{ fontSize: 30 }} />
									</Tooltip>
								) : (
									<PlaylistAddIcon color="secondary" style={{ fontSize: 30 }} />
								)}
							</IconButton>
						</div>
					</Card>
				</Container>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	newOrderState: state.neworderform,
	salesQuoteState: state.salesquotes,
	isLoading: state.salesorders.isLoading,
	newOrderFormIsVisible: state.salesorders.newOrderFormIsVisible,
	isEditing: state.neworderform.isEditing,
	quoteCardInfo: state.salesquotes.quoteCardInfo,
	orderCardInfo: state.salesorders.orderCardInfo,
	customersArray: state.customers.customers,
	quotesArray: state.salesquotes.quotes,
	standardNotesArray: state.neworderform.standardNotes,

	orderInputsObj: state.neworderform.orderInputValues,
	orderNoteInputsObj: state.neworderform.noteInputValues,

	orderProjectInfo: state.neworderform.projectInfo,

	options: state.settings.options,
});

const mapDispatchToProps = (dispatch) => ({
	onShowErrorModal: (message) => dispatch(actionCreators.showErrorModal(message)),
	onCloseNewOrderForm: () => dispatch(actionCreators.setNewOrderFormVisible(false)),
	onSetNewOrderField: (field, value) =>
		dispatch(actionCreators.setNewOrderStateField(field, value)),
	onAddNewOrderNoteLine: (noteTitle, noteText) =>
		dispatch(actionCreators.addNewOrderNoteLine(noteTitle, noteText)),
	onGetStandardNotes: () => dispatch(actionCreators.getStandardOrderNotes()),
	onApplySelectedNote: (noteId) => dispatch(actionCreators.applyOrderNote(noteId)),
	onSaveNewOrder: () => dispatch(actionCreators.saveNewOrder()),
	onAddNewOrderLine: () => dispatch(actionCreators.addNewOrderLine()),
	onUpdateOrder: () => dispatch(actionCreators.updateOrder()),

	onResetNewOrderFormState: () => dispatch(actionCreators.resetNewOrderState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderForm);

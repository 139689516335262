import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import hrCities from "../../data/cities/data";
import { Formik, Form, Field } from "formik";
import MuiTextField from "@material-ui/core/TextField";
import { TextField } from "formik-material-ui";
import { Autocomplete } from "formik-material-ui-lab";
import { Box, InputAdornment, LinearProgress, makeStyles } from "@material-ui/core";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import BusinessIcon from "@material-ui/icons/Business";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InsertCommentOutlinedIcon from "@material-ui/icons/InsertCommentOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
	},
	textField: {
		width: "100%",
	},
	actions: {
		marginTop: 40,
		display: "flex",
		direction: "row",
		justifyContent: "center",
	},
}));

const FormInputsNatural = (props) => {
	const classes = useStyles();
	const {
		selectedCustomerInfo,
		isEditing,
		onCloseCustomerForm,
		onSaveNewCustomer,
		onUpdateCustomer,
	} = props;

	const filterOptions = createFilterOptions({
		limit: 10,
	});

	return (
		<div className={classes.root}>
			<Formik
				initialValues={
					isEditing
						? {
								legalEntity: selectedCustomerInfo.legalEntity,
								name: selectedCustomerInfo.name,
								email: selectedCustomerInfo.email,
								oib: selectedCustomerInfo.oib,
								address: selectedCustomerInfo.address,
								city: selectedCustomerInfo.city,
								tel: selectedCustomerInfo.tel,
								comment: selectedCustomerInfo.comment,
						  }
						: {
								legalEntity: false,
								name: "",
								email: "",
								oib: "",
								address: "",
								city: null,
								tel: "",
								comment: "",
						  }
				}
				validate={(values) => {
					const errors = {};
					if (!values.name) {
						errors.name = "Name required";
					}
					if (!values.email) {
						errors.email = "Email required";
					} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
						errors.email = "Invalid email address";
					}
					//--check if vatId (if true, no need for OIB check) --> removed this req in privatna osoba
					// if (!values.vatId) {
					// 	if (!values.oib) {
					// 		errors.oib = "OIB required ";
					// 	} else if (values.oib.toString().length > 1 && values.oib.toString().length !== 11) {
					// 		errors.oib = "OIB has to be 11 digits long ";
					// 	}
					// }
					if (!values.address) {
						errors.address = "Address required";
					}
					if (!values.city) {
						errors.city = "City required";
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					// alert(JSON.stringify(values, null, 2));
					if (isEditing) {
						onUpdateCustomer(values);
					} else {
						onSaveNewCustomer(values);
					}
				}}>
				{({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
					<Form>
						{/* NAME */}
						<Box margin={2}>
							<Field
								className={classes.textField}
								component={TextField}
								name="name"
								label="Name:"
								type="text"
								variant="outlined"
								size="small"
								helperText="e.g. Nika Prlić"
								autoFocus
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<BusinessIcon style={{ fill: "lightgrey" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{/* EMAIL */}
						<Box margin={2}>
							<Field
								className={classes.textField}
								component={TextField}
								name="email"
								label="E-mail:"
								type="email"
								variant="outlined"
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<MailOutlineIcon style={{ fill: "lightgrey" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{/* OIB */}
						<Box margin={2}>
							<Field
								className={classes.textField}
								component={TextField}
								name="oib"
								label="OIB:"
								type="text"
								variant="outlined"
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<FingerprintIcon style={{ fill: "lightgrey" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{/* ADDRESS */}
						<Box margin={2}>
							<Field
								className={classes.textField}
								component={TextField}
								name="address"
								label="Address:"
								type="text"
								variant="outlined"
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<HomeWorkIcon style={{ fill: "lightgrey" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{/* AUTOCOMPLETE CITIES */}
						<Box margin={1}>
							<Field
								freeSolo
								forcePopupIcon={true}
								style={{ marginLeft: 10, marginRight: 10 }}
								name="city"
								component={Autocomplete}
								options={hrCities.map((item) => item.Naselje + ", " + item.BrojPu)}
								// onBlur={(v)=> console.log(v.target.value)}
								onBlur={(v) => setFieldValue("city", v.target.value)}
								// getOptionLabel={(option) => (option.Naselje + ", " + option.BrojPu) }
								filterOptions={filterOptions}
								renderInput={(params) => (
									<MuiTextField
										className={classes.textField}
										margin="dense"
										{...params}
										error={touched["city"] && !!errors["city"]}
										helperText={touched["city"] && errors["city"]}
										label="City:"
										variant="outlined"
										InputProps={{
											...params.InputProps,
											startAdornment: (
												<InputAdornment position="start">
													<LocationOnIcon style={{ fill: "lightgrey" }} />
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
						</Box>
						{/* TEL */}
						<Box margin={2}>
							<Field
								className={classes.textField}
								component={TextField}
								name="tel"
								label="Tel:"
								type="text"
								variant="outlined"
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PhoneIcon style={{ fill: "lightgrey" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{/* COMMENT */}
						<Box margin={2}>
							<Field
								className={classes.textField}
								component={TextField}
								name="comment"
								label="Comment:"
								type="text"
								variant="outlined"
								size="small"
								multiline
								rows={2}
								rowsMax={5}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<InsertCommentOutlinedIcon style={{ fill: "lightgrey" }} />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{/* spinner */}
						{isSubmitting && <LinearProgress />}

						{/* SUBMIT */}
						<Box margin={1} className={classes.actions}>
							<Button
								variant="contained"
								color="secondary"
								disabled={isSubmitting}
								onClick={submitForm}>
								Submit
							</Button>
							<Button onClick={onCloseCustomerForm} color="primary">
								Cancel
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedCustomerInfo: state.customers.customerInfo,
	isEditing: state.newcustomerform.isEditing,
});

const mapDispatchToProps = (dispatch) => ({
	onCloseCustomerForm: () => dispatch(actionCreators.setNewCustomerFormHidden()),
	onSaveNewCustomer: (values) => dispatch(actionCreators.saveNewCustomerForm(values)),
	onUpdateCustomer: (values) => dispatch(actionCreators.updateCustomerForm(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormInputsNatural);

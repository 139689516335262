import React from "react";
import {
	Card,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles({
	container: {
		width: "55%",
		maxWidth: 800,
		zIndex: 99,
		position: "absolute",
		top: 70,
		left: 10,
   },
   table:{
      marginTop:20,
   },
   hideLastBorder: {
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    },
});

const NoteQuickPreview = (props) => {
	const { rowDetails, fadeIn } = props;
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Fade in={fadeIn} timeout={{ enter: 400 }}>
				<Card raised style={{ backgroundColor: "#e8eaf6" }}>
					<CardContent>
						<Typography variant="subtitle2">{rowDetails.name}</Typography>
						<Typography variant="caption">{rowDetails.description}</Typography>
						<Table className={classes.table} aria-label="simple table" size="small">
							<TableHead>
								<TableRow>
									<TableCell>Title</TableCell>
									<TableCell align="left">Text</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rowDetails.data.map((row, index) => (
									<TableRow key={index} className={classes.hideLastBorder}>
										<TableCell style={{ verticalAlign: 'top' }} component="th" scope="row">
											{row.title}
										</TableCell>
										<TableCell style={{whiteSpace:"pre-wrap"}}  align="left">{row.text}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Fade>
		</div>
	);
};

export default NoteQuickPreview;

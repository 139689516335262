import axios from "axios";
import * as actionCreators from "./index";

export const setIsLoadingCustomersScreen = (isLoading) => {
	return {
		type: "[CUSTOMERS] SET_IS_LOADING",
		isLoading,
	};
};
export const setCustomers = (customers) => {
	return {
		type: "[CUSTOMERS] SET_CUSTOMERS",
		customers,
	};
};
export const setCustomerCardVisible = () => {
	return {
		type: "[CUSTOMERS] SET_CUSTOMER_CARD_VISIBLE",
	};
};
export const setCustomerCardHidden = () => {
	return {
		type: "[CUSTOMERS] SET_CUSTOMER_CARD_HIDDEN",
	};
};
export const setCustomerInfo = (customerInfo) => {
	return {
		type: "[CUSTOMERS] SET_CUSTOMER_INFO",
		customerInfo,
	};
};

//crete new quote from customer detail screen
export const createQuoteFromCustomer = () => {
	return async (dispatch, getState) => {
		//redirected to quotesScreen from caller component (so new quote screen can be called)
		//since it lives from within that component
		dispatch(actionCreators.setQuoteCustomer(getState().customers.customerInfo));
		dispatch(setCustomerCardHidden());
		dispatch(actionCreators.setQuoteisEditing(false));
		dispatch(actionCreators.setNewQuoteFormVisible(true));
	};
};

//Opens fresh newCustomerDialog
export const openNewCustomerDialog = () => {
	return async (dispatch, getState) => {
		try {
			//clear selectedCustomerInfo for freshness
			dispatch(setCustomerInfo({}));
			dispatch(actionCreators.setNewCustomerFormIsEditing(false));
			dispatch(actionCreators.setNewCustomerFormVisible(true));
		} catch (error) {}
	};
};
//Opens newCustomerForm with pre-populated entries
//Entries are read from [customers r] customerInfo
export const openEditCustomerDialog = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(actionCreators.setNewCustomerFormIsEditing(true));
			dispatch(actionCreators.setNewCustomerFormVisible(true));
		} catch (error) {}
	};
};

//Opens view customer card
//sets customerInfo in [customers r] for this card
export const openCustomerCard = (info) => {
	return async (dispatch, getState) => {
		await dispatch(setCustomerInfo(info));
		await dispatch(setCustomerCardVisible());
	};
};

export const getCustomers = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(setIsLoadingCustomersScreen(true));
			const result = await axios.get(process.env.REACT_APP_NODE_URL + "/api/customers", {
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			// console.log(result)
			dispatch(setCustomers(result.data.customers));
		} catch (error) {
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingCustomersScreen(false));
		}
	};
};


//test for db deletion for EUR
export const deleteDbEntriesTest111 = () => {
	return async (dispatch, getState) => {
		try {
			console.log("Function starting");

			const result = await axios.post(process.env.REACT_APP_NODE_URL + "/api/customers/seedcustomers", "data1",
			{ headers: { Authorization: "Bearer " + getState().auth.token }}
			);

			// const response = await axios.get(
			// 	process.env.REACT_APP_NODE_URL + "/api/quotes/getEuroExchangeRate",
			// 	{ headers: { Authorization: "Bearer " + getState().auth.token } },
			// );


			



			console.log("Result:: ", result)
			
		} catch (error) {
			console.log("Error:: ", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingCustomersScreen(false));
		}
	};
};


export const recordCount = () => {
	return async (dispatch, getState) => {
		try {
			console.log("Function starting");

			const result = await axios.post(process.env.REACT_APP_NODE_URL + "/api/customers/recordCount", "data1",
			{ headers: { Authorization: "Bearer " + getState().auth.token }}
			);

		

			console.log("Result:: ", result)
			
		} catch (error) {
			console.log("Error:: ", error)
			dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(setIsLoadingCustomersScreen(false));
		}
	};
};

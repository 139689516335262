import React, { useState, useEffect } from "react";
import "./OrdersTable.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Menu,
	MenuItem,
	Switch,
	TableHead,
	Tooltip,
	Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { OrdersTablePaginationActions } from "./OrdersTablePaginationActions";
import DetailsPreview from "./DetailsPreview";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { throttle } from "lodash";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import parseISO from "date-fns/parseISO";
import getMonth from "date-fns/getMonth";
import ReceiptIcon from "@material-ui/icons/Receipt";

const useStyles2 = makeStyles({
	root: {
		padding: 20,
		marginTop: 20,
	},
	table: {
		minWidth: 500,
	},
	hoverDetailCard__container: {
		width: "50%",
		height: 500,
		zIndex: 99,
		position: "absolute",
		top: 100,
		left: 10,
	},
	actions__container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	actions__pdfLink: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
	},
	elipsisContainer: {
		display: "block",
		width: 200,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
});

const OrdersTable = (props) => {
	const classes = useStyles2();
	const {
		ordersArray,
		searchQuery,
		filterBy,
		onOpenOrderCard,
		onCompleteOrder,
		onSetOrderCardInfo,
		onGenerateOrderPdf,
		onChangeOrderDate,
		onOpenOrderEditDialog,
		onSetPdfItemsToPrint,
		onShowAlertModal,
	} = props;
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [isUnfinishedOrdersVisible, setIsUnfinishedOrdersVisible] = useState(false);

	//pre filtering before appplying searchbar
	let prefilteredArray;
	if (isUnfinishedOrdersVisible) {
		prefilteredArray = ordersArray.filter((item) => item.orderNumber.length === 0);
	} else {
		prefilteredArray = ordersArray;
	}
	//array used in table
	const filteredOrdersArray = prefilteredArray.filter((item) => {
		//since filterBy is set globally we have to check if our array has this prop
		//instead of manually setting name we can pass filters array and select 1st value
		if (typeof item[filterBy] === "undefined" || !filterBy) {
			return item["orderNumber"].toLowerCase().includes(searchQuery.toLowerCase());
		} else {
			return item[!filterBy ? "orderNumber" : filterBy]
				.toLowerCase()
				.includes(searchQuery.toLowerCase());
		}
	});

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, filteredOrdersArray.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	//open order details dialog
	const viewOrderDetailsHandler = async (data) => {
		onOpenOrderCard(data);
	};
	//on hover (quick order preview)
	const [hoverDetailIsVisible, setHoverDetailIsVisible] = useState(false);
	const [rowDetails, setRowDetails] = useState();
	//switch
	const unfinishedSwitchHandler = () => {
		setIsUnfinishedOrdersVisible((prev) => !prev);
	};

	//dotted row dropdown
	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuClickHandler = (event, row) => {
		setAnchorEl(event.currentTarget);
		setQuoteDetailsOnMenuClick(row);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	//ON dotted menu click in row -> creates barcode & sets clicked row details in quoteDetailCard
	const setQuoteDetailsOnMenuClick = (data) => {
		onSetOrderCardInfo(data);
	};

	//changeable quick date in 1st column
	const changeDateHandler = (newDate, orderId) => {
		console.log(newDate, orderId);
		const values = {
			orderId: orderId,
			newDate: newDate,
		};
		onChangeOrderDate(values);
	};

	//set page to 0 (first page) after search query is selected/applied
	useEffect(() => {
		if (page > 0) {
			setPage(0);
		}
	}, [searchQuery]);

	//Doesnt allow changind finished status checkbox except dates in last 2 months
	const finishedCheckboxHelper = (item) => {
		const currentMonth = getMonth(new Date());
		const currentItemMonth = getMonth(parseISO(item.orderDate));
		if (Math.abs(currentMonth - currentItemMonth) >=2 && item.orderNumber.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	const completeOrderHandler = (orderId, isCompleted, orderNumber) => {
		if (isCompleted) {
			onShowAlertModal(
				"Uncheck Order?",
				`Are you sure you want to uncheck invoice number ${orderNumber}?`,
				{method: "unfinishOrder", orderId, isCompleted}
			)
		}else{
			onCompleteOrder(orderId, isCompleted)
		}
	}

	//calculates and returns total value with pdv for each item(row) in table
	const calculateWithPdvHelper = (row) => {
		const pdv = row.orderInputValues[Object.keys(row.orderInputValues)[0]].pdv;
		const pdvResult = "1." + pdv.toString();
		const totalWithPdv = ( (row.totalValue * +pdvResult)).toFixed(2)
		return totalWithPdv;
	}

	return (
		<div className={classes.root}>
			<TableContainer component={Paper}>
				{/* // switch */}
				<FormGroup row style={{ marginLeft: 20 }}>
					<FormControlLabel
						control={
							<Switch
								checked={isUnfinishedOrdersVisible}
								onChange={unfinishedSwitchHandler}
								name="switch_showUnfinished"
							/>
						}
						label="Uninvoiced"
					/>
				</FormGroup>
				{/* table */}
				<Table className={classes.table} aria-label="custom pagination table" size="small">
					<TableHead>
						<TableRow>
							<TableCell align="left"></TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Invoice #</TableCell>
							<TableCell>Customer</TableCell>
							<TableCell>Project info</TableCell>
							<TableCell>Value</TableCell>
							<TableCell>SQ number</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredOrdersArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: filteredOrdersArray
						).map((row) => (
							<TableRow
								key={row._id}
								style={{ backgroundColor: row.orderNumber.length > 0 ? "" : "#FFFBF9" }}>
								{/* select row checkbox - for printing multiple pdfs */}
								<TableCell padding="checkbox" style={{ paddingLeft: 2 }}>
									<Checkbox
										//   checked={checked}
										onChange={() => onSetPdfItemsToPrint(row)}
										inputProps={{ "aria-label": "primary checkbox" }}
										style={{ width: 26, height: 26 }}
										icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
										checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
										color="primary"
									/>
								</TableCell>
								{/*CHANGEABLE DATE */}
								<TableCell component="th" scope="row" style={{ paddingLeft: 0, width: 130 }}>
									{/* {format(parseISO(row.orderDate), "dd/MM/yy - kk:mm")} */}
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<DateTimePicker
											className="datePicker"
											value={row.orderDate}
											onChange={(date) => changeDateHandler(date, row._id)}
											showTodayButton
											ampm={false}
											format="d/MM/yy kk:mm"
											InputProps={{
												disableUnderline: true,
											}}
										/>
									</MuiPickersUtilsProvider>
								</TableCell>
								{/* invoice number */}
								<TableCell width={120} align="left" >
									{row.orderNumber}
								</TableCell>
								{/* customer name */}
								<TableCell width={250} align="left">{row.customerName}</TableCell>
								{/* Project info */}
								<TableCell width={200} align="left">
									<Tooltip title={row.projectInfo}>
										<div className={classes.elipsisContainer}>{row.projectInfo}</div>
									</Tooltip>
								</TableCell>
								{/* total value */}
								<TableCell width={100} align="left">
									{calculateWithPdvHelper(row)}
									<span style={{ color: "silver", fontSize: 12, marginLeft: 3 }}>
										{row.exchangeRate !== 1 ? "€ EN" : "€"}
									</span>
								</TableCell>
								{/* quote number */}
								<TableCell width={100} align="left">{row.quoteNumber}</TableCell>
								{/* ACTIONS ACTIONS ACTIONS ACTIONS */}
								<TableCell align="left" width={150}>
									<div className={classes.actions__container}>
										<div>
											{/* hover details */}
											<IconButton
												onMouseEnter={() => {
													setHoverDetailIsVisible(true);
													setRowDetails(row);
												}}
												onMouseLeave={() => {
													setHoverDetailIsVisible(false);
												}}
												style={{ padding: 0, marginLeft: 5 }}>
												<VisibilityIcon fontSize="small" color={"primary"} />
											</IconButton>
											{/* open details */}
											<Tooltip title="View details">
												<IconButton
													onClick={() => viewOrderDetailsHandler(row)}
													size="small"
													style={{ marginLeft: 5, padding: 0 }}>
													<OpenInNewIcon fontSize="small" color={"primary"} />
												</IconButton>
											</Tooltip>
											{/* completed - checkbox --*/}
											<Tooltip title={row.orderNumber.length > 0 ? "Unfinish order" : "Complete order"}>
												<Checkbox
													disabled={finishedCheckboxHelper(row)}
													size="small"
													style={{ padding: 0, marginLeft: 5 }}
													checked={row.orderNumber.length > 0}
													onChange={throttle(
														() => completeOrderHandler(row._id, row.orderNumber.length > 0, row.orderNumber),
														1500,
													)}
													inputProps={{ "aria-label": "create invoice checkbox" }}
													// diff color for closed in curr month and in previous month
													color={(Math.abs(getMonth(new Date()) - getMonth(parseISO(row.orderDate))) >= 1 && row.orderNumber.length > 0)?"primary":"secondary"}
												/>
											</Tooltip>



											{/* if order is storno indicator */}
											{row.totalValue.toString().includes("-") && (
												<Tooltip title="This is a credit note (storno račun)">
													<IconButton size="small" style={{ marginLeft: 5, padding: 0 }}>
														<ReceiptIcon
															fontSize="small"
															color={"primary"}
															style={{ fill: "tomato" }}
														/>
													</IconButton>
												</Tooltip>
											)}
										</div>
										{/* dots quick menu */}
										<div>
											<IconButton
												aria-controls="simple-menu"
												aria-haspopup="true"
												onClick={(event) => menuClickHandler(event, row)}
												style={{ padding: 0, marginLeft: 5 }}>
												<MoreVertIcon fontSize="small" color="primary" />
											</IconButton>
											{/* save pdf */}
											<Menu
												elevation={2}
												id="simple-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleClose}>
												<div onClick={handleClose}>
													{/* edit */}
													<MenuItem dense onClick={onOpenOrderEditDialog}>
														<EditIcon
															color="primary"
															fontSize="small"
															style={{ marginRight: 10 }}
														/>
														<Typography>Edit</Typography>
													</MenuItem>
													{/* GENERATE PDF*/}
													<MenuItem onClick={() => onGenerateOrderPdf(true)}>
														<PictureAsPdfIcon
															color="primary"
															fontSize="small"
															style={{ marginRight: 5 }}
														/>
														<Typography color="primary">Save PDF</Typography>
													</MenuItem>
													{/* GENERATE PDF - no barcode*/}
													<MenuItem onClick={() => onGenerateOrderPdf(false)}>
														<PictureAsPdfIcon
															color="primary"
															fontSize="small"
															style={{ marginRight: 5 }}
														/>
														<Typography color="primary">PDF no barcode</Typography>
													</MenuItem>
												</div>
											</Menu>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								style={{ overflow: "visible" }}
								rowsPerPageOptions={[15, 20, 30]} // , { label: 'All', value: -1 }
								colSpan={3}
								count={filteredOrdersArray.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={OrdersTablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			{/* Quck details preview */}
			{hoverDetailIsVisible && (
				<DetailsPreview rowDetails={rowDetails} fadeIn={hoverDetailIsVisible} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ordersArray: state.salesorders.orders,
	searchQuery: state.searchbar.searchQuery,
	filterBy: state.searchbar.filterBy,
	quotesArray: state.salesquotes.quotes,
});

const mapDispatchToProps = (dispatch) => ({
	onOpenOrderCard: (orderInfo) => dispatch(actionCreators.openOrderCard(orderInfo)),
	onSetIsLoadingOrdersScreen: (isLoading) =>
		dispatch(actionCreators.setIsLoadingOrdersScreen(isLoading)),
	onCompleteOrder: (orderId, isCompleted) =>
		dispatch(actionCreators.completeOrder(orderId, isCompleted)),
	onSetOrderCardInfo: (orderInfo) => dispatch(actionCreators.setOrderCardInfo(orderInfo)),
	onChangeOrderDate: (newDate) => dispatch(actionCreators.changeOrderDate(newDate)),
	onGenerateOrderPdf: (withBarcode) => dispatch(actionCreators.generateOrderPdf(withBarcode)),
	onOpenOrderEditDialog: () => dispatch(actionCreators.openEditOrderDialog()),
	onSetPdfItemsToPrint: (row) => dispatch(actionCreators.setPdfItemsToPrint(row)),
	onShowAlertModal: (title, message, modalProps) =>
		dispatch(actionCreators.showAlertModal(title, message, modalProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);


const uniqid = require('uniqid');

const initialState = {
   quoteInputValues: {},
   noteInputValues: {},
   quoteDate: new Date(),
   quoteCustomer: null,
   isForeignCurrency: false,
   isEditing:false,
   standardNotes:[],
   projectInfo:"",
}
///////////quotes
export default (state = initialState, action) => {
   switch (action.type) {
      case "[NEW_QUOTE_FORM] SET_QUOTE_VALUES":
         const newQuoteValues = { ...state.quoteInputValues }
         newQuoteValues[action.lineId] = action.newValue
         return {
            ...state,
            quoteInputValues: newQuoteValues
         }
      case "[NEW_QUOTE_FORM] ADD_QUOTE_LINE":
         const quoteId = `DynamicQuoteLine-${uniqid()}`;
         const newQuoteLines = { ...state.quoteInputValues }
         newQuoteLines[quoteId] = {
            id: quoteId,
            itemName: "Usluga pisanog prijevoda teksta",
            description: "",
            quantity: 1,
            unit: "Kartica",
            price: 0,
            discountPercentage: 0,
            discountValue: 0,
            pdv: 25,
            total: 0
         }
         return {
            ...state,
            quoteInputValues: newQuoteLines
         }
      case "[NEW_QUOTE_FORM] REMOVE_QUOTE_LINE":
         const withoutDeletedLine = { ...state.quoteInputValues }
         delete withoutDeletedLine[action.lineId]
         return {
            ...state,
            quoteInputValues: withoutDeletedLine
         }
      case "[NEW_QUOTE_FORM] COPY_QUOTE_LINE":
         //create new id
         const newCopyId = `DynamicQuoteLine-${uniqid()}`;
         //get object with sent line id
         const newCopyObject = { ...state.quoteInputValues[action.lineId] }
         //change that objects id with new id
         newCopyObject.id = newCopyId;
         //put this object into state array
         const newCopyArray = { ...state.quoteInputValues }
         newCopyArray[newCopyId] = newCopyObject
         return {
            ...state,
            quoteInputValues: newCopyArray
         }

      ///////////notes
      case "[NEW_QUOTE_FORM] SET_NOTE_VALUES":
         const newNoteValues = { ...state.noteInputValues }
         newNoteValues[action.lineId] = action.newValue
         return {
            ...state,
            noteInputValues: newNoteValues
         }
      case "[NEW_QUOTE_FORM] ADD_NOTE_LINE":
         const noteId = `DynamicNoteLine-${uniqid()}`;
         const newNoteLines = { ...state.noteInputValues }
         newNoteLines[noteId] = {
            id: noteId,
            noteTitle: action.noteTitle,
            noteText: action.noteText
         }
         return {
            ...state,
            noteInputValues: newNoteLines
         }
      case "[NEW_QUOTE_FORM] REMOVE_NOTE_LINE":
         const withoutDeletedQuote = { ...state.noteInputValues }
         delete withoutDeletedQuote[action.lineId]
         return {
            ...state,
            noteInputValues: withoutDeletedQuote
         }
         case "[NEW_QUOTE_FORM] LOAD_STANDARD_NOTES":
			return {
				...state,
				standardNotes: action.notesArray.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
			};
      //RESET
      case "[NEW_QUOTE_FORM] RESET_STATE":
         return initialState;

      case "[NEW_QUOTE_FORM] SET_QUOTE_DATE":
         return {
            ...state,
            quoteDate: action.value
         }
      case "[NEW_QUOTE_FORM] SET_PROJECT_INFO":
         return {
            ...state,
            projectInfo: action.value
         }
      case "[NEW_QUOTE_FORM] SET_QUOTE_CUSTOMER":
         return {
            ...state,
            quoteCustomer: action.value
         }
      case "[NEW_QUOTE_FORM] SET_ISFOREIGN_CURRENCY":
         return {
            ...state,
            isForeignCurrency: action.value
         }
      case "[NEW_QUOTE_FORM] IS_EDITING":
         return {
            ...state,
            isEditing: action.isEditing
         }
         case "[NEW_QUOTE_FORM] SET_VALUES_BEFORE_EDIT":
         return {
            ...state,
            quoteInputValues: action.quoteInputValues,
            noteInputValues: action.noteInputValues,
            quoteDate:action.quoteDate,
            quoteCustomer:action.quoteCustomer,
            isForeignCurrency:action.isForeignCurrency,
            projectInfo:action.projectInfo,
         }

      default:
         return state
   }
}

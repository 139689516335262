import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles({
	container: {
		width: "55%",
		maxWidth: 800,
		zIndex: 99,
		position: "absolute",
		top: 70,
		left: 10,
	},
	projectInfo__container: {
		backgroundColor: "#80cbc4",
		padding: "2px 5px",
		borderRadius: 2,
		marginTop: 5,
		whiteSpace: "pre-line",
	},
	table: {
		marginTop: 15,
	},
	table__cell: {
		fontSize: 12,
	},
	subtotal__container: {display:"flex", flexDirection:"column", alignItems:"flex-end", marginTop:20},

	notes__row: {
		display: "flex",
		flexDirection: "row",
		paddingHorizontal: 10,
	},
	notes__rowItemLeft: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		paddingRight: 5,
		borderRight: "2 solid black",
		width: 90,
	},
	notes__rowItemRight: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: 5,
		flex: 1,
		whiteSpace: "pre-wrap",
	},
});


//////////////////////////
////QUICK PREVIEW
//////////////////////////

const DetailsPreview = (props) => {
	const { rowDetails, fadeIn } = props;
	const classes = useStyles();

	//calculates and returns total value with pdv for each item(row) in table
	const calculateWithPdvHelper = (row) => {
		const pdv = row.orderInputValues[Object.keys(row.orderInputValues)[0]].pdv;
		const pdvResult = "1." + pdv.toString();
		const totalWithPdv = ((row.totalValue * +pdvResult)).toFixed(2);
		return totalWithPdv;
	};

	return (
		<div className={classes.container}>
			<Fade in={fadeIn} timeout={{ enter: 400 }}>
				<Card raised style={{ backgroundColor: "#eceff1" }}>
					<CardContent>
						{/* icon */}
						<div style={{ float: "right" }}>
							{rowDetails.orderNumber.length > 0 ? (
								<CheckCircleOutlineIcon color="secondary" style={{ fontSize: 30 }} />
							) : (
								<CheckCircleOutlineIcon style={{ fontSize: 30, fill: "#ffb74d" }} />
							)}
						</div>
						<div>
							<Typography component="span" color="textSecondary" variant="body2">
								Order date
							</Typography>
							<span style={{ fontSize: 15, fontWeight: "bold", marginLeft: 10 }}>
								{format(parseISO(rowDetails.orderDate), "dd/MM/yy kk:mm:ss")}
							</span>
						</div>
						<div>
							<Typography component="span" color="textSecondary" variant="body2">
								Customer
							</Typography>
							<span style={{ fontSize: 15, fontWeight: "bold", marginLeft: 10 }}>
								{rowDetails.customerName}
							</span>
						</div>
						<div>
							<Typography component="span" color="textSecondary" variant="body2">
								Quote Number
							</Typography>
							<span style={{ fontSize: 15, fontWeight: "bold", marginLeft: 10 }}>
								{rowDetails.quoteNumber}
							</span>
						</div>
						{/* Project info */}
						<div className={classes.projectInfo__container}>
							<Typography variant="caption">{rowDetails.projectInfo}</Typography>
						</div>
						{/* table */}
						<div className={classes.table}>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow style={{ fontSize: 10 }}>
										<TableCell className={classes.table__cell}>Item</TableCell>
										<TableCell className={classes.table__cell} align="right">
											quantity
										</TableCell>
										<TableCell className={classes.table__cell} align="right">
											price
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(rowDetails.orderInputValues).map((key) => (
										<TableRow key={rowDetails.orderInputValues[key].id}>
											<TableCell className={classes.table__cell} component="th" scope="row">
												<div style={{ fontWeight: "bold" }}>
													{rowDetails.orderInputValues[key].itemName}
												</div>
												<div style={{ whiteSpace: "pre-wrap" }}>
													{rowDetails.orderInputValues[key].description}
												</div>
											</TableCell>
											<TableCell className={classes.table__cell} align="right">
												{rowDetails.orderInputValues[key].quantity}
											</TableCell>
											<TableCell className={classes.table__cell} align="right">
												{rowDetails.orderInputValues[key].price}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
						{/* subtotal */}
						<div className={classes.subtotal__container}>
							<div>
								<Typography component="span" color="textSecondary" variant="body2">
									SUBTOTAL
								</Typography>
								<span style={{ fontSize: 15, fontWeight: "bold", marginLeft: 10 }}>
									{rowDetails.totalValue.toFixed(2)}
								</span>
								<Typography
									style={{ marginLeft: 5 }}
									component="span"
									color="textSecondary"
									variant="caption">
									€
								</Typography>
							</div>
							{/* with pdv */}
							<div>
								<Typography component="span" color="textSecondary" variant="body2">
									TOTAL (PDV)
								</Typography>
								<span style={{ fontSize: 15, fontWeight: "bold", marginLeft: 10 }}>
									{calculateWithPdvHelper(rowDetails)}
								</span>
								<Typography
									style={{ marginLeft: 5 }}
									component="span"
									color="textSecondary"
									variant="caption">
									{rowDetails.foreignCurrency ? "€" : "€"}
								</Typography>
							</div>
						</div>
						{/* notes */}
						<div style={{ marginTop: 50 }}>
							{rowDetails.orderNoteInputValues &&
								Object.keys(rowDetails.orderNoteInputValues).map((key) => (
									<div key={rowDetails.orderNoteInputValues[key].id} className={classes.notes__row}>
										<div className={classes.notes__rowItemLeft}>
											<Typography component="span" style={{ fontSize: 13 }}>
												{rowDetails.orderNoteInputValues[key].noteTitle}
											</Typography>
										</div>
										<div className={classes.notes__rowItemRight}>
											<Typography component="span" color="textSecondary" style={{ fontSize: 12 }}>
												{rowDetails.orderNoteInputValues[key].noteText}
											</Typography>
										</div>
									</div>
								))}
						</div>
					</CardContent>
				</Card>
			</Fade>
		</div>
	);
};

export default DetailsPreview;

const initialState = {
    isLoading: false,
    customers: [],
    customerInfo: {},
    customerInfoVisible: false
}

export default (state = initialState, action) => {
    switch (action.type) {

        case "[CUSTOMERS] SET_IS_LOADING":
            return {
                ...state,
                isLoading: action.isLoading
            }
        case "[CUSTOMERS] SET_CUSTOMERS":
            return {
                ...state,
                customers: action.customers
            }
        case "[CUSTOMERS] SET_CUSTOMER_CARD_VISIBLE":
            return {
                ...state,
                customerInfoVisible: true
            }
        case "[CUSTOMERS] SET_CUSTOMER_CARD_HIDDEN":
            return {
                //reset all
                ...state,
                customerInfoVisible: false
            }
        case "[CUSTOMERS] SET_CUSTOMER_INFO":
            return {
                ...state,
                customerInfo: action.customerInfo,

            }

        default:
            return state
    }
}

import React from "react";
import * as actionCreators from "../../../store/actions/index";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import CachedIcon from "@material-ui/icons/Cached";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const SalesOrdersDropdown = (props) => {
	const { onGetAllOrders, onSaveMultiplePdfs } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
				Menu
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem
					onClick={() => {
						onGetAllOrders();
						handleClose();
					}}>
					<CachedIcon style={{ marginRight: 5 }} color="primary" fontSize="small" />
					Reload
				</MenuItem>
				<MenuItem
					onClick={() => {
						onSaveMultiplePdfs(true);
						handleClose();
					}}>
					<PictureAsPdfIcon style={{ marginRight: 5 }} color="primary" fontSize="small" />
					Save multiple PDFs
				</MenuItem>
				<MenuItem
					onClick={() => {
						onSaveMultiplePdfs(false);
						handleClose();
					}}>
					<PictureAsPdfIcon style={{ marginRight: 5 }} color="primary" fontSize="small" />
					Multiple PDFs no barcode
				</MenuItem>
				<MenuItem onClick={handleClose}>Statistics</MenuItem>
			</Menu>
		</div>
	);
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
	onGetAllOrders: () => dispatch(actionCreators.getOrders()),
	onSaveMultiplePdfs: (isWithBarcode) =>
		dispatch(actionCreators.generateMultiplePdf(isWithBarcode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SalesOrdersDropdown);

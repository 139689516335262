import { forEach } from "lodash";
import  _  from "lodash";

const initialState = {
	isLoading: false,
	quotes: [],
	newQuoteFormIsVisible: false,
	quoteCardVisible: false,
	quoteCardInfo: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "[SALES_QUOTES] IS_LOADING":
			return {
				...state,
				isLoading: action.isLoading,
			};
		case "[SALES_QUOTES] SET_QUOTES":
			return {
				...state,
				quotes: action.quotes,
			};

		// case "[SALES_QUOTES] SET_QUOTES":
			// const newQuoteData = [...state.quotes];
			// //iterate over new quotes
			// action.quotes.forEach(quote => {
			// 	//add only those that do not exist in store
			// 	if (newQuoteData.filter((element) => _.isEqual(element, quote)).length === 0) { //filetered list is empty if it does not exist in current (store) data
			// 		newQuoteData.push(quote);
			// 	}
			// });

			// const newQuoteData = [...action.quotes];

			// state.quotes.forEach(oldQuote => {
			// 	if (newQuoteData.filter((element) => _.isEqual(element._id, oldQuote._id)).length === 0) {
			// 		console.log('first: ', newQuoteData.filter((element) => _.isEqual(element, oldQuote)));
			// 		newQuoteData.push(oldQuote);
			// 	}
				

			// });



			// return {
			// 	...state,
			// 	quotes: newQuoteData,
			// };

		case "[SALES_QUOTES] SET_NEW_QUOTE_FORM_IS_VISIBLE":
			return {
				...state,
				newQuoteFormIsVisible: action.isVisible,
			};
		case "[SALES_QUOTES] SET_QUOTE_CARD_IS_VISIBLE":
			return {
				...state,
				quoteCardVisible: action.quoteCardIsVisible,
			};
		case "[SALES_QUOTES] SET_QUOTE_CARD_INFO":
			return {
				...state,
				quoteCardInfo: action.quoteCardInfo,
			};
		case "[SALES_QUOTES] RESET_STATE":
			return initialState;
		case "[SALES_QUOTES] SET_QUOTE_CARD_BARCODE":
			const newInfo = { ...state.quoteCardInfo };
			newInfo["barcode"] = action.barCode;
			return {
				...state,
				quoteCardInfo: newInfo,
			};

		default:
			return state;
	}
};


import React, {useState} from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import {
	Container,
	IconButton,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import NoteQuickViewCard from "./NoteQuickViewCard";

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
}));

const NotesTable = (props) => {
	const classes = useStyles();
	const { standardNotes, onEditNote, onShowAlertModal } = props;

	const deleteNoteHandler = (row) => {
		onShowAlertModal("Delete", `Are you sure you want to delete note "${row.name}"?`, {method:"deleteNote", id:row._id})
	}

	const [hoverDetailIsVisible, setHoverDetailIsVisible] = useState(false);
	const [rowDetails, setRowDetails] = useState();

	return (
		<div>
			<Container maxWidth="lg">
				<Table className={classes.table} aria-label="simple table" size="small">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell align="left">Description</TableCell>
							<TableCell align="left">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{standardNotes.map((row) => (
							<TableRow key={row._id}>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell>{row.description}</TableCell>
								<TableCell>
									{/* quick view */}
									<IconButton style={{ marginLeft: 5, padding: 0 }} size="small"
									onMouseEnter={() => {
										setHoverDetailIsVisible(true);
										setRowDetails(row);
									}}
									onMouseLeave={() => {
										setHoverDetailIsVisible(false);
									}}
									>
										<VisibilityIcon color="primary" fontSize="small" />
									</IconButton>
									{/*edit note */}
									<Tooltip title="Edit note">
										<IconButton
											onClick={() => onEditNote(row)}
											style={{ marginLeft: 5, padding: 0 }}
											size="small">
											<EditIcon color="primary" fontSize="small" />
										</IconButton>
										{/*delete note */}
									</Tooltip>
									<Tooltip title="Delete note">
										<IconButton
											onClick={()=> deleteNoteHandler(row)}
											style={{ marginLeft: 5, padding: 0 }}
											size="small">
											<DeleteIcon color="primary" fontSize="small" />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{hoverDetailIsVisible && <NoteQuickViewCard rowDetails={rowDetails} fadeIn={hoverDetailIsVisible}  />}
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	standardNotes: state.neworderform.standardNotes, //neworder and new quote pull from the same API
});

const mapDispatchToProps = (dispatch) => ({
	onEditNote: (row) => dispatch(actionCreators.editNote(row)),
	onShowAlertModal: (title, message, modalProps) =>
		dispatch(actionCreators.showAlertModal(title, message, modalProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesTable);

import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
	AppBar,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	Menu,
	MenuItem,
	Slide,
	Toolbar,
} from "@material-ui/core";
import * as actionCreators from "../../../store/actions/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import QuoteCardDetails from "./QuoteCard-details";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

const useStyles = makeStyles((theme) => ({
	root: {},
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	customerData__container: {
		paddingTop: 10,
		paddingLeft: 50,
	},
	quoteMenu: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		marginLeft: 10,
		paddingLeft: 5,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const QuoteCard = (props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const {
		quoteCardVisible,
		onCloseQuoteCard,
		quoteCardInfo,
		isLoading,

		onOpenEditQuoteDialog,
		onShowAlertModal,
		onOpenNewOrderForm,
		onCopyQuote,
		onGeneratePdf,
	} = props;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const closeDialogHandler = () => {
		onCloseQuoteCard();
	};

	return (
		<div className={classes.root}>
			<Dialog
				fullScreen
				open={quoteCardVisible}
				onClose={closeDialogHandler}
				aria-labelledby="form-dialog-title"
				maxWidth="md"
				TransitionComponent={Transition}>
				{/* APPBAR */}
				<AppBar className={classes.appBar} color="secondary" style={{}}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={closeDialogHandler}
							aria-label="close">
							<ArrowBackIcon fontSize="large" />
						</IconButton>
						{/* QUOTE TITLE/NAME */}
						<Typography variant="h6" className={classes.title}>
							Sales Quote {quoteCardInfo.quoteNumber}
						</Typography>
						{isLoading && <CircularProgress style={{ marginRight: 30 }} color="primary" />}
						{/* ACTION BUTTONS */}
						{/* DROPDOWN MENU */}
						<div className={classes.quoteMenu}>
							<Button
								size="small"
								variant="outlined"
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleClick}>
								Quote Menu
							</Button>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}>
								<div
									onClick={handleMenuClose}
									style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
									{/* edit */}
									<MenuItem dense onClick={onOpenEditQuoteDialog}>
										<EditIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />{" "}
										<Typography>Edit</Typography>
									</MenuItem>
									{/* copy quote */}
									<MenuItem dense onClick={onCopyQuote}>
										<FileCopyIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />{" "}
										<Typography>Save Copy</Typography>
									</MenuItem>
									{/* create order */}
									<MenuItem dense onClick={onOpenNewOrderForm}>
										<LocalAtmIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />{" "}
										<Typography>Create Order</Typography>
									</MenuItem>
									{/* delete */}
									<MenuItem
										dense
										onClick={() => {
											onShowAlertModal(
												"Delete",
												`Are you sure you want to delete quote ${quoteCardInfo.quoteNumber}?`,
												{ method: "deleteQuote" },
											);
										}}>
										<DeleteIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />{" "}
										<Typography>Delete</Typography>
									</MenuItem>
									{/* GENERATE PDF*/}
									<MenuItem onClick={() => onGeneratePdf(true)}>
										<PictureAsPdfIcon color="primary" fontSize="small" style={{ marginRight: 5 }} />
										<Typography color="primary">Save PDF</Typography>
									</MenuItem>
									{/* GENERATE PDF - no barcode*/}
									<MenuItem onClick={() => onGeneratePdf(false)}>
										<PictureAsPdfIcon color="primary" fontSize="small" style={{ marginRight: 5 }} />
										<Typography color="primary">PDF no barcode</Typography>
									</MenuItem>
								</div>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
				{/*  QUOTE DETAILS VIEW*/}
				<QuoteCardDetails />
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoading: state.salesquotes.isLoading,
	quoteCardVisible: state.salesquotes.quoteCardVisible,
	quoteCardInfo: state.salesquotes.quoteCardInfo,
});

const mapDispatchToProps = (dispatch) => ({
	onCloseQuoteCard: () => dispatch(actionCreators.setQuoteCardVisible(false)),
	onOpenEditQuoteDialog: () => dispatch(actionCreators.openEditQuoteDialog()),
	onShowAlertModal: (title, message, modalProps) =>
		dispatch(actionCreators.showAlertModal(title, message, modalProps)),
	onSetOrderCardInfo: (orderCardInfo) => dispatch(actionCreators.setOrderCardInfo(orderCardInfo)),
	onOpenNewOrderForm: () => dispatch(actionCreators.openNewOrderForm()),
	onCopyQuote: () => dispatch(actionCreators.copyQuote()),
	onGeneratePdf: (withBarcode) => dispatch(actionCreators.generatePdf(withBarcode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteCard);


const initialState = {
	isLoading: false,
	isEditing: false,
	noteDialogIsVisible: false,
	newNoteFormIsVisible: false,
	noteInputValues: {},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case "[NOTES] IS_LOADING":
			return {
				...state,
				isLoading: action.isLoading,
			};
		case "[NOTES] IS_EDITING":
			return {
				...state,
				isEditing: action.isEditing,
			};
		case "[NOTES] DIALOG_IS_VISIBLE":
			return {
				...state,
				noteDialogIsVisible: action.isVisible,
			};
		case "[NOTES] NEW_NOTE_FORM_IS_VISIBLE":
			return {
				...state,
				newNoteFormIsVisible: action.isVisible,
				isEditing: action.isEditing,
			};
		case "[NOTES] SET_NOTE_INPUT_VALUES":
			return {
				...state,
				noteInputValues: action.payload,
			};
		case "[NOTES] RESET_STATE":
			return initialState;

		default:
			return state;
	}
};

export default reducer;

import * as actionCreators from "../../store/actions/index";
import axios from "axios";

//QUOTES
export const setNewQuoteValue = (lineId, newValue) => {
	return {
		type: "[NEW_QUOTE_FORM] SET_QUOTE_VALUES",
		lineId,
		newValue,
	};
};
export const addNewQuoteLine = () => {
	return {
		type: "[NEW_QUOTE_FORM] ADD_QUOTE_LINE",
	};
};
export const removeQuoteLine = (lineId) => {
	return {
		type: "[NEW_QUOTE_FORM] REMOVE_QUOTE_LINE",
		lineId,
	};
};
export const copyQuoteLine = (lineId) => {
	return {
		type: "[NEW_QUOTE_FORM] COPY_QUOTE_LINE",
		lineId,
	};
};

//NOTES
export const setNewNoteValue = (lineId, newValue) => {
	return {
		type: "[NEW_QUOTE_FORM] SET_NOTE_VALUES",
		lineId,
		newValue,
	};
};
export const addNewNoteLine = (noteTitle, noteText) => {
	return {
		type: "[NEW_QUOTE_FORM] ADD_NOTE_LINE",
		noteTitle,
		noteText,
	};
};
export const removeNoteLine = (lineId) => {
	return {
		type: "[NEW_QUOTE_FORM] REMOVE_NOTE_LINE",
		lineId,
	};
};
export const setStandardQuoteNotes = (notesArray) => {
	return {
		type: "[NEW_QUOTE_FORM] LOAD_STANDARD_NOTES",
		notesArray,
	};
};

//RESET
export const resetNewQuoteInitialState = () => {
	return {
		type: "[NEW_QUOTE_FORM] RESET_STATE",
	};
};
export const setQuoteDate = (value) => {
	return {
		type: "[NEW_QUOTE_FORM] SET_QUOTE_DATE",
		value,
	};
};
export const setQuoteProjectInfo = (value) => {
	return {
		type: "[NEW_QUOTE_FORM] SET_PROJECT_INFO",
		value,
	};
};
export const setQuoteCustomer = (value) => {
	return {
		type: "[NEW_QUOTE_FORM] SET_QUOTE_CUSTOMER",
		value,
	};
};
export const setQuoteisForeignCurrency = (value) => {
	return {
		type: "[NEW_QUOTE_FORM] SET_ISFOREIGN_CURRENCY",
		value,
	};
};
export const setQuoteisEditing = (isEditing) => {
	return {
		type: "[NEW_QUOTE_FORM] IS_EDITING",
		isEditing,
	};
};
//used in openEditQuoteDialog ([salesQuotes] action)
//sets values from [salesQuoes r] quoteCardInfo --> to [newQuoteForm r] all values listed below
export const setEditingStateValues = (
	quoteInputValues,
	noteInputValues,
	quoteDate,
	quoteCustomer,
	isForeignCurrency,
	projectInfo
) => {
	return {
		type: "[NEW_QUOTE_FORM] SET_VALUES_BEFORE_EDIT",
		quoteInputValues,
		noteInputValues,
		quoteDate,
		quoteCustomer,
		isForeignCurrency,
		projectInfo
	};
};

//get notes from db and "setStandardQutoeNotes" to store
//launched from newQuoteForm UEFF
export const getStandardQuoteNotes = () => {
	return async (dispatch, getState) => {
		dispatch(actionCreators.setIsLoadingQuotesScreen(true));
		try {
         const result = await axios.get(process.env.REACT_APP_NODE_URL + "/api/notes/notes", {
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			dispatch(setStandardQuoteNotes(result.data.notes));
		} catch (error) {
         dispatch(
				actionCreators.showErrorModal(error.response.data.message || "Something went wrong"),
			);
		} finally {
			dispatch(actionCreators.setIsLoadingQuotesScreen(false));
		}
	};
};
//applies selected note from the dropdown in newQuoteForm
export const applyQuoteNote = (note) => {
	return async (dispatch, getState) => {
      const arr = note.data;
		//one name(note) can hold multiple lines(data = title/text combo)
		arr.forEach(
			(note) => dispatch(addNewNoteLine(note.title, note.text))
		);
	};
};



import axios from "axios";
import * as actionCreators from "../../store/actions/index";

export const setNewOrderStateField = (field, value) => {
	return {
		type: "[NEW_ORDER_FORM] SET_STATE_FIELD", //set any field dynamically
		field,
		value,
	};
};
export const setOrderisEditing = (isEditing) => {
	return {
		type: "[NEW_ORDER_FORM] SET_IS_EDITING",
		isEditing,
	};
};
// ITEMS ITEMS ITEMS
export const setNewOrderValue = (lineId, newValue) => {
	return {
		type: "[NEW_ORDER_FORM] SET_ITEM_LINE_VALUE",
		lineId,
		newValue,
	};
};
export const addNewOrderLine = () => {
	return {
		type: "[NEW_ORDER_FORM] ADD_ORDER_LINE",
	};
};
export const removeOrderLine = (lineId) => {
	return {
		type: "[NEW_ORDER_FORM] REMOVE_ITEM_LINE",
		lineId,
	};
};
// NOTES NOTES NOTES
export const setNewOrderNoteValue = (lineId, newValue) => {
	return {
		type: "[NEW_ORDER_FORM] SET_NOTE_LINE_VALUE",
		lineId,
		newValue,
	};
};
export const addNewOrderNoteLine = (noteTitle, noteText) => {
	return {
		type: "[NEW_ORDER_FORM] ADD_NOTE_LINE",
		noteTitle,
		noteText,
	};
};
export const removeOrderNoteLine = (lineId) => {
	return {
		type: "[NEW_ORDER_FORM] REMOVE_NOTE_LINE",
		lineId,
	};
};
//sets notes from db into store
export const setStandardOrderNotes = (notesArray) => {
	return {
		//sets the notes in state, ready to be used in dropdown menu
		type: "[NEW_ORDER_FORM] LOAD_STANDARD_NOTES",
		notesArray,
	};
};
//RESET
export const resetNewOrderState = () => {
	return {
		type: "[NEW_ORDER_FORM] RESET_STATE",
	};
};
export const setOrderEditingStateValues = (
	orderInputValues,
	noteInputValues,
	orderDate,
	orderCustomerId,
	isForeignCurrency,
	projectInfo,
	paymentDueDays
) => {
	return {
		type: "[NEW_ORDER_FORM] SET_VALUES_BEFORE_EDIT",
		orderInputValues,
		noteInputValues,
		orderDate,
		orderCustomerId,
		isForeignCurrency,
		projectInfo,
		paymentDueDays
	};
};

export const openEditOrderDialog = () => {
	return async (dispatch, getState) => {
		try {
			if (getState().salesorders.orderCardInfo.orderNumber.length > 0) {
				dispatch(actionCreators.showErrorModal("Closed sales orders cannot be edited."));
				return;
			}

			await dispatch(setOrderisEditing(true));
			await dispatch(
				setOrderEditingStateValues(
					getState().salesorders.orderCardInfo.orderInputValues,
					getState().salesorders.orderCardInfo.orderNoteInputValues,
					getState().salesorders.orderCardInfo.orderDate,
					getState().salesorders.orderCardInfo.customerId,
					getState().salesorders.orderCardInfo.isForeignCurrency,
					getState().salesorders.orderCardInfo.projectInfo,
					getState().salesorders.orderCardInfo.paymentDueDays,
				),
			);
			await dispatch(actionCreators.setNewOrderFormVisible(true));
		} catch (error) {}
	};
};

export const openNewOrderForm = () => {
	return async (dispatch, getState) => {
		dispatch(resetNewOrderState()); //isEditing:false included
		dispatch(actionCreators.setNewOrderFormVisible(true));
		dispatch(actionCreators.setQuoteCardVisible(false));
		dispatch(setNewOrderStateField("orderDate", new Date()))
		//Creating new order -> setting fields in newOrederForm reducer from sales quote
		dispatch(
			actionCreators.setNewOrderStateField(
				"orderInputValues",
				getState().salesquotes.quoteCardInfo.quoteInputValues,
			),
		);
		dispatch(
			actionCreators.setNewOrderStateField(
				"orderCustomerId",
				getState().salesquotes.quoteCardInfo.customerId,
			),
		);
		dispatch(
			actionCreators.setNewOrderStateField(
				"isForeignCurrency",
				getState().salesquotes.quoteCardInfo.foreignCurrency,
			),
		);
		dispatch(
			actionCreators.setNewOrderStateField("quoteId", getState().salesquotes.quoteCardInfo._id),
		);
		dispatch(
			actionCreators.setNewOrderStateField(
				"totalValue",
				getState().salesquotes.quoteCardInfo.totalValue,
			),
		);
		dispatch(
			actionCreators.setNewOrderStateField(
				"exchangeRate",
				getState().salesquotes.quoteCardInfo.exchangeRate,
			),
		);
		dispatch(
			actionCreators.setNewOrderStateField(
				"orderCustomerName",
				getState().salesquotes.quoteCardInfo.customerName,
			),
		);
		dispatch(
			actionCreators.setNewOrderStateField(
				"quoteNumber",
				getState().salesquotes.quoteCardInfo.quoteNumber,
			),
		);
		dispatch(
			actionCreators.setNewOrderStateField(
				"projectInfo",
				getState().salesquotes.quoteCardInfo.projectInfo,
			),
		);
		//setting default payment due days for new order - loaded from options in settings.
		//and not from quote info iteself (options are global, not tied with quote)
		dispatch(
			actionCreators.setNewOrderStateField(
				"paymentDueDays",
				getState().settings.options.paymentDueDays,
			),
		);
	};
};

//get notes from db and "setStandardOrderNotes" to store
//launched from newOrderForm UEFF
export const getStandardOrderNotes = () => {
	return async (dispatch, getState) => {
		dispatch(actionCreators.setIsLoadingOrdersScreen(true));
		try {
			const result = await axios.get(process.env.REACT_APP_NODE_URL + "/api/notes/notes", {
				headers: { Authorization: "Bearer " + getState().auth.token },
			});
			dispatch(setStandardOrderNotes(result.data.notes));
		} catch (error) {
			// console.log("error[getStandardOrderNotes] :", error)
		} finally {
			dispatch(actionCreators.setIsLoadingOrdersScreen(false));
		}
	};
};

//applies selected note from  the dropdown in newOrderForm
export const applyOrderNote = (note) => {
	return async (dispatch, getState) => {
		const arr = note.data;
		//one name(note) can hold multiple lines(data = title/text combo)
		arr.forEach((note) => dispatch(addNewOrderNoteLine(note.title, note.text)));
	};
};

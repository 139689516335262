import React, {useState} from "react";
import PageTitle from "../../shared/components/UI/PageTitle";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import {
	Button,
	CircularProgress,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	TextField,
} from "@material-ui/core";
import NotesDialog from "../components/NotesDialog";

const useStyles = makeStyles((theme) => ({
	header: {},
}));

const SettingsScreen = (props) => {
	const classes = useStyles();

	const { isLoading, onSetNotesDialogVisibility, noteDialogIsVisible, options,onChangeOptionValue } = props;

	const [dueDateValue, setDueDateValue] = useState(options.paymentDueDays)

	return (
		<Container maxWidth="xl">
			<Grid container justify="space-between" alignItems="center">
				<Grid container alignItems="center" spacing={3} style={{ width: 250 }}>
					{/* Page title */}
					<Grid item>
						<PageTitle tooltip="Click to refresh" onClick={() => {}} title="Settings" />
					</Grid>
					<Grid item>
						{/* Spinner */}
						{isLoading && <CircularProgress color="secondary" />}
					</Grid>
				</Grid>
				<Grid item>
					{/* <CustomersOptionsDropdown />  //here dropdown for settings if necessary */}
				</Grid>
			</Grid>
			<List style={{ marginTop: 10 }}>
				{/* open notes dialog */}
				<ListItem onClick={() => onSetNotesDialogVisibility(true)} button>
					<ListItemText primary="Notes" secondary="Setup custom notes for quotes and orders" />
				</ListItem>
				<Divider />
				<ListItem style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
					<ListItemText
						primary="Payment due"
						secondary="Specify number of days added to order date, creating Payment due date (dospjeće)"
					/>
					<div>
					<TextField onChange={(e) => setDueDateValue(e.target.value)} style={{width:80}} value={dueDateValue} type="number" size="small" variant="outlined" />
					<Button onClick={() => onChangeOptionValue({optionName:"paymentDueDays", newValue: parseInt(dueDateValue) })}>Set</Button>
					</div>
				</ListItem>
				<Divider />
			</List>

			{/* notes dialog component - hiddne */}
			{noteDialogIsVisible && <NotesDialog />}
		</Container>
	);
};

const mapStateToProps = (state) => ({
	isLoading: state.notes.isLoading,
	noteDialogIsVisible: state.notes.noteDialogIsVisible,
	options:state.settings.options
});

const mapDispatchToProps = (dispatch) => ({
	//open note dialog
	onSetNotesDialogVisibility: (isVisible) =>
		dispatch(actionCreators.setNoteDialogIsVisible(isVisible)),
	onChangeOptionValue: (payload) =>
		dispatch(actionCreators.ChangeOptionValue(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);

import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import parseISO from 'date-fns/parseISO'
import getMonth from 'date-fns/getMonth'
import getYear from "date-fns/getYear";
import isThisYear from "date-fns/isThisYear";
import {
   AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell
} from 'recharts';

const useStyles = makeStyles((theme) => ({
   graph__container: {
      paddingLeft: 50,
      marginRight: 10,
      marginBottom:20
   },
   graph__row1: {
      display: "flex",
      flexDirection: "row",
      alignItems:"center",
      flexWrap: "wrap",
      justifyContent:"flex-start"
   }
}));


const QuoteTotalsMonthly = (props) => {
   const classes = useStyles();
   const {
      quoteArray,
   } = props;

   const dataCount = [
      { name: "Jan", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 0 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 0 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Feb", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 1 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 1 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Mar", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 2 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 2 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Apr", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 3 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 3 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "May", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 4 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 4 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Jun", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 5 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 5 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Jul", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 6 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 6 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Aug", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 7 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 7 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Sep", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 8 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 8 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Oct", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 9 && isThisYear(parseISO(quote.createdDate))).length, orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 9 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Nov", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 10 && isThisYear(parseISO(quote.createdDate))).length, orders: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 10 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
      { name: "Dec", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 11 && isThisYear(parseISO(quote.createdDate))).length, orders: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 11 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).length},
   ]
   const dataSum = [
      { name: "Jan", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 0 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 0 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Feb", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 1 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 1 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Mar", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 2 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 2 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Apr", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 3 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 3 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "May", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 4 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 4 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Jun", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 5 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 5 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Jul", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 6 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 6 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Aug", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 7 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 7 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Sep", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 8 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 8 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Oct", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 9 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders:  [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 9 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Nov", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 10 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 10 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
      { name: "Dec", quotes: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 11 && isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0).toFixed(2), orders: [...quoteArray].filter(quote => getMonth(parseISO(quote.createdDate)) === 11 && isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0).toFixed(2) },
   ];


   const dataPie = [
      { name: `Quotes ${getYear(new Date())}`, value: [...quoteArray].filter(quote => isThisYear(parseISO(quote.createdDate))).map(item => item.totalValue).reduce((a, b) => a + b, 0) },
      { name: `Orders ${getYear(new Date())}`, value: [...quoteArray].filter(quote => isThisYear(parseISO(quote.createdDate)) && quote.orderNumber.length > 0).map(item => item.totalValue).reduce((a,b) => a + b, 0)},
   ]
   const COLORS = ['#FFBB28', '#FF8042'];


   return (
      <div className={classes.graph__container}>
         <div className={classes.graph__row1}>
            <div style={{ width:"75%" }}>
               <ResponsiveContainer height={250}>
                  <AreaChart data={dataCount}
                     margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
                     <CartesianGrid strokeDasharray="3 3" />
                     <XAxis tick={{ fontSize: '14px', width: '50px', wordWrap: 'break-word', fill: '#9e9e9e' }} dataKey="name" />
                     <YAxis tick={{ fill: '#9e9e9e' }}
                        // label={
                        //    <Text
                        //       x={0}
                        //       y={0}
                        //       dx={50}
                        //       dy={150}
                        //       offset={0}
                        //       angle={-90}
                        //    >Count</Text>
                        // } 
                        />
                     <Tooltip  />
                     <Legend verticalAlign="top" height={36} />
                     <Area name="Quote Count" type='step' dataKey='quotes' stackId="1" stroke='#82ca9d' fill='#82ca9d' />
                     <Area name="Orders Count" type='step' dataKey='orders' stackId="1" stroke='#ffc658' fill='#ffc658' />
                  </AreaChart>
               </ResponsiveContainer>
            </div>
            {/* PieChartPie */}
            <div style={{ margin: 10, width: 230 }}>
               <PieChart width={240} height={205}>
                  <Pie data={dataPie} dataKey="value" cx={100} cy={80} innerRadius={40} outerRadius={80} fill="#82ca9d">
                     {
                        dataPie.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                     }
                  </Pie>
                  <Tooltip formatter={(value) => `${value} €`} />
                  <Legend verticalAlign="top" height={36} />
               </PieChart>
            </div>
         </div>

         <div>
            <ResponsiveContainer width="90%" height={250}>
               <AreaChart data={dataSum}
                  margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={{ fontSize: '14px', width: '50px', wordWrap: 'break-word', fill: '#9e9e9e' }} dataKey="name" />
                  <YAxis tick={{ fill: '#9e9e9e' }} 
                  // label={
                  //    <Text
                  //       x={0}
                  //       y={0}
                  //       dx={20}
                  //       dy={170}
                  //       offset={0}
                  //       angle={-90}
                  //    >Value €</Text>
                  // } 
                  />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Area name="Quote Value €" type='monotone' dataKey='quotes' stackId="1" stroke='#8884d8' fill='#8884d8' />
                  <Area name="Orders Value €" type='monotone' dataKey='orders' stackId="1" stroke='#ffc658' fill='#ffc658' />
               </AreaChart>
            </ResponsiveContainer>
         </div>

      </div>
   );
};
const mapStateToProps = (state) => ({
   quoteArray: state.salesquotes.quotes,
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(QuoteTotalsMonthly);

import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { Grid, ListItem, ListItemText, List, Menu, MenuItem, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { debounce, uniqBy } from "lodash";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		height: 45,
		width: "70vw",
		maxWidth: 600,
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
	divider: {
		height: 28,
		margin: 4,
	},

	resultsPaper: {
		width: "60vw",
		maxWidth: 600,
		maxHeight: 150,
		overflowY: "scroll",
		opacity: 0.8,
	},
}));
//SearchBar works like this:
//-we set list we want to search by from outside (searchableArray)
//-we pass an object of filters
//-we return below the searchBar tips as what is being filtered from that list (localSearchArray holds results)
//-on click from that list we put that item in searchQuery reducer and foundFiles are cleared
//-(only when reducer is populated table is refreshed)

//passing parameters:
//-searchableArray
//-filters
//-showDropdown (render with or without droupdown)

const SearchBarEl = (props) => {
	const classes = useStyles();
	const { onSetSearchQuery, searchableArray, filters, showDropdown, onSetFilterBy } = props;

	const [currFilter, setCurrFilter] = useState(Object.keys(filters)[0]);
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const [localSearchQuery, setLocalSearchQuery] = useState("");
	const [localSearchArray, setLocalSearchArray] = useState([]);

	//ref to search input field
	const searchInputRef = useRef();

	//clears serach text input
	const clearSearchTextHandler = () => {
		onSetSearchQuery("");
		setLocalSearchQuery("");
		//clear text to search input via ref -> since we are not using value (because of debounce)
		searchInputRef.current.value="";
	};

	//TODO used only to give newest filter (update currFilter) when redux fulterBy changes
	//see if it plays well once lots of customers are added
	//check also if saving to onSetFoundFiles is taxing
	useEffect(() => {
		setCurrFilter(Object.keys(filters)[0]);
		onSetFilterBy(Object.keys(filters)[0]);
		onSetSearchQuery("");
	}, []);

	const onSearchQueryChangeHandler = (value) => {
		//when user backspaces the search term so we get results in table again 
		//(table is filtererd in idividual table component - via globally set search query in r store)
		if (value === "") {
			onSetSearchQuery("");
			
		}else{
			setDropdownVisible(true);
			const newList = searchableArray.filter((item) => {
				return item[currFilter].toString().toLowerCase().includes(value.toLowerCase());
			});
			//show only one valule of each (no repeats)
			const uniqValues = uniqBy(newList, currFilter);
			setLocalSearchArray(uniqValues);
		}
	};

	const onFoundFileSelectHandler = (item) => {
		onSetSearchQuery(item[currFilter]);
		//add selected item text to search input via ref -> since we are not using value (because of debounce)
		searchInputRef.current.value=item[currFilter];
		setDropdownVisible(false);
	};

	//we are debouncing by 500ms, params are passed auto from the called function
	const debounced_onSearchQueryChangeHandler = debounce(onSearchQueryChangeHandler, 500);
	const debounced_setLocalSearchQuery = debounce(setLocalSearchQuery, 500);

	//DROPDOWN - FILTERS
	//filters menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	// 1. convert passed in object of filters to array and use to map filter options to dropdown menu
	const filtersArray = [];
	for (const key in filters) {
		filtersArray.push(filters[key]);
	}

	//select one of the filters from the dropdown
	const selectFilter = (value) => {
		setAnchorEl(null);
		//2 .here we convert from value (e.g. "OIB") to key (e.g. oib)
		onSetFilterBy(Object.keys(filters).find((key) => filters[key] === value));
		setCurrFilter(Object.keys(filters).find((key) => filters[key] === value));
	};

	return (
		<Grid container alignItems="center" direction="column">
			<Grid item>
				<Paper className={classes.root}>
					<SearchIcon size="small" style={{ fill: "grey" }} />
					<InputBase
					inputComponent="input"
						inputRef={ searchInputRef }
						onChange={(e) => {
							// onSearchQueryChangeHandler(e.target.value);
							debounced_onSearchQueryChangeHandler(e.target.value);
							// setLocalSearchQuery(e.target.value);
							debounced_setLocalSearchQuery(e.target.value);
						}}
						className={classes.input}
						placeholder={`Search files...by ${filters[currFilter]}`}
						inputProps={{ "aria-label": "search files" }}
					/>
					{localSearchQuery.length > 0 && (
						<IconButton onClick={clearSearchTextHandler}>
							<CloseIcon fontSize="small" />
						</IconButton>
					)}

					<Divider className={classes.divider} orientation="vertical" />
					{/* FILTERS MENU - SET FILTERS*/}
					<div>
						<IconButton
							size="small"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={handleClick}>
							<FilterListIcon fontSize="small" />
						</IconButton>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleCloseMenu}>
							{/* items rendered from filtersArray */}
							{filtersArray.map((item) => (
								<MenuItem key={item} onClick={() => selectFilter(item)}>
									{item}
								</MenuItem>
							))}
						</Menu>
					</div>
				</Paper>
			</Grid>

			{/* search results - hidden when not typing */}
			{localSearchQuery && dropdownVisible && showDropdown && (
				<Grid item>
					<Paper style={{ marginTop: 3 }} className={classes.resultsPaper}>
						<Grid container direction="column">
							{/* found results */}
							{localSearchArray &&
								localSearchArray.map((item) => (
									<Grid item key={item._id}>
										<List dense disablePadding>
											<ListItem
												button
												onClick={() => {
													onFoundFileSelectHandler(item);
												}}>
												<ListItemText primary={item[currFilter]} />
											</ListItem>
										</List>
									</Grid>
								))}
							{/* show if no results are found */}
							{localSearchArray && localSearchArray.length === 0 && (
								<p style={{ paddingLeft: 20, color: "lightgray" }}>No results found...</p>
							)}
						</Grid>
					</Paper>
				</Grid>
			)}
		</Grid>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	onSetSearchQuery: (query) => dispatch(actionCreators.setSearchQuery(query)),
	onSetFilterBy: (keyword) => dispatch(actionCreators.setFilterBy(keyword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarEl);
